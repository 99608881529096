<template>
  <div class="row mt-16">
    <div class="text-center col-12">
      <div class="row" style="margin-bottom: 10px" v-if="false">
        <div class="col-12">
          <div
            class="btn-toolbar align-items-center"
            :class="{
              'justify-content-center': view !== 'monitor',
              'justify-content-end': view == 'monitor',
            }"
            role="toolbar"
            style="margin-left: 2px; margin-right: 2px"
          >
            <div class="btn-group btn-group-sm">
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-play"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-pause"></i>
              </button>
              <button type="button" class="btn btn-outline-secondary">
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card" style="margin-bottom: 10px">
        <div class="card-body text-left">
          <div v-for="(item, itemIndex) in itens" :key="item.id">
            <div
              class="row"
              :class="{ arquivosListaEspacamento: itemIndex > 0 }"
            >
              <div class="col col-12 d-flex">
                <div class="flex-grow-1">
                  <p
                    class="card-text d-flex justify-content-between"
                    style="padding: 0px; margin: 0px 0px 5px"
                  >
                    <small>{{ item.nome }}</small>
                    <small
                      ><span class="text-gray-700"
                        ><small>{{ item.uploadSpeed }} mbps « </small></span
                      >{{ item.uploadPercent }}%</small
                    >
                  </p>
                  <div class="progress" style="height: 12px">
                    <div
                      role="progressbar"
                      aria-valuenow="25"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      class="progress-bar bg-success"
                      :style="{ width: item.uploadPercent + '%' }"
                    ></div>
                  </div>
                </div>
                <div
                  class="d-flex align-items-end"
                  style="zoom: 80%; margin-left: 15px"
                  v-if="false"
                >
                  <div class="btn-group btn-group-sm">
                    <button type="button" class="btn btn-outline-secondary">
                      <i class="fa-solid fa-play"></i>
                    </button>
                    <button type="button" class="btn btn-outline-secondary">
                      <i class="fa-solid fa-pause"></i>
                    </button>
                    <button type="button" class="btn btn-outline-secondary">
                      <i class="fa-solid fa-circle-xmark"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentP2P",
  props: {
    msg: String,
    quantidade: { type: Number, default: 3 },
    view: { type: String, default: "" },
  },
  data() {
    return {
      itens: [
        {
          id: 1,
          nome: "Backup comercial.zip",
          uploadPercent: 87,
          uploadSpeed: 1.45,
        },
        {
          id: 2,
          nome: "Testando transferencia 12-02-2022 A.jpg",
          uploadPercent: 46,
          uploadSpeed: 2.45,
        },
        {
          id: 3,
          nome: "Backup financeiro.PDF",
          uploadPercent: 100,
          uploadSpeed: 1.98,
        },
        {
          id: 4,
          nome: "Backup comercial.zip",
          uploadPercent: 87,
          uploadSpeed: 1.45,
        },
        {
          id: 5,
          nome: "Testando transferencia 12-02-2022 A.jpg",
          uploadPercent: 46,
          uploadSpeed: 2.45,
        },
        {
          id: 6,
          nome: "Backup financeiro.PDF",
          uploadPercent: 100,
          uploadSpeed: 1.98,
        },
        {
          id: 7,
          nome: "Backup comercial.zip",
          uploadPercent: 87,
          uploadSpeed: 1.45,
        },
        {
          id: 8,
          nome: "Testando transferencia 12-02-2022 A.jpg",
          uploadPercent: 46,
          uploadSpeed: 2.45,
        },
        {
          id: 9,
          nome: "Backup financeiro.PDF",
          uploadPercent: 100,
          uploadSpeed: 1.98,
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.arquivosListaEspacamento {
  margin-top: 10px;
}
</style>
