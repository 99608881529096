<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormConfiguracoes'"
        ref="dialogFormConfiguracoes"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div>
          <div class="d-flex justify-content-end">
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Configurações</h2>
          </div>

          <div class="d-flex flex-column mb-7">
            <div
              class="nav-group nav-group-outline mx-auto"
              data-kt-buttons="true"
            >
              <button
                class="
                  btn btn-color-gray-900 btn-active btn-active-secondary
                  px-6
                  py-3
                  me-2
                "
                :class="{ active: abaSelecionada == 'smtp' }"
                @click="abaSelecionada = 'smtp'"
              >
                SMTP
              </button>
              <button
                class="
                  btn btn-color-gray-900 btn-active btn-active-secondary
                  px-6
                  py-3
                "
                :class="{ active: abaSelecionada == 'email' }"
                @click="abaSelecionada = 'email'"
              >
                Email
              </button>
            </div>
          </div>

          <div :class="{ 'd-none': abaSelecionada !== 'smtp' }">
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">E-mail</label>

              <input
                v-model="item.smtpEmail"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.smtpEmail.status }"
                type="text"
                @keyup="enterSalvar"
                ref="smtpEmail"
                autocomplete="off"
              />
              <div v-if="itemErros.smtpEmail.status" class="invalid-feedback">
                {{ itemErros.smtpEmail.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Endereço</label>

              <input
                v-model="item.smtpEndereco"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.smtpEndereco.status }"
                type="text"
                @keyup="enterSalvar"
                ref="smtpEndereco"
                autocomplete="off"
              />
              <div
                v-if="itemErros.smtpEndereco.status"
                class="invalid-feedback"
              >
                {{ itemErros.smtpEndereco.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Usuário</label>

              <input
                v-model="item.smtpUsuario"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.smtpUsuario.status }"
                type="text"
                @keyup="enterSalvar"
                autocomplete="off"
              />
              <div v-if="itemErros.smtpUsuario.status" class="invalid-feedback">
                {{ itemErros.smtpUsuario.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Senha</label>

              <input
                v-model="item.smtpSenha"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.smtpSenha.status }"
                type="text"
                @keyup="enterSalvar"
                autocomplete="off"
              />
              <div v-if="itemErros.smtpSenha.status" class="invalid-feedback">
                {{ itemErros.smtpSenha.mensagem }}
              </div>
            </div>

            <div class="row">
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <label class="fs-6 fw-bold mb-2">Porta</label>
                  <input
                    v-model="item.smtpPorta"
                    class="form-control form-control-solid"
                    :class="{ 'is-invalid': itemErros.smtpPorta.status }"
                    type="number"
                    min="1"
                    placeholder="1"
                    @keypress="$funcoes.keyPressInteiro"
                    @keyup="enterSalvar"
                  />
                  <div
                    v-if="itemErros.smtpPorta.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.smtpPorta.mensagem }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 fv-row">
                <div class="fv-row mt-11 mb-7">
                  <div
                    class="
                      form-check form-switch form-check-custom form-check-solid
                    "
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="item.smtpSsl"
                      checked="checked"
                    />
                    <label class="form-check-label fw-bold ms-3" for="status"
                      >SSL</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 text-center">
                <button
                  type="button"
                  class="btn btn-warning"
                  style="margin-right: 5px"
                  @click="smtpTeste()"
                >
                  Teste SMTP
                </button>
                <h5 class="mt-3">{{ mensagemTesteSMTP }}</h5>
              </div>
            </div>
          </div>

          <div :class="{ 'd-none': abaSelecionada !== 'email' }">
            <div class="fv-row mt-4 mb-7">
              <h2 class="text-primary mb-3 text-center">Boas vindas</h2>
              <label class="fs-6 fw-semibold mb-2">Assunto</label>
              <input
                class="form-control form-control-solid mb-5"
                type="text"
                @keyup="enterSalvar"
                autocomplete="off"
                v-model="item.emailNuvemUsuarioAssunto"
              />
              <label class="fs-6 fw-semibold mb-2">Corpo</label>
              <ckeditor
                @ready="onEditorReady"
                :editor="ckEditorFormat"
                v-model="item.emailNuvemUsuarioTexto"
                :config="ckEditorConfig"
              ></ckeditor>
            </div>

            <div class="fv-row mt-4 mb-7">
              <h2 class="text-primary mb-3 text-center">Recuperar Senha</h2>
              <label class="fs-6 fw-semibold mb-2">Assunto</label>
              <input
                class="form-control form-control-solid mb-5"
                type="text"
                @keyup="enterSalvar"
                autocomplete="off"
                v-model="item.emailNuvemRecuperarAssunto"
              />
              <label class="fs-6 fw-semibold mb-2">Corpo</label>
              <ckeditor
                @ready="onEditorReady"
                :editor="ckEditorFormat"
                v-model="item.emailNuvemRecuperarTexto"
                :config="ckEditorConfig"
              ></ckeditor>
            </div>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import Dialog from "../components/ComponentDialog.vue";

//import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export default {
  components: { Dialog },
  props: {
    view: { type: String, default: "modal" },
  },

  computed: {},

  data() {
    return {
      item: {
        smtpEmail: "",
        smtpEndereco: "",
        smtpUsuario: "",
        smtpSenha: "",
        smtpPorta: 25,
        smtpSsl: true,
        emailNuvemUsuarioAssunto: "",
        emailNuvemUsuarioTexto: "",
        emailNuvemRecuperarAssunto: "",
        emailNuvemRecuperarTexto: "",
      },
      itemErros: {
        smtpEmail: { status: false, mensagem: "" },
        smtpEndereco: { status: false, mensagem: "" },
        smtpUsuario: { status: false, mensagem: "" },
        smtpSenha: { status: false, mensagem: "" },
        smtpPorta: { status: false, mensagem: "" },
        smtpSsl: { status: false, mensagem: "" },
      },
      mensagem: "",
      mensagemTesteSMTP: "",
      abaSelecionada: "smtp",
      carregando: false,
      ckEditorFormat: Editor,
      ckEditorConfig: {},
    };
  },

  mounted() {},

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      //* Salva a configuracao
      const body = this.item;
      apiRequest = await api.put(process.env.VUE_APP_URL_API + "/admin/configuracao", body);

      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      }

      this.$emit("save-item");

      this.fechar();
    },
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogFormConfiguracoes.abrir();
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["smtpEmail"].focus();
      }, 500);
    },
    fechar() {
      this.$refs.dialogFormConfiguracoes.fechar();
      this.$emit("dialog-off");
    },

    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.abaSelecionada = "smtp";
      this.mensagem = "";
      this.mensagemTesteSMTP = "";

      this.item.smtpEmail = "";
      this.item.smtpEndereco = "";
      this.item.smtpUsuario = "";
      this.item.smtpSenha = "";
      this.item.smtpPorta = 25;
      this.item.smtpSsl = true;

      this.item.emailNuvemUsuarioAssunto = "";
      this.item.emailNuvemUsuarioTexto = "";
      this.item.emailNuvemRecuperarAssunto = "";
      this.item.emailNuvemRecuperarTexto = "";

      this.itemErros = api.errosLimpar(this.itemErros);
    },
    async carregarItem() {
      var thisCall = this;

      //* Limpa os campos
      await this.novo();

      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/configuracao");

      //* Verifica se econtrou
      if (response.data.erro == false) {
        const item = response.data.item;
        this.item.smtpEndereco = item.smtpEndereco;
        this.item.smtpEmail = item.smtpEmail;
        this.item.smtpUsuario = item.smtpUsuario;
        this.item.smtpSenha = item.smtpSenha;
        this.item.smtpPorta = item.smtpPorta;
        this.item.smtpSsl = item.smtpSsl;

        this.item.emailNuvemUsuarioAssunto = item.emailNuvemUsuarioAssunto;
        this.item.emailNuvemUsuarioTexto = item.emailNuvemUsuarioTexto;
        this.item.emailNuvemRecuperarAssunto = item.emailNuvemRecuperarAssunto;
        this.item.emailNuvemRecuperarTexto = item.emailNuvemRecuperarTexto;
      }
    },
    async onEditorReady(eventInfo) {
      console.log(await eventInfo.ui.componentFactory);
    },
    async smtpTeste() {
      //* Limpa mensagem de teste
      this.mensagemTesteSMTP = "Enviando e-mail...";

      //* Instancia os parametros
      const item = this.item;

      //* Testa o smtp
      const response = await api.post(process.env.VUE_APP_URL_API + "/admin/configuracao/teste-smtp", {
        smtpEndereco: item.smtpEndereco,
        smtpEmail: item.smtpEmail,
        smtpUsuario: item.smtpUsuario,
        smtpSenha: item.smtpSenha,
        smtpPorta: item.smtpPorta,
        smtpSsl: item.smtpSsl,
      });

      //* Verifica se ocorreu algum erro
      if (response.data.erro == false) {
        this.mensagemTesteSMTP = "E-mail enviado com sucesso!";
      } else {
        if (response.data.erros) {
          api.errosProcessar(this.itemErros, response.data.erros);
        } else {
          this.mensagemTesteSMTP = response.data.mensagem;
        }
      }
    },
  },
};
</script>

<style>
.ck .ck-balloon-panel {
  z-index: 999999 !important;
}
</style>
