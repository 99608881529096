<template>
  <div>
    <div v-if="erro == false">
      <h3 class="text-center text-warning" v-if="item.status == ''">
        Carregando arquivo...
      </h3>
      <h2 class="text-center text-warning" v-if="item.status == 'compactando'">
        Compactando dados em um arquivo zip...
      </h2>
      <h5 class="text-center" v-if="item.status == 'compactando'">
        Aguarde...
      </h5>
      <h2 class="text-center text-success" v-if="item.status == 'concluido'">
        Arquivo carregado com sucesso!
      </h2>
      <h5 class="text-center" v-if="item.status == 'concluido'">
        Iniciando download... aguarde...
      </h5>
      <h5 class="text-center text-danger" v-if="item.status == 'erro'">
        Erro ao carregar download!
      </h5>
      <h5
        class="text-center"
        v-if="item.status == 'erro' && item.erroTipo == 'TAMANHO'"
      >
        Você pode selecionar até 4 GB de dados
      </h5>
      <h5
        class="text-center"
        v-if="item.status == 'erro' && item.erroTipo == 'ZIP'"
      >
        Erro ao criar arquivo ZIP
      </h5>

      <div class="text-center mt-4" v-if="item.status !== 'erro'">
        <i class="bi bi-file-earmark text-dark fs-3hx gx-5"></i>
      </div>

      <h3 class="text-center mt-4 text-muted">{{ item.nome + item.tipo }}</h3>
      <h3 class="text-center mt-1 text-muted" v-if="item.zipTamanho > 0">
        {{ $funcoes.tamanhoTexto(item.zipTamanho) }}
      </h3>
    </div>
    <div v-if="erro == true">
      <h5 class="text-center text-danger" v-if="erroTipo !== 'SENHA'">
        {{ erroMensagem }}
      </h5>

      <div class="fv-row text-center">
        <h5 class="text-danger mb-5" v-if="erroTipo == 'SENHA'">
          {{ erroMensagem }}
        </h5>
        <div class="d-flex justify-content-center mb-5">
          <input
            class="form-control"
            style="max-width: 300px"
            type="text"
            autocomplete="off"
            v-model="senha"
          />
        </div>
        <button
          type="button"
          class="btn btn-warning"
          @click="verificarStatus()"
        >
          Validar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import { useAuthStore } from "@/stores/auth.js";

export default {
  components: {},
  props: {
    dialog: { type: Boolean, default: false },
  },
  setup() {
    const storeAuth = useAuthStore();

    return { storeAuth };
  },
  data() {
    return {
      item: {
        status: "",
        nome: "",
        tipo: "",
        zipTamanho: 0,
        erroTipo: "",
      },
      erro: false,
      erroMensagem: "",
      erroTipo: "",
      senha: "",
    };
  },

  async mounted() {
    //* Verifica o status
    this.verificarStatus();
  },

  watch: {},

  methods: {
    async verificarStatus() {
      var thisCall = this;

      //* Limpa os erros
      this.erro = false;
      this.erroTipo = "";
      this.erroMensagem = "";

      try {
        //* Carrega a regiao
        var resultRegiao = await api.get(
          process.env.VUE_APP_URL_API +
            "/" +
            this.$route.params.instancia +
            "/" +
            this.$route.params.nuvem +
            "/download/nuvem",
          null
        );

        //* Verifica a regiao
        if (resultRegiao.data.erro == false) {
          var urlRegiao = process.env.VUE_APP_URL_API;

          //* Verifica se consta a regiao
          if (
            process.env[
              "VUE_APP_URL_API_" + resultRegiao.data.item.regiao.toUpperCase()
            ]
          ) {
            urlRegiao =
              process.env[
                "VUE_APP_URL_API_" + resultRegiao.data.item.regiao.toUpperCase()
              ];
          }

          //* Carrega os dados de download
          var resultDownload = await api.get(
            urlRegiao +
              "/0/" +
              this.$route.params.nuvem +
              "/download/status/" +
              this.$funcoes.customEncodeURIComponent(this.$route.params.key),
            this.senha
          );

          //* Verifica se carregou os dados com sucesso
          if (resultDownload.data.erro == false) {
            //* Instancia o status
            this.item.status = resultDownload.data.item.status;
            this.item.nome = resultDownload.data.item.nome;
            this.item.tipo = resultDownload.data.item.tipo;
            this.item.zipTamanho = resultDownload.data.item.zipTamanho;
            this.item.erroTipo = resultDownload.data.item.erroTipo;

            //* Verifica o status
            if (this.item.status == "concluido") {
              //* Abre o download do arquivo
              this.$funcoes.downloadUrl(
                urlRegiao +
                  "/0/" +
                  this.$route.params.nuvem +
                  "/download/" +
                  this.$funcoes.customEncodeURIComponent(
                    this.$route.params.key
                  ) +
                  "?" +
                  (this.senha !== ""
                    ? "p=" +
                      this.$funcoes.customEncodeURIComponent(this.senha) +
                      "&"
                    : "") +
                  "attachment=true",
                resultDownload.data.item.nome + resultDownload.data.item.tipo
              );
              return;
            }
          } else {
            //* Define que ocorreu erro ao carregar status
            this.erro = true;

            //* Define os dados do erro
            this.erroTipo = resultDownload.data.erroTipo;
            this.erroMensagem = resultDownload.data.mensagem;
          }
        }
      } catch (errVerificarStatus) {
        console.log(errVerificarStatus);
      }

      if (this.erro == false) {
        setTimeout(function () {
          thisCall.verificarStatus();
        }, 5000);
      }
    },
  },
};
</script>

<style></style>
