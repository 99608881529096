<template>
  <div
    ref="dragDialog"
    style="position: absolute; width: 400px; z-index: 9999; display: none"
    @dblclick="minimizar()"
  >
    <Dialog
      :id="'dialogTransferencias'"
      ref="dialogTransferencias"
      :hide-header="true"
      :hideFooter="true"
      :modal="false"
      :tamanho="'md'"
      :classModalBody="'mx-5 mx-xl-1 my-0 pt-4 pb-0'"
      :titulo="'Transferencias'"
      @dialog-off="fechar()"
      :class="{ 'd-none': this.storeArvore.dialogAberta }"
    >
      <h3 class="card-title d-flex justify-content-center">
        <span class="svg-icon svg-icon-2" v-if="false"
          ><i class="bi bi-arrow-left-right fs-2x gx-5 p-2 text-gray-700"></i
        ></span>
        <span class="card-label fw-bold text-primary">Transferências</span>
        <span class="svg-icon svg-icon-2" v-if="false"
          ><i class="bi bi-cloud fs-2hx gx-5 p-2 text-primary"></i
        ></span>
      </h3>

      <div class="separator separator-dashed my-4 mt-2"></div>

      <p v-if="Object.keys(storeUpload.arquivosUltimos).length == 0">
        Nenhum arquivo foi transferido
      </p>

      <div
        v-for="arquivoValue in Object.keys(
          storeUpload.arquivosUltimos
        ).reverse()"
        :key="arquivoValue"
      >
        <div class="timeline">
          <ComponentFilaDiretorioArquivo
            :diretorio="
              storeUpload.fila[storeUpload.arquivos[arquivoValue].filaId]
                .diretorios[storeUpload.arquivos[arquivoValue].diretorioId]
            "
            :arquivo="arquivoValue"
            :fila="storeUpload.arquivos[arquivoValue].filaId"
            :view="'dialog'"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "../components/ComponentDialogStatic.vue";
import ComponentFilaDiretorioArquivo from "../components/ComponentFilaDiretorioArquivo.vue";

import { useStoreUpload } from "../stores/storeUpload.js";
import { useStoreArvore } from "../stores/storeArvore.js";

import interact from "interactjs";

export default {
  components: { Dialog, ComponentFilaDiretorioArquivo },
  props: {},
  setup() {
    const storeUpload = useStoreUpload();
    const storeArvore = useStoreArvore();

    return { storeUpload, storeArvore };
  },
  data() {
    return {
      screenX: 0,
      screenY: 0,
      dragDialog: null,
      posicao: "minimizado",
    };
  },

  mounted() {
    var thisCall = this;

    //* Verifica se esta nulo
    if (this.dragDialog == null) {
      //* Referencia a dialog
      this.dragDialog = this.$refs.dragDialog;

      //* Inicia o drag
      this.initInteract(this.dragDialog);

      //* Posiciona o elemento ao redimensionar
      window.addEventListener(
        "resize",
        function (event) {
          thisCall.redimensionar();
        },
        true
      );

      setTimeout(function () {
        //* Posiciona o elemento
        thisCall.redimensionar();

        //* Verifica se esta no desktop
        if (thisCall.$funcoes.appTipo() == "desktop") {
          //* Apresenta o elemento de drag
          thisCall.dragDialog.style.display = "block";
        }
      }, 200);
    }
  },

  methods: {
    initInteract: function (selector) {
      //* Habilita o dragindrop
      interact(selector).draggable({
        inertia: true,
        restrict: {
          restriction: "body",
          endOnly: true,
          elementRect: { top: 0, left: 0, bottom: 0, right: 0 },
        },
        autoScroll: true,
        onmove: this.dragMoveListener,
        onend: this.onDragEnd,
      });
    },
    dragMoveListener: function (event) {
      //* Define que o usuario esta posicionando
      this.posicao = "absoluta";

      var target = event.target,
        //* Carrega a posicao
        x =
          (parseFloat(target.getAttribute("data-x")) || this.screenX) +
          event.dx,
        y =
          (parseFloat(target.getAttribute("data-y")) || this.screenY) +
          event.dy;

      //* Posiciona o elemento
      target.style.webkitTransform = target.style.transform =
        "translate(" + x + "px, " + y + "px)";

      //* Atualiza os atributos
      target.setAttribute("data-x", x);
      target.setAttribute("data-y", y);
    },
    moveToPosition(target, x, y) {
      if (target !== null) {
        //* Movimenta o elemento
        target.style.webkitTransform = target.style.transform =
          "translate(" + x + "px, " + y + "px)";

        //* Atualiza a posicao atual
        target.setAttribute("data-x", x);
        target.setAttribute("data-y", y);

        //* Atualiza a posicao atual
        this.screenX = target.getBoundingClientRect().left;
        this.screenY = target.getBoundingClientRect().top;
      }
    },
    onDragEnd: function (event) {
      var target = event.target;

      //* Atualiza o novo caminho
      this.screenX = target.getBoundingClientRect().left;
      this.screenY = target.getBoundingClientRect().top;
    },
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogTransferencias.abrir();
    },
    fechar() {
      this.$refs.dialogTransferencias.fechar();
      this.$emit("dialog-off");
    },
    minimizar() {
      if (this.posicao !== "minimizado") {
        this.posicao = "minimizado";
      } else {
        this.posicao = "absoluta";

        this.moveToPosition(
          this.$refs.dragDialog,
          window.innerWidth / 2 - 230,
          window.innerHeight - 550
        );
      }

      this.redimensionar();
    },
    redimensionar() {
      if (this.posicao == "minimizado") {
        this.moveToPosition(
          this.$refs.dragDialog,
          window.innerWidth / 2 - 230,
          window.innerHeight - 150
        );
      }
    },
  },
};
</script>

<style>
</style>
