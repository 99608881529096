<template>
  <div class="row justify-content-center">
    <div class="text-center col-12" v-if="view == ''">
      <div class="row justify-content-center mb-7 mt-10">
        <div class="text-center col-10">
          <h5>Relatórios</h5>
          <h5 class="text-gray-700">
            <small>Relatórios com os dados de todos os usuarios</small>
          </h5>
        </div>
      </div>
    </div>

    <div class="text-center col-12">
      <div class="text-center">
        <button
          @click="$refs.backupLogList.carregarItens()"
          class="btn btn-sm btn-secondary me-2"
        >
          Atualizar
        </button>
      </div>
      <div class="mt-5">
        <BackupLogList ref="backupLogList"></BackupLogList>
      </div>
    </div>
  </div>
</template>

<script>
import BackupLogList from "../../../../_shared/features/BackupLogList.vue";

export default {
  name: "MonitorApp",
  components: {
    BackupLogList,
  },
  setup() {},
  data: function () {
    return {
      view: "",
    };
  },
  methods: {},
  async mounted() {},
  beforeCreate: function () {
    document.body.classList.remove("bodyOverflow");
  },
};
</script>
