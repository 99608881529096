<template>
  <div>
    <Dialog
      :id="'dialogListDownload'"
      ref="dialogListDownload"
      :hide-header="true"
      :hideFooter="true"
      :modal="true"
      :tamanho="'xl'"
      :classModalBody="'mx-5 mx-xl-1 my-1'"
      @dialog-off="
        $emit('dialog-off');
        fechar();
      "
    >
      <DownloadList
        ref="listDownload"
        :dialog="true"
        @dialog-on="fechar()"
        @dialog-off="abrir(true)"
      />

      <template #header>
        <div class="m-9 mb-0">
          <div class="d-flex justify-content-between">
            <div>
              <h1 class="d-flex text-dark fw-bolder fs-2 mt-2">Links</h1>
            </div>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="separator m-0 mt-3" v-if="true"></div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import DownloadList from "./DownloadList.vue";
import Dialog from "../components/ComponentDialog.vue";

export default {
  components: { DownloadList, Dialog },
  props: {},

  data() {
    return {};
  },

  mounted() {},

  methods: {
    async abrir(interno = false) {
      if (!interno) {
        this.$emit("dialog-on");        
      }

      this.$refs.listDownload.carregarItens();
      this.$refs.dialogListDownload.abrir();

      if (!interno) {
        this.$emit("dialog-on");
      }
    },
    fechar() {
      this.$refs.dialogListDownload.fechar();
    },
  },
};
</script>

<style></style>
