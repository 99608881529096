<template>
  <div>
    <div class="row gy-5 g-xl-8">
      <div class="col-xl-12">
        <div class="mb-5 mb-xl-8" :class="{ 'card card-xl-stretch': !dialog }">
          <div class="py-3" :class="{ 'card-body': !dialog }">
            <div class="table-responsive">
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <thead>
                  <tr class="fw-bolder text-muted">
                    <th class="text-start">
                      <span
                        class="cursor-pointer"
                        :class="{
                          'table-sort-desc':
                            ordem.campo == 'nome' && ordem.tipo == 'ASC',
                          'table-sort-asc':
                            ordem.campo == 'nome' && ordem.tipo == 'DESC',
                        }"
                        @click="alterarOrdem('nome')"
                        >Logs ({{ itensTotal }})</span
                      >
                    </th>
                    <th class="w-120px text-center">Instância</th>
                    <th class="w-120px text-center">Usuário</th>
                    <th class="w-100px text-center">Concluídos</th>
                    <th class="w-100px text-center">Erros</th>
                    <th class="w-150px text-center">Email</th>
                    <th class="w-150px text-center">Status</th>
                    <th class="w-40px text-end"></th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in itens" :key="item._id" :item="item">
                    <td class="text-start">
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-45px me-5 mt-1 mb-1 mx-1">
                          <i
                            class="bi bi-card-list text-primary fs-2hx gx-5"
                          ></i>
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <span
                            class="text-dark fw-bolder text-hover-primary fs-6"
                            style="cursor: pointer"
                          >
                            {{
                              item.backup && item.backup.nome
                                ? item.backup.nome
                                : item.backup
                            }}
                          </span>
                          <span
                            class="text-muted fw-bold text-muted d-block fs-7"
                          >
                            {{ $funcoes.formatarDataHora(item.dataFim) }}</span
                          >
                        </div>
                      </div>
                    </td>

                    <td class="text-center">
                      <span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.instancia }}</span
                      >
                    </td>

                    <td class="text-center">
                      <span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ usuarios[item.usuario].nome }}</span
                      >
                    </td>

                    <td class="text-center">
                      <span class="fw-bolder fs-3 text-dark">{{
                        item.itensConcluidos
                      }}</span>
                    </td>
                    <td class="text-center">
                      <span class="fw-bolder fs-3 text-dark">{{
                        item.itensErros
                      }}</span>
                    </td>

                    <td class="text-center">
                      <span
                        class="badge badge-light-success"
                        v-if="item.emailStatus == 'Enviado'"
                        >{{ item.emailStatus }}</span
                      >
                      <span
                        class="badge badge-light-dark"
                        v-if="item.emailStatus == 'Enviando'"
                        >{{ item.emailStatus }}</span
                      >
                      <span
                        class="badge badge-light-danger"
                        v-if="
                          item.emailStatus !== '' &&
                          item.emailStatus !== 'Enviando' &&
                          item.emailStatus !== 'Enviado'
                        "
                        >{{ item.emailStatus }}</span
                      >
                      <div class="fw-normal fs-8 text-gray-700 mt-2">
                        {{ item.email }}
                      </div>
                    </td>

                    <td class="text-center">
                      <span
                        class="badge badge-light-success"
                        v-if="item.itensErros == 0"
                        >Sucesso</span
                      >
                      <span
                        class="badge badge-light-danger"
                        v-if="item.itensErros > 0"
                        >Erro</span
                      >
                    </td>

                    <td>
                      <div class="d-flex justify-content-end flex-shrink-0">
                        <button
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="abrir(item)"
                        >
                          Abrir
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Paginacao
              ref="paginacao"
              :total="itensTotal"
              :limit="20"
              @change-pagina="carregarItens()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import { useAuthStore } from "@/stores/auth.js";

import Paginacao from "../components/ComponentPaginacao.vue";

export default {
  components: { Paginacao },

  props: {
    dialog: { type: Boolean, default: false },
  },

  setup() {
    const storeAuth = useAuthStore();

    return { storeAuth };
  },

  data() {
    return {
      timerFiltroTexto: null,
      itensTotal: 0,
      filtroTexto: "",
      ordem: { campo: "dataFim", tipo: "DESC" },
      itens: [],
      usuarios: {},
    };
  },

  mounted() {
    this.carregarItens();
  },

  methods: {
    async carregarItens() {
      let filtros = "";

      filtros += "?_limit=" + 20;

      filtros += "&_page=" + this.$refs.paginacao.pagina;

      if (this.filtroTexto !== "") {
        filtros += "&_all=" + this.filtroTexto;
      }

      filtros += "&_ordem=" + this.ordem.campo + "_" + this.ordem.tipo;

      const response = await api.get(
        this.storeAuth.apiNuvem() + "/backup/logs" + filtros
      );
      api.valid(response.data, this.$router);

      //* Percorre os usuarios
      for (
        var indiceUsuario = 0;
        indiceUsuario < response.data.usuarios.length;
        indiceUsuario++
      ) {
        //* Define o usuario
        this.usuarios[response.data.usuarios[indiceUsuario]._id] =
          response.data.usuarios[indiceUsuario];
      }

      //* Define os itens
      this.itens = response.data.itens;
      this.itensTotal = response.data.total;
      /*
      setTimeout(function () {
        window.KTApp.initBootstrapTooltips();
      }, 200);*/
    },
    abrir(item) {
      const token = localStorage.getItem("ckd");

      window.open(
          this.storeAuth.apiNuvem() +
          "/upload/backup/log/" +
          item._id +
          "?token=" +
          encodeURIComponent(token),
        "_blank",
        "width=1350,height=750"
      );
    },
    alterarOrdem(campo) {
      //* Verifica se o campo foi mantido
      if (this.ordem.campo == campo) {
        //* Verifica a ordem atual e altera para a nova
        if (this.ordem.tipo == "ASC") {
          this.ordem.tipo = "DESC";
        } else {
          this.ordem.tipo = "ASC";
        }
      } else {
        //* Altera para ordenar o novo campo
        this.ordem.campo = campo;
        this.ordem.tipo = "ASC";
      }

      //* Recarrega os itens com nova ordem
      this.carregarItens();
    },
    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const obj = this;

      this.timerFiltroTexto = setTimeout(function () {
        //obj.$refs.paginacao.pagina = 1;
        obj.carregarItens();
      }, 300);
    },
  },
};
</script>

<style>
</style>
