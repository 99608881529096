<template>
  <div @click="storeArvore.instanciaSelecionada = instancia">
    <div class="row ms-0 mb-3">
      <div class="col col-12 text-start d-flex" v-if="storeArvore?.instancias[instancia]?.tipo == 'local2'">
        <span tabindex="0" data-bs-toggle="tooltip2" data-bs-placement="top" data-bs-dismiss="click" title="Conectar"
          class="me-2" @click="abrirNuvemConectarDialog()" onclick="this.blur()" style="cursor: pointer; margin-top: 7px">
          <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
          <span class="svg-icon svg-icon-2">
            <i class="bi bi-pc-display-horizontal fs-2x gx-5 p-2 text-primary"></i>
          </span>
          <!--end::Svg Icon-->
        </span>
        <div>
          <h5>Árvore Local</h5>
          <h5 class="text-muted">
            <small>Arquivos do disco local</small>
          </h5>
        </div>
      </div>
      <div class="col col-12" v-if="storeArvore?.instancias[instancia]?.tipo == 'local' &&
        funcao !== 'selecionar-diretorio' &&
        funcao !== 'selecionar-diretorio-multiplo'
        ">
        <div class="d-flex justify-content-between">
          <div class="d-flex text-start">
            <span tabindex="0" data-bs-toggle="tooltip2" data-bs-placement="top" data-bs-dismiss="click" title="Conectar"
              class="me-2" @click="abrirNuvemConectarDialog()" onclick="this.blur()"
              style="cursor: pointer; margin-top: 7px">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
              <span class="svg-icon svg-icon-2">
                <i class="bi bi-pc-display-horizontal fs-2x gx-5 p-2 text-primary"></i>
              </span>
              <!--end::Svg Icon-->
            </span>
            <div>
              <h5>Árvore Local</h5>
              <h5 class="text-muted">
                <small>Arquivos do disco local</small>
              </h5>
            </div>
          </div>
          <div>
            <div :class="{ 'd-none': funcao !== 'padrao' }">
              <button class="btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark me-2 mb-2 px-4"
                data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end">
                <span class="svg-icon svg-icon-2 me-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-arrow-down-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
                  </svg>
                </span>

                <span class="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-folder-fill" viewBox="0 0 16 16" v-if="storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view == 'diretorio'
                      ">
                    <path
                      d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-folder-fill" viewBox="0 0 16 16" v-if="storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view ==
                      'compartilhamentos'
                      ">
                    <path
                      d="M13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2l.04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3zM2.19 3c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293L7.586 3H2.19zm9.608 5.271-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742z" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                    viewBox="0 0 16 16" v-if="storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view == 'pesquisa'
                      ">
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg> </span>{{
                    storeArvore.instancias[instancia].view == "diretorio"
                    ? "Pastas"
                    : storeArvore.instancias[instancia].view == "versoes"
                      ? "Versões"
                      : storeArvore.instancias[instancia].view == "excluidos"
                        ? "Excluídos"
                        : storeArvore.instancias[instancia].view == "pesquisa"
                          ? "Pesquisa"
                          : ""
                  }}
              </button>
              <div
                class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-color fw-semibold py-4 fs-base w-175px"
                data-kt-menu="true">
                <!--begin::Menu item-->
                <div class="menu-item px-3 my-0">
                  <span @click="storeArvore.selecionarView(instancia, 'diretorio')" class="menu-link px-3 py-2" :class="{
                              active:
                                storeArvore.instancias[instancia] &&
                                storeArvore.instancias[instancia].view == 'diretorio',
                            }" data-kt-element="mode" data-kt-value="light">
                    <span class="menu-icon" data-kt-element="icon">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen060.svg-->
                      <span class="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-folder-fill" viewBox="0 0 16 16">
                          <path
                            d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="menu-title">Pastas</span>
                  </span>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3 my-0" :class="{
                  'd-none':
                    storeArvore.instancias[instancia].view !== 'diretorio' &&
                    storeArvore.instancias[instancia].view !==
                    'compartilhamentos' &&
                    storeArvore.instancias[instancia].view !== 'pesquisa',
                }">
                  <span @click="viewPesquisa()" class="menu-link px-3 py-2" :class="{
                    active:
                      storeArvore.instancias[instancia] &&
                      storeArvore?.instancias[instancia]?.view == 'pesquisa',
                  }" data-kt-element="mode" data-kt-value="light">
                    <span class="menu-icon" data-kt-element="icon">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen060.svg-->
                      <span class="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-search" viewBox="0 0 16 16">
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="menu-title">Pesquisa</span>
                  </span>
                </div>
                <!--end::Menu item-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col col-12" v-if="storeArvore?.instancias[instancia]?.tipo == 'remoto' &&
        funcao !== 'selecionar-diretorio'
        ">
        <div class="d-flex justify-content-between">
          <div class="d-flex text-start">
            <span data-bs-toggle="tooltip2" data-bs-placement="top" data-bs-dismiss="click" title="Conectar"
              class="mt-2 me-2" @click="abrirNuvemConectarDialog()" onclick="this.blur()" style="cursor: pointer">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
              <span class="svg-icon svg-icon-2">
                <i class="bi bi-cloud fs-2hx gx-5 p-2 text-primary"></i>
              </span>
              <!--end::Svg Icon-->
            </span>
            <div>
              <h5>
                {{
                  storeAuth.nuvem[instancia] == null
                  ? storeAuth?.sessao.usuario.nuvem.nome
                  : storeAuth?.nuvem[instancia].nome
                }}
              </h5>
              <h5 class="text-muted">
                <small>{{
                  storeAuth.nuvem[instancia] == null
                  ? storeAuth?.sessao.usuario.nomeUsuario
                  : storeAuth?.nuvem[instancia].usuarioNuvem.nomeUsuario
                }}@{{
  storeAuth.nuvem[instancia] == null
  ? storeAuth?.sessao.usuario.nuvem.alias
  : storeAuth?.nuvem[instancia].alias
}}</small>
              </h5>
            </div>
            <span class="ms-5 svg-icon svg-icon-2 mt-2" data-bs-toggle="tooltip2" data-bs-placement="top"
              data-bs-dismiss="click" title="Propriedades" v-if="funcao == 'padrao' &&
                  ((storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem'))
                  " @click="abrirPropriedades(instancia, true)" style="cursor: pointer" onclick="this.blur()">
              <i class="bi bi-hdd fs-2hx gx-5 p-2 text-primary"></i>
            </span>
            <div class="ms-2" v-if="funcao == 'padrao' &&
              ((storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem'))
              ">
              <h5>Consumo</h5>
              <h5 class="text-muted">
                <small v-if="storeAuth.nuvem[instancia] == null">{{
                  storeAuth?.sessao.usuario.nuvem.espacoOcupado > 0
                  ? $funcoes.tamanhoTexto(
                    storeAuth?.sessao.usuario.nuvem.espacoOcupado
                  )
                  : 0
                }}
                  de
                  {{
                    storeAuth?.sessao.usuario.nuvem.espacoTotal > 0
                    ? $funcoes.tamanhoTexto(
                      storeAuth?.sessao.usuario.nuvem.espacoTotal
                    )
                    : 0
                  }}</small>
                <small v-if="storeAuth.nuvem[instancia] !== null">{{
                  storeAuth?.nuvem[instancia].espacoOcupado > 0
                  ? $funcoes.tamanhoTexto(
                    storeAuth?.nuvem[instancia].espacoOcupado
                  )
                  : 0
                }}
                  de
                  {{
                    storeAuth?.nuvem[instancia].espacoTotal > 0
                    ? $funcoes.tamanhoTexto(
                      storeAuth?.nuvem[instancia].espacoTotal
                    )
                    : 0
                  }}</small>
              </h5>
            </div>
          </div>
          <div>
            <div :class="{ 'd-none': funcao !== 'padrao' }">
              <button class="btn btn-outline btn-outline-dashed btn-outline-dark btn-active-light-dark me-2 mb-2 px-4"
                data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end">
                <span class="svg-icon svg-icon-2 me-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-arrow-down-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
                  </svg>
                </span>
                <span class="svg-icon svg-icon-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-folder-fill" viewBox="0 0 16 16" v-if="storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view == 'diretorio'
                      ">
                    <path
                      d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-folder-fill" viewBox="0 0 16 16" v-if="storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view ==
                      'compartilhamentos'
                      ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-folder-symlink-fill" viewBox="0 0 16 16">
                      <path
                        d="M13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2l.04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3zM2.19 3c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293L7.586 3H2.19zm9.608 5.271-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742z" />
                    </svg>
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-badge-vr-fill" viewBox="0 0 16 16" v-if="storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view == 'versoes'
                      ">
                    <path d="M9.673 5.933v1.938h1.033c.66 0 1.068-.316 1.068-.95 0-.64-.422-.988-1.05-.988h-1.05z" />
                    <path
                      d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm5.937 7 1.99-5.999H6.61L5.277 9.708H5.22L3.875 5.001H2.5L4.508 11h1.429zM8.5 5.001V11h1.173V8.763h1.064L11.787 11h1.327L11.91 8.583C12.455 8.373 13 7.779 13 6.9c0-1.147-.773-1.9-2.105-1.9H8.5z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-trash-fill" viewBox="0 0 16 16" v-if="storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view == 'excluidos'
                      ">
                    <path
                      d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                    viewBox="0 0 16 16" v-if="storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view == 'pesquisa'
                      ">
                    <path
                      d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                  </svg> </span>{{
                    storeArvore.instancias[instancia].view == "diretorio"
                    ? "Pastas"
                    : storeArvore.instancias[instancia].view == "versoes"
                      ? "Versões"
                      : storeArvore.instancias[instancia].view == "excluidos"
                        ? "Excluídos"
                        : storeArvore.instancias[instancia].view == "pesquisa"
                          ? "Pesquisa"
                          : storeArvore.instancias[instancia].view ==
                            "compartilhamentos"
                            ? "Compartilhamentos"
                            : ""
                  }}
              </button>
              <div
                class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-color fw-semibold py-4 fs-base w-200px"
                data-kt-menu="true">
                <!--begin::Menu item-->
                <div class="menu-item px-3 my-0">
                  <span @click="storeArvore.selecionarView(instancia, 'diretorio')" class="menu-link px-3 py-2" :class="{
                    active:
                      storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view == 'diretorio',
                  }" data-kt-element="mode" data-kt-value="light"
                    v-if="(storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')">
                    <span class="menu-icon" data-kt-element="icon">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen060.svg-->
                      <span class="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-folder-fill" viewBox="0 0 16 16">
                          <path
                            d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="menu-title">Pastas</span>
                  </span>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3 my-0" v-if="true">
                  <span @click="
                    storeArvore.selecionarView(instancia, 'compartilhamentos')
                    " class="menu-link px-3 py-2" :class="{
    active:
      storeArvore.instancias[instancia] &&
      storeArvore.instancias[instancia].view ==
      'compartilhamentos',
  }" data-kt-element="mode" data-kt-value="light">
                    <span class="menu-icon" data-kt-element="icon">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen060.svg-->
                      <span class="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-folder-fill" viewBox="0 0 16 16">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-folder-symlink-fill" viewBox="0 0 16 16">
                            <path
                              d="M13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2l.04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3zM2.19 3c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293L7.586 3H2.19zm9.608 5.271-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742z" />
                          </svg>
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="menu-title">Compartilhamentos</span>
                  </span>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3 my-0">
                  <span @click="
                    storeArvore.isSelecionadoArquivo(instancia) == true &&
                      (storeArvore?.instancias[instancia]?.view ==
                        'diretorio' ||
                        storeArvore?.instancias[instancia]?.view == 'pesquisa' ||
                        storeArvore?.instancias[instancia]?.view == 'compartilhamentos')
                      ? storeArvore.versoes(instancia)
                      : false
                    " class="menu-link px-3 py-2" :class="{
    active:
      storeArvore.instancias[instancia] &&
      storeArvore.instancias[instancia].view == 'versoes',
  }"
                    v-if="(storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')">
                    <span class="menu-icon" data-kt-element="icon">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen061.svg-->
                      <span class="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-badge-vr-fill" viewBox="0 0 16 16">
                          <path
                            d="M9.673 5.933v1.938h1.033c.66 0 1.068-.316 1.068-.95 0-.64-.422-.988-1.05-.988h-1.05z" />
                          <path
                            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm5.937 7 1.99-5.999H6.61L5.277 9.708H5.22L3.875 5.001H2.5L4.508 11h1.429zM8.5 5.001V11h1.173V8.763h1.064L11.787 11h1.327L11.91 8.583C12.455 8.373 13 7.779 13 6.9c0-1.147-.773-1.9-2.105-1.9H8.5z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="menu-title">Versões</span>
                  </span>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3 my-0">
                  <span class="menu-link px-3 py-2" :class="{
                    active:
                      storeArvore.instancias[instancia] &&
                      storeArvore.instancias[instancia].view == 'excluidos',
                  }" @click="storeArvore.excluidos(instancia)"
                    v-if="(storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')">
                    <span class="menu-icon" data-kt-element="icon">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen062.svg-->
                      <span class="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash-fill" viewBox="0 0 16 16">
                          <path
                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="menu-title">Excluídos</span>
                  </span>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3 my-0" :class="{
                  'd-none':
                    storeArvore.instancias[instancia].view !== 'diretorio' &&
                    storeArvore.instancias[instancia].view !==
                    'compartilhamentos' &&
                    storeArvore.instancias[instancia].view !== 'pesquisa',
                }">
                  <span @click="viewPesquisa()" class="menu-link px-3 py-2" :class="{
                    active:
                      storeArvore.instancias[instancia] &&
                      storeArvore?.instancias[instancia]?.view == 'pesquisa',
                  }" data-kt-element="mode" data-kt-value="light">
                    <span class="menu-icon" data-kt-element="icon">
                      <!--begin::Svg Icon | path: icons/duotune/general/gen060.svg-->
                      <span class="svg-icon svg-icon-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-search" viewBox="0 0 16 16">
                          <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </span>
                    <span class="menu-title">Pesquisa</span>
                  </span>
                </div>
                <!--end::Menu item-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row gy-5 g-xl-8">
      <div class="col-xl-12">
        <div class="mb-5 mb-xl-8" :class="{ 'card card-xl-stretch ': funcao == 'padrao' }">
          <div class="py-0 px-1" :class="{ 'card-body': funcao == 'padrao' }">
            <div class="row mx-1 my-3" v-if="funcao == 'padrao'">
              <div class="col col-12 d-flex justify-content-between">
                <div v-if="instancia == 'direita' && $funcoes.appTipo() == 'desktop'
                  ">
                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Transferir">
                    <button class="btn btn-icon btn-light-primary me-2" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0
                      " @click="storeArvore.transferir(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-box-arrow-in-left" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z" />
                          <path fill-rule="evenodd"
                            d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>
                </div>

                <div :class="{
                  'd-flex': true,
                  'flex-row-reverse':
                    instancia == 'esquerda' || $funcoes.appTipo() == 'web',
                }">
                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Propriedades">
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.tipo !==
                        'remoto' ||
                        storeArvore?.instancias[instancia]?.view == 'versoes',
                    }" :disabled="storeArvore.isSelecionadoArquivo(instancia) == true ||
  storeArvore.selecionadosQuantidade(instancia) !== 1
  " @click="abrirPropriedades(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-info-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path
                            d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Restaurar"
                    v-if="storeArvore?.instancias[instancia]?.view == 'versoes' || storeArvore?.instancias[instancia]?.view == 'excluidos'"
                    >
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.view !==
                        'versoes' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'excluidos',
                    }" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0 ||
  (storeArvore?.instancias[instancia]?.view ==
    'excluidos' &&
    storeArvore?.instancias[instancia]?.diretorioAtual !==
    storeArvore?.instancias[instancia]
      ?.diretorioExcluidoRoot)
  " @click="restaurarConfirmar()" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-left-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Nova Pasta" v-if="storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'pesquisa' || (storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== '')">
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        (storeArvore?.instancias[instancia]?.view !==
                          'diretorio' &&
                          storeArvore?.instancias[instancia]?.view !==
                          'compartilhamentos') ||
                        storeArvore?.instancias[instancia]?.view ==
                        'pesquisa',
                    }" @click="storeArvore.diretorioCriar(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-folder-plus" viewBox="0 0 16 16">
                          <path
                            d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z" />
                          <path
                            d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip2" data-bs-placement="top"
                    data-bs-dismiss="click" title="Excluir"
                    v-if="storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'pesquisa' || storeArvore?.instancias[instancia]?.view == 'versoes' || (storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== '')"
                    >
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.view !==
                        'diretorio' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'compartilhamentos' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'pesquisa' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'versoes',
                    }" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0
  " @click="excluirItem()" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-trash" viewBox="0 0 16 16">
                          <path
                            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Recortar" v-if="storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'pesquisa' || (storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== '')">
                    <button class="btn btn-icon btn-light-primary me-2 position-relative" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.view !==
                        'diretorio' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'compartilhamentos' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'pesquisa',
                    }" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0
  " @click="storeArvore.recortar(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-scissors" viewBox="0 0 16 16">
                          <path
                            d="M3.5 3.5c-.614-.884-.074-1.962.858-2.5L8 7.226 11.642 1c.932.538 1.472 1.616.858 2.5L8.81 8.61l1.556 2.661a2.5 2.5 0 1 1-.794.637L8 9.73l-1.572 2.177a2.5 2.5 0 1 1-.794-.637L7.19 8.61 3.5 3.5zm2.5 10a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0zm7 0a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                      <span class="position-absolute translate-middle badge badge-circle badge-danger"
                        style="top: 90%; left: 90%" v-if="storeArvore.clipboard.recortar &&
                          storeArvore.clipboard.recortar.instancia ==
                          instancia &&
                          storeArvore.clipboard.recortar.itens.length > 0
                          ">{{
    storeArvore.clipboard.recortar &&
    storeArvore.clipboard.recortar.instancia ==
    instancia &&
    storeArvore.clipboard.recortar.itens.length > 0
    ? storeArvore.clipboard.recortar.itens.length
    : ""
  }}</span>
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Copiar"
                    v-if="storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'versoes' || storeArvore?.instancias[instancia]?.view == 'pesquisa' || (storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== '')"
                    >
                    <button type="button" class="btn btn-icon btn-light-primary me-2 position-relative" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0
                        " @click="storeArvore.copiar(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-journals" viewBox="0 0 16 16">
                          <path
                            d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z" />
                          <path
                            d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                      <span class="position-absolute translate-middle badge badge-circle badge-danger"
                        style="top: 90%; left: 90%" v-if="storeArvore.clipboard.copiar &&
                          storeArvore.clipboard.copiar.instancia == instancia &&
                          storeArvore.clipboard.copiar.itens.length > 0
                          ">{{
    storeArvore.clipboard.copiar &&
    storeArvore.clipboard.copiar.instancia == instancia &&
    storeArvore.clipboard.copiar.itens.length > 0
    ? storeArvore.clipboard.copiar.itens.length
    : ""
  }}</span>
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Colar"
                    v-if="storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'pesquisa' || (storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== '')"
                    >
                    <button class="btn btn-icon btn-light-primary me-2" type="button" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.view !==
                        'diretorio' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'compartilhamentos' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'pesquisa',
                    }" :disabled="storeArvore.clipboard.copiar.itens.length == 0 &&
    storeArvore.clipboard.recortar.itens.length == 0
    " @click="storeArvore.colar(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-clipboard" viewBox="0 0 16 16">
                          <path
                            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
                          <path
                            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip2" data-bs-placement="top"
                    data-bs-dismiss="click" title="Renomear"
                    v-if="storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'pesquisa' || (storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== '')"
                    >
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.view !==
                        'diretorio' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'compartilhamentos' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'pesquisa',
                    }" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0
  " @click="storeArvore.renomear(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-cursor-text" viewBox="0 0 16 16">
                          <path
                            d="M5 2a.5.5 0 0 1 .5-.5c.862 0 1.573.287 2.06.566.174.099.321.198.44.286.119-.088.266-.187.44-.286A4.165 4.165 0 0 1 10.5 1.5a.5.5 0 0 1 0 1c-.638 0-1.177.213-1.564.434a3.49 3.49 0 0 0-.436.294V7.5H9a.5.5 0 0 1 0 1h-.5v4.272c.1.08.248.187.436.294.387.221.926.434 1.564.434a.5.5 0 0 1 0 1 4.165 4.165 0 0 1-2.06-.566A4.561 4.561 0 0 1 8 13.65a4.561 4.561 0 0 1-.44.285 4.165 4.165 0 0 1-2.06.566.5.5 0 0 1 0-1c.638 0 1.177-.213 1.564-.434.188-.107.335-.214.436-.294V8.5H7a.5.5 0 0 1 0-1h.5V3.228a3.49 3.49 0 0 0-.436-.294A3.166 3.166 0 0 0 5.5 2.5.5.5 0 0 1 5 2zm3.352 1.355zm-.704 9.29z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Abrir" v-if="storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'pesquisa'">
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.tipo ==
                        'remoto' ||
                        (storeArvore?.instancias[instancia]?.view !==
                          'diretorio' &&
                          storeArvore?.instancias[instancia]?.view !==
                          'pesquisa'),
                    }" :disabled="storeArvore.selecionadosQuantidade(instancia) !== 1
  " data-bs-dismiss="click" @click="storeArvore.abrirArquivoOS(instancia)" onclick="this.blur()">
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-terminal" viewBox="0 0 16 16">
                          <path
                            d="M6 9a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3A.5.5 0 0 1 6 9zM3.854 4.146a.5.5 0 1 0-.708.708L4.793 6.5 3.146 8.146a.5.5 0 1 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2z" />
                          <path
                            d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h12z" />
                        </svg>
                      </span>
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Abrir" v-if="$funcoes.appTipo() !== 'web' && (storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'pesquisa' || storeArvore?.instancias[instancia]?.view == 'compartilhamentos')">
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.tipo ==
                        'local' ||
                        (storeArvore?.instancias[instancia]?.view !==
                          'diretorio' &&
                          storeArvore?.instancias[instancia]?.view !==
                          'pesquisa'
                          &&
                          storeArvore?.instancias[instancia]?.view !==
                          'compartilhamentos'),
                    }" :disabled="storeArvore.selecionadosQuantidade(instancia) !== 1 || storeArvore.isSelecionadoArquivo(instancia) == false
  " data-bs-dismiss="click" @click="abrirArquivo()" onclick="this.blur()">
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                        </svg>
                      </span>
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Atualizar">
                    <button class="btn btn-icon btn-light-primary me-2" type="button"
                      @click="storeArvore.atualizar(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z" />
                          <path
                            d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Pasta Acima">
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.view !==
                        'diretorio' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'compartilhamentos' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'pesquisa' &&
                        storeArvore?.instancias[instancia]?.tipo !== 'local',
                    }" data-bs-dismiss="click" @click="pastaAcima(instancia)" onclick="this.blur()">
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
                        </svg>
                      </span>
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Pasta Anterior">
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.view !==
                        'diretorio' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'compartilhamentos' &&
                        storeArvore?.instancias[instancia]?.view !==
                        'pesquisa' &&
                        storeArvore?.instancias[instancia]?.tipo !== 'local',
                    }" data-bs-dismiss="click" @click="pastaAnterior(instancia)" onclick="this.blur()">
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                        </svg>
                      </span>
                    </button>
                  </span>
                </div>

                <div v-if="instancia == 'esquerda' || $funcoes.appTipo() == 'web'">
                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Transferir" v-if="$funcoes.appTipo() == 'desktop'"><button
                      class="btn btn-icon btn-light-primary me-2" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0
                        " @click="storeArvore.transferir(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-box-arrow-in-right" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z" />
                          <path fill-rule="evenodd"
                            d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button></span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Upload"
                    v-if="$funcoes.appTipo() == 'web' && (storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'versoes' || (storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== ''))">
                    <button class="btn btn-icon btn-light-primary me-2" @click="abrirDialogUpload()"
                      onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-cloud-upload" viewBox="0 0 16 16">
                          <path fill-rule="evenodd"
                            d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                          <path fill-rule="evenodd"
                            d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Download" v-if="$funcoes.appTipo() == 'web' && (storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'versoes' || storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== '')">
                    <button class="btn btn-icon btn-light-primary me-2" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0
                      || (storeArvore?.instancias[instancia]?.view == 'versoes' && storeArvore.selecionadosQuantidade(instancia) !== 1)
                      " @click="storeArvore.downloadAnexo(instancia)" onclick="this.blur()">
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-cloud-download" viewBox="0 0 16 16">
                          <path
                            d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
                          <path
                            d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Abrir" v-if="$funcoes.appTipo() == 'web' && (storeArvore?.instancias[instancia]?.view == 'diretorio' || storeArvore?.instancias[instancia]?.view == 'pesquisa'  || storeArvore?.instancias[instancia]?.view == 'compartilhamentos')">
                    <button class="btn btn-icon btn-light-primary me-2" :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.tipo ==
                        'local' ||
                        (storeArvore?.instancias[instancia]?.view !==
                          'diretorio' &&
                          storeArvore?.instancias[instancia]?.view !==
                          'pesquisa' &&
                          storeArvore?.instancias[instancia]?.view !==
                          'compartilhamentos'),
                    }" :disabled="storeArvore.selecionadosQuantidade(instancia) !== 1 || storeArvore.isSelecionadoArquivo(instancia) == false
  " data-bs-dismiss="click" @click="abrirArquivo()" onclick="this.blur()">
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
                          <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/>
                        </svg>
                      </span>
                    </button>
                  </span>

                  <span class="d-inline-block" tabindex="0" data-bs-dismiss="click" data-bs-toggle="tooltip2"
                    data-bs-placement="top" title="Link"
                    v-if="$funcoes.appTipo() == 'web' && (storeArvore?.instancias[instancia]?.view == 'diretorio' || (storeArvore?.instancias[instancia]?.view == 'compartilhamentos' && storeArvore?.instancias[instancia]?.diretorioAtual !== ''))">
                    <button class="btn btn-icon btn-light-primary me-2" :disabled="storeArvore.selecionadosQuantidade(instancia) == 0
                      " @click="abrirDialogLinkOpcoes(instancia)" onclick="this.blur()">
                      
                      <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                      <span class="svg-icon svg-icon-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-link" viewBox="0 0 16 16">
                          <path
                            d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z" />
                          <path
                            d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z" />
                        </svg>
                      </span>
                      <!--end::Svg Icon-->
                    </button>
                  </span>

                </div>
              </div>
            </div>

            <div class="m-3" v-if="storeArvore?.instancias[instancia]?.view == 'pesquisa'">
              <div class="input-group">
                <span class="input-group-text" id="basic-addon1"><i class="bi bi-search"></i></span>
                <input type="text" class="form-control" ref="filtroNome" v-if="storeArvore.instancias[instancia]"
                  v-model="storeArvore.instancias[instancia].filtroNome" @keyup="enterBarraIrManual" />
              </div>
            </div>

            <div class="m-3 text-center" v-if="storeArvore?.instancias[instancia]?.funcao ==
              'selecionar-diretorio' ||
              storeArvore?.instancias[instancia]?.funcao ==
              'selecionar-diretorio-multiplo'
              ">
              <button class="btn btn-light-primary" @click="storeArvore.diretorioCriar(instancia)">
                <span class="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-folder-plus" viewBox="0 0 16 16">
                    <path
                      d="m.5 3 .04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14H9v-1H2.826a1 1 0 0 1-.995-.91l-.637-7A1 1 0 0 1 2.19 4h11.62a1 1 0 0 1 .996 1.09L14.54 8h1.005l.256-2.819A2 2 0 0 0 13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2zm5.672-1a1 1 0 0 1 .707.293L7.586 3H2.19c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672z">
                    </path>
                    <path
                      d="M13.5 10a.5.5 0 0 1 .5.5V12h1.5a.5.5 0 1 1 0 1H14v1.5a.5.5 0 1 1-1 0V13h-1.5a.5.5 0 0 1 0-1H13v-1.5a.5.5 0 0 1 .5-.5z">
                    </path>
                  </svg>
                </span>
                Nova Pasta
              </button>
            </div>

            <div class="m-3">
              <div class="input-group">
                <span class="input-group-text cursor-pointer" id="barraPathOpcoesMostrar" @click="
                  storeArvore.instancias[instancia].barraPathOpcoesMostrar =
                  !storeArvore.instancias[instancia].barraPathOpcoesMostrar
                  "><i id="barraPathOpcoesMostrarIcone" class="bi bi-folder2-open"></i></span>
                <input autocomplete="off" type="text" class="form-control" id="inputBarraPath"
                  v-if="storeArvore.instancias[instancia]" v-model="storeArvore.instancias[instancia].barraPath"
                  :instancia="instancia" @blur="blurBarraIr" @keyup="enterBarraIr" @focus="
                    storeArvore.instancias[
                      instancia
                    ].barraPathOpcoesMostrar = true
                    " />
              </div>
            </div>

            <div class="row position-relative">
              <div class="col">
                <div ref="frameDiretorio" class="table-responsive py-0 px-2 m-1"
                  style="min-height: 400px; max-height: 400px; overflow: scroll">
                  <table class="table">
                    <thead>
                      <tr class="fw-bolder text-muted">
                        <th class="text-start w-25px ps-4" v-if="funcao == 'selecionar-diretorio-multiplo'"></th>
                        <th class="text-start" v-if="funcao == 'padrao' &&
                          (storeArvore?.instancias[instancia]?.view ==
                            'diretorio' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'compartilhamentos' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'pesquisa' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'excluidos')
                          ">
                          Itens ({{
                            storeArvore?.instancias[instancia]?.itensTotal
                          }})
                        </th>
                        <th class="text-start" v-if="funcao == 'padrao' &&
                            storeArvore?.instancias[instancia]?.view ==
                            'versoes'
                            ">
                          Versões ({{
                            storeArvore?.instancias[instancia]?.itensTotal
                          }})
                        </th>
                        <th class="text-center" v-if="funcao == 'padrao'">
                          Tamanho
                        </th>
                        <th class="text-center" v-if="funcao == 'padrao'">
                          Data
                        </th>
                        <th class="text-center" v-if="funcao == 'padrao'">
                          Tipo
                        </th>
                        <th class="text-center" v-if="funcao == 'padrao' &&
                          storeArvore?.instancias[instancia]?.tipo ==
                          'remoto' &&
                          (storeArvore?.instancias[instancia]?.view ==
                            'diretorio' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'compartilhamentos' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'pesquisa' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'excluidos')
                          ">
                          Versões
                        </th>
                        <th class="text-center" v-if="funcao == 'padrao' &&
                          storeArvore?.instancias[instancia]?.tipo ==
                          'remoto' &&
                          storeArvore?.instancias[instancia]?.view ==
                          'versoes'
                          ">
                          Versão
                        </th>
                      </tr>
                    </thead>

                    <tbody :class="{
                      'd-none':
                        !storeArvore?.instancias[instancia]?.carregandoItens,
                    }">
                      <tr>
                        <td colspan="10">
                          <div class="spinner-border text-primary mt-5" role="status"></div>

                          <div class="text-gray-800 fs-6 fw-semibold mt-3">
                            Carregando ...
                          </div>
                        </td>
                      </tr>
                    </tbody>

                    <tbody :class="{
                      'd-none':
                        storeArvore?.instancias[instancia]?.carregandoItens,
                    }">
                      <tr v-for="(item, indexItem) in storeArvore?.instancias[
                        instancia
                      ]?.itens" :key="item._id" :item="item" class="bg-hover-light" :class="{
  'bg-light': storeArvore.isSelecionado(
    instancia,
    item._id
  ),
}" :id="'itemDiretorio_' + instancia + '_' + indexItem"
                        @dblclick="storeArvore.navegarArvore(instancia, item)" @click="
                          storeArvore.selecionar(
                            $event,
                            instancia,
                            item,
                            indexItem
                          )
                          ">
                        <td v-if="funcao == 'selecionar-diretorio-multiplo'" class="ps-4 py-2">
                          <span class="form-check form-check-custom form-check-sm"
                            v-if="!item.isArquivo && !item.isDiretorioPai">
                            <input class="form-check-input" type="checkbox" @change="selecaoMultipla(item, $event)"
                              :checked="storeArvore.instancias[instancia]
                                  .selecaoMultipla[item._id]
                                " />
                          </span>
                        </td>
                        <td class="ps-2 pe-2" v-if="item.status &&
                          (item.status == 'diretorio-novo' ||
                            item.status == 'renomear')
                          " :colspan="storeArvore?.instancias[instancia]?.tipo == 'local'
      ? '4'
      : '5'
    ">
                          <div>
                            <div v-if="item.status == 'diretorio-novo'">
                              <div class="d-flex">
                                <span class="align-self-center svg-icon svg-icon-2x svg-icon-primary me-3"
                                  v-if="!item.tipo">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor">
                                    </path>
                                    <path
                                      d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z"
                                      fill="currentColor"></path>
                                  </svg>
                                </span>
                                <input type="text" placeholder="Nome do diretório" class="form-control me-3"
                                  v-model="item.nome" @keyup="
                                    enterDiretorioSalvar(
                                      $event,
                                      instancia,
                                      indexItem,
                                      item
                                    )
                                    " />
                                <button class="btn btn-icon btn-light-primary me-3 w-65px" id="kt_file_manager_add_folder"
                                  @click="
                                    storeArvore.diretorioSalvar(
                                      instancia,
                                      indexItem,
                                      item
                                    )
                                    ">
                                  <span class="indicator-label">
                                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr085.svg-->
                                    <span class="svg-icon svg-icon-1">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                                          fill="currentColor"></path>
                                      </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                  </span>
                                  <span class="indicator-progress">
                                    <span class="spinner-border spinner-border-sm align-middle"></span>
                                  </span>
                                </button>
                                <button class="btn btn-icon btn-light-danger w-65px" id="kt_file_manager_cancel_folder"
                                  @click="
                                    storeArvore.diretorioCancelar(
                                      instancia,
                                      indexItem
                                    )
                                    ">
                                  <span class="indicator-label">
                                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr088.svg-->
                                    <span class="svg-icon svg-icon-1">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1"
                                          transform="rotate(-45 7.05025 15.5356)" fill="currentColor"></rect>
                                        <rect x="8.46447" y="7.05029" width="12" height="2" rx="1"
                                          transform="rotate(45 8.46447 7.05029)" fill="currentColor"></rect>
                                      </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                  </span>
                                  <span class="indicator-progress">
                                    <span class="spinner-border spinner-border-sm align-middle"></span>
                                  </span>
                                </button>
                              </div>
                              <div v-if="item.erroMensagem && item.erroMensagem !== ''
                                " class="invalid-feedback text-start ps-12" :class="{
    'd-block':
      item.erroMensagem &&
      item.erroMensagem !== '',
  }">
                                {{ item.erroMensagem }}
                              </div>
                            </div>

                            <div v-if="item.status == 'renomear'">
                              <div class="d-flex">
                                <span class="svg-icon svg-icon-2x svg-icon-primary me-3 align-self-center"
                                  v-if="!item.isArquivo">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor">
                                    </path>
                                    <path
                                      d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z"
                                      fill="currentColor"></path>
                                  </svg>
                                </span>
                                <span class="svg-icon svg-icon-2x svg-icon-primary me-3 align-self-center"
                                  v-if="item.isArquivo">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none">
                                    <path opacity="0.3"
                                      d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                                      fill="currentColor"></path>
                                    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path>
                                  </svg>
                                </span>
                                <input type="text" placeholder="Novo nome" class="form-control me-3" v-model="item.nome"
                                  @keyup="
                                    enterRenomear($event, instancia, indexItem)
                                    " />
                                <button class="btn btn-icon btn-light-primary me-3 w-65px" @click="
                                  storeArvore.renomearSalvar(
                                    instancia,
                                    indexItem
                                  )
                                  ">
                                  <span class="indicator-label">
                                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr085.svg-->
                                    <span class="svg-icon svg-icon-1">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M9.89557 13.4982L7.79487 11.2651C7.26967 10.7068 6.38251 10.7068 5.85731 11.2651C5.37559 11.7772 5.37559 12.5757 5.85731 13.0878L9.74989 17.2257C10.1448 17.6455 10.8118 17.6455 11.2066 17.2257L18.1427 9.85252C18.6244 9.34044 18.6244 8.54191 18.1427 8.02984C17.6175 7.47154 16.7303 7.47154 16.2051 8.02984L11.061 13.4982C10.7451 13.834 10.2115 13.834 9.89557 13.4982Z"
                                          fill="currentColor"></path>
                                      </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                  </span>
                                  <span class="indicator-progress">
                                    <span class="spinner-border spinner-border-sm align-middle"></span>
                                  </span>
                                </button>
                                <button class="btn btn-icon btn-light-danger w-65px" @click="
                                  storeArvore.renomearCancelar(
                                    instancia,
                                    indexItem
                                  )
                                  ">
                                  <span class="indicator-label">
                                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr088.svg-->
                                    <span class="svg-icon svg-icon-1">
                                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="7.05025" y="15.5356" width="12" height="2" rx="1"
                                          transform="rotate(-45 7.05025 15.5356)" fill="currentColor"></rect>
                                        <rect x="8.46447" y="7.05029" width="12" height="2" rx="1"
                                          transform="rotate(45 8.46447 7.05029)" fill="currentColor"></rect>
                                      </svg>
                                    </span>
                                    <!--end::Svg Icon-->
                                  </span>
                                  <span class="indicator-progress">
                                    <span class="spinner-border spinner-border-sm align-middle"></span>
                                  </span>
                                </button>
                              </div>
                              <div v-if="item.erroMensagem && item.erroMensagem !== ''
                                " class="invalid-feedback text-start ps-12" :class="{
    'd-block':
      item.erroMensagem &&
      item.erroMensagem !== '',
  }">
                                {{ item.erroMensagem }}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="py-1 ps-2" v-if="!item.status">
                          <div class="d-flex align-items-center">
                            <span class="svg-icon svg-icon-2x svg-icon-primary me-4" v-if="!item.isArquivo">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" v-if="item.isDiretorioPai ||
                                  !item.compartilhamento ||
                                  item.compartilhamento.diretorio._id !==
                                  item._id
                                  ">
                                <path opacity="0.3" d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z" fill="currentColor"></path>
                                <path
                                  d="M9.2 3H3C2.4 3 2 3.4 2 4V19C2 19.6 2.4 20 3 20H21C21.6 20 22 19.6 22 19V7C22 6.4 21.6 6 21 6H12L10.4 3.60001C10.2 3.20001 9.7 3 9.2 3Z"
                                  fill="currentColor"></path>
                              </svg>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                fill="none" v-if="(!item.isDiretorioPai ||
                                    item.isDiretorioPai == false) &&
                                  item.compartilhamento &&
                                  item.compartilhamento.diretorio._id ==
                                  item._id
                                  ">
                                <path fill="currentColor"
                                  d="M13.81 3H9.828a2 2 0 0 1-1.414-.586l-.828-.828A2 2 0 0 0 6.172 1H2.5a2 2 0 0 0-2 2l.04.87a1.99 1.99 0 0 0-.342 1.311l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3zM2.19 3c-.24 0-.47.042-.683.12L1.5 2.98a1 1 0 0 1 1-.98h3.672a1 1 0 0 1 .707.293L7.586 3H2.19zm9.608 5.271-3.182 1.97c-.27.166-.616-.036-.616-.372V9.1s-2.571-.3-4 2.4c.571-4.8 3.143-4.8 4-4.8v-.769c0-.336.346-.538.616-.371l3.182 1.969c.27.166.27.576 0 .742z" />
                              </svg>
                            </span>
                            <span class="svg-icon svg-icon-2x svg-icon-primary me-4" v-if="item.isArquivo">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none">
                                <path opacity="0.3"
                                  d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22Z"
                                  fill="currentColor"></path>
                                <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path>
                              </svg>
                            </span>
                            <div class="text-start">
                              <span class="text-dark fw-bolder text-hover-primary fs-7 prevent-select">
                                {{ item.nome }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td class="text-center py-1" v-if="funcao == 'padrao' && !item.status">
                          <div class="">
                            <div class="text-muted fw-bold d-block fs-7 mt-1 prevent-select">
                              {{
                                item.tamanho > 0
                                ? $funcoes.tamanhoTexto(item.tamanho)
                                : item.tamanho
                              }}
                            </div>
                          </div>
                        </td>
                        <td class="text-center py-1" v-if="funcao == 'padrao' && !item.status">
                          <div class="">
                            <div class="text-muted fw-bold d-block fs-7 mt-1 prevent-select">
                              {{
                                $funcoes.formatarDataHora(
                                  item.dataAlteracao
                                    ? item.dataAlteracao
                                    : item.data
                                )
                              }}
                            </div>
                          </div>
                        </td>
                        <td class="text-center py-1" v-if="funcao == 'padrao' && !item.status">
                          <div class="text-muted fw-bold d-block fs-7 mt-1 prevent-select">
                            {{ item.isArquivo ? item.tipo : "DIR" }}
                          </div>
                        </td>
                        <td class="text-center py-1" v-if="funcao == 'padrao' &&
                          !item.status &&
                          storeArvore?.instancias[instancia]?.tipo ==
                          'remoto' &&
                          (storeArvore?.instancias[instancia]?.view ==
                            'diretorio' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'compartilhamentos' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'pesquisa' ||
                            storeArvore?.instancias[instancia]?.view ==
                            'excluidos')
                          ">
                          <div class="text-muted fw-bold d-block fs-7 mt-1 prevent-select d-flex justify-content-center">
                            <div>
                              {{
                                item.versoes
                                ? Object.keys(item.versoes).length
                                : ""
                              }}
                            </div>
                            <div v-if="item.isArquivo &&
                                item.statusTransferencia &&
                                item.statusTransferencia == 'concluido'
                                " style="margin-top: 8px"
                              class="ms-3 bullet bullet-dot bg-success h-6px w-6px translate-middle"></div>
                          </div>
                        </td>
                        <td class="py-1 text-center pe-2" v-if="funcao == 'padrao' &&
                          !item.status &&
                          storeArvore?.instancias[instancia]?.tipo ==
                          'remoto' &&
                          storeArvore?.instancias[instancia]?.view ==
                          'versoes'
                          ">
                          <div class="text-muted fw-bold d-block fs-7 mt-1 prevent-select d-flex justify-content-center">
                            <div>{{ item.versao }}</div>
                            <div v-if="item.isArquivo &&
                              item.statusTransferencia &&
                              item.statusTransferencia == 'concluido'
                              " style="margin-top: 8px"
                              class="ms-3 bullet bullet-dot bg-success h-6px w-6px translate-middle"></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col position-absolute" style="background-color: red">
                <div class="position-relative">
                  <div class="position-absolute top-0 start-50 translate-middle-x" style="
                      width: 100%;
                      margin-top: 0px;
                      padding-left: 50px;
                      padding-right: 12px;
                    ">
                    <div class="card hover-elevate-up shadow-sm parent-hover" :class="{
                      'd-block':
                        storeArvore.instancias[instancia] &&
                        storeArvore.instancias[instancia]
                          .barraPathOpcoesMostrar == true,
                      'd-none':
                        !storeArvore.instancias[instancia] ||
                        storeArvore.instancias[instancia]
                          .barraPathOpcoesMostrar == false,
                    }" :id="'barraPathOpcao_' + instancia" ref="barraPathOpcao">
                      <div class="card-body p-3" v-if="storeArvore.instancias[instancia] &&
                        storeArvore.instancias[instancia]
                          .barraPathOpcoesMostrar == true &&
                        Object.keys(
                          storeArvore.instancias[instancia].barraPathOpcoes
                        ).length > 0
                        ">
                        <div v-for="(
                            barraPathOpcaoKey, barraPathOpcaoIndex
                          ) in Object.keys(
                                storeArvore.instancias[instancia].barraPathOpcoes
                              ).reverse()" :key="barraPathOpcaoKey">
                          <div class="d-flex align-items" :class="{ 'mt-3': barraPathOpcaoIndex > 0 }">
                            <!--begin::Svg Icon | path: icons/duotune/general/gen002.svg-->
                            <span class="svg-icon svg-icon-3">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="bi bi-clock-history" viewBox="0 0 16 16">
                                <path
                                  d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                                <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                                <path
                                  d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                              </svg>
                            </span>
                            <!--end::Svg Icon-->
                            <span class="ms-3 text-gray-700 parent-hover-primary fs-7 fw-bold cursor-pointer" @click="
                              barraPathOpcaoClique(
                                storeArvore.instancias[instancia]
                                  .barraPathOpcoes[barraPathOpcaoKey]
                              )
                              ">{{
    storeArvore.instancias[instancia]
      .barraPathOpcoes[barraPathOpcaoKey].path
  }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <NuvemConectarDialog ref="nuvemConectarDialog" :instancia="instancia" @dialog-off="onFecharDialogConectar()" />
    <ComponentUploadHtml ref="dialogUploadHtml" :instancia="instancia" />
    <LinkOpcoesDialog ref="dialogLinkOpcoes" :instancia="instancia" />
    <PropriedadesDialog ref="propriedadesDialog" :instancia="instancia" />
    <DialogMensagem ref="dialogExcluir" titulo="Excluir"
      mensagem="Confirme caso deseje excluir o(s) item selecionado(s) ?" classBody="mx-5 mx-xl-15 my-1 text-center"
      @confirmar="storeArvore.excluir(instancia)" />
    <DialogMensagem ref="dialogRestaurar" titulo="Restaurar"
      mensagem="Confirme caso deseje restaurar o item selecionado ?" classBody="mx-5 mx-xl-15 my-1 text-center"
      @confirmar="restaurar()" />
    <DialogMensagem ref="dialogRestaurarMensagem" titulo="Restaurar" classBody="mx-5 mx-xl-15 my-1 text-center"
      tipo="erro" />
  </div>
</template>

<script>
//import Combo from "../../components/ComponentCombo.vue";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreUpload } from "../stores/storeUpload";
import { useStoreArvore } from "../stores/storeArvore.js";

import DialogMensagem from "../components/ComponentDialogMensagem.vue";
import ComponentUploadHtml from "../components/ComponentUploadHtml.vue";

import NuvemConectarDialog from "./NuvemConectarDialog.vue";
import PropriedadesDialog from "./PropriedadesDialog.vue";
import Dialog from "../components/ComponentDialog.vue";
import LinkOpcoesDialog from "./LinkOpcoesDialog.vue";

//* XX const path = require("path");

var path;

try {
  path = require("path");
} catch (errPath) {
  console.log(errPath);
}

export default {
  components: {
    NuvemConectarDialog,
    PropriedadesDialog,
    LinkOpcoesDialog,
    DialogMensagem,
    Dialog,
    ComponentUploadHtml,
  },

  props: {
    tipo: { type: String, default: "local" },
    instancia: { type: String, default: "padrao" },
    funcao: { type: String, default: "padrao" },
  },

  setup() {
    const storeAuth = useAuthStore();
    const storeUpload = useStoreUpload();
    const storeArvore = useStoreArvore();

    return { storeUpload, storeAuth, storeArvore };
  },

  data() {
    return {
      timerFiltroTexto: null,
      filtroTexto: "",
      barraPath: "",
      ordem: "nome_ASC",
    };
  },

  async mounted() {
    var thisCall = this;

    //* Ajuste menu view
    setTimeout(function () {
      window.KTMenu.init();
      window.KTMenu.init();
    }, 500);

    //* Cria a arvore
    await this.storeArvore.criar(this.instancia, this.tipo);

    //* Atualiza os dados da sessao
    this.storeAuth.atualizarSessao();

    //* Define a funcao da instancia
    this.storeArvore.instancias[this.instancia].funcao = this.funcao;

    //* Redimensiona o diretorio
    window.addEventListener(
      "resize",
      function (event) {
        thisCall.redimensionar();
      },
      true
    );

    setTimeout(function () {
      thisCall.redimensionar();
    }, 200);

    document.addEventListener("click", function (evt) {
      if (thisCall.$refs.barraPathOpcao == null) {
        return;
      }

      if (
        evt.target.id == "barraPathOpcoesMostrar" ||
        evt.target.id == "barraPathOpcoesMostrarIcone" ||
        (evt.target.id == "inputBarraPath" &&
          evt.target.getAttribute("instancia") == thisCall.instancia)
      ) {
        return;
      }

      //* Verifica se clicou fora da barra de opcoes
      let itemComparar = document.getElementById(
        thisCall.$refs.barraPathOpcao.id
      ),
        targetItem = evt.target;

      //* Compara todos os elementos
      do {
        if (targetItem == itemComparar) {
          return;
        }
        targetItem = targetItem.parentNode;
      } while (targetItem);

      //* Verifica se a instancia esta criada
      if (thisCall.storeArvore.instancias[thisCall.instancia]) {
        //* Esconde o menu de opcoes
        thisCall.storeArvore.instancias[
          thisCall.instancia
        ].barraPathOpcoesMostrar = false;
      }
    });
  },

  methods: {
    redimensionar() {
      if (this.funcao == "padrao") {
        var tamanhoFrame = 290;

        //* Caso seja a pesquisa, acerta o tamanho do frame
        if (this.storeArvore.instancias[this.instancia].view == "pesquisa") {
          tamanhoFrame = 345;
        }

        if (this.$refs.frameDiretorio) {
          this.$refs.frameDiretorio.style["max-height"] =
            window.innerHeight - tamanhoFrame + "px";
          this.$refs.frameDiretorio.style["min-height"] =
            window.innerHeight - tamanhoFrame + "px";
        }
      }
    },
    enterBarraIr(event) {
      //* Esconde o menu de opcoes
      this.storeArvore.instancias[
        this.instancia
      ].barraPathOpcoesMostrar = false;

      //* Abre o diretorio informado
      this.enterBarraIrManual(event, false);
    },
    enterBarraIrManual(event, manual = true) {
      if (event.keyCode === 13) {
        //* Verifica se continua o mesmo path
        if (
          this.storeArvore.instancias[this.instancia].barraPath ==
          this.storeArvore.instancias[this.instancia].barraPathAnterior &&
          manual == false
        ) {
          return;
        }

        if (this.$funcoes.appTipo() == "desktop") {
          //* Ajusta o path
          if (
            this.storeArvore.instancias[this.instancia].tipo == "local" &&
            this.storeArvore.instancias[this.instancia].barraPath.trim() !== ""
          ) {
            this.storeArvore.instancias[this.instancia].barraPath =
              path.resolve(
                this.storeArvore.instancias[this.instancia].barraPath
              );
          }
        }

        //* Carrega o path preenchido
        var barraPathValida =
          this.storeArvore.instancias[this.instancia].barraPath;

        //* Valida se contem barra no ultimo path
        if (
          barraPathValida !== "" &&
          (barraPathValida[barraPathValida.length - 1] == "/" ||
            barraPathValida[barraPathValida.length - 1] == "\\")
        ) {
          this.storeArvore.instancias[this.instancia].barraPath =
            barraPathValida.slice(0, -1);
        }

        //* Define para abrir o diretorio
        this.storeArvore.diretorioIr(
          this.instancia,
          this.storeArvore.instancias[this.instancia].barraPath,
          "",
          true
        );
      }
      event.stopPropagation();
    },

    blurBarraIr(event) {
      //* Verifica se continua o mesmo path
      if (
        this.storeArvore.instancias[this.instancia].barraPath ==
        this.storeArvore.instancias[this.instancia].barraPathAnterior
      ) {
        return;
      }

      //* Ajusta o path
      if (
        this.storeArvore.instancias[this.instancia].tipo == "local" &&
        this.storeArvore.instancias[this.instancia].barraPath.trim() !== ""
      ) {
        this.storeArvore.instancias[this.instancia].barraPath = path.resolve(
          this.storeArvore.instancias[this.instancia].barraPath
        );
      }

      //* Carrega o path preenchido
      var barraPathValida =
        this.storeArvore.instancias[this.instancia].barraPath;

      //* Valida se contem barra no ultimo path
      if (
        barraPathValida !== "" &&
        (barraPathValida[barraPathValida.length - 1] == "/" ||
          barraPathValida[barraPathValida.length - 1] == "\\")
      ) {
        this.storeArvore.instancias[this.instancia].barraPath =
          barraPathValida.slice(0, -1);
      }

      //* Define para abrir o diretorio
      this.storeArvore.diretorioIr(
        this.instancia,
        this.storeArvore.instancias[this.instancia].barraPath
      );

      event.stopPropagation();
    },

    enterDiretorioSalvar(event, instancia, indexItem, item) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.storeArvore.diretorioSalvar(instancia, indexItem, item);
      }
      event.stopPropagation();
    },
    enterRenomear(event, instancia, indexItem) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.storeArvore.renomearSalvar(instancia, indexItem);
      }
      event.stopPropagation();
    },
    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const thisCall = this;

      this.timerFiltroTexto = setTimeout(function () {
        //obj.$refs.paginacao.pagina = 1;
        thisCall.storeArvore.carregarItens(thisCall.instancia);
      }, 300);
    },
    restaurarConfirmar() {
      this.$refs.dialogRestaurar.abrir();
    },
    restaurar() {
      var thisCall = this;

      //* Verifica se esta visualizando as versoes
      if (this.storeArvore.instancias[this.instancia].view == "versoes") {
        this.storeArvore.restaurarVersao(this.instancia);

        //* Verifica se esta visualizando as versoes
      } else if (
        this.storeArvore.instancias[this.instancia].view == "excluidos"
      ) {
        //* Restaura os itens excluidos
        this.storeArvore
          .restaurarExcluido(this.instancia)
          .then(function (resultRestaurarExcluido) {
            //* Verifica se restaurou os arquivos
            if (resultRestaurarExcluido !== "") {
              thisCall.$refs.dialogRestaurarMensagem.mensagemCustomizada =
                resultRestaurarExcluido;
              thisCall.$refs.dialogRestaurarMensagem.abrir();
            }
          });
      }
    },
    excluirItem() {
      this.$refs.dialogExcluir.abrir();
    },
    abrirNuvemConectarDialog() {
      this.$emit("dialog-on");
      this.$refs.nuvemConectarDialog.abrir();
    },
    abrirPropriedades(instancia, diretorioRoot = false) {
      this.$refs.propriedadesDialog.carregarItem(instancia, diretorioRoot);
      this.$refs.propriedadesDialog.abrir();
    },
    async clickColar(event) {
      await this.tooltipHide(event);
    },
    onFecharDialogConectar() {
      this.$emit("dialog-off");

      setTimeout(function () {
        //window.KTApp.init();
        //window.KTMenu.init();
        //window.KTApp.initBootstrapTooltips();
      }, 200);
    },
    async tooltipHide(item) {
      var valor = item.srcElement.attributes["aria-describedby"]?.value;

      if (valor !== null) {
        var tooltip = document.getElementById(valor);
        if (tooltip !== null) {
          tooltip.style.display = "none";
        }
      }
    },
    viewPesquisa() {
      var thisCall = this;

      this.storeArvore.instancias[this.instancia].filtroNome = "";
      this.storeArvore.instancias[this.instancia].view = "pesquisa";
      this.redimensionar();
      setTimeout(function () {
        thisCall.$refs.filtroNome.focus();
      }, 200);
    },
    selecaoMultipla(item, check) {
      this.storeArvore.selecaoMultipla(
        this.instancia,
        item,
        check.srcElement.checked
      );
    },
    pastaAnterior(instancia) {
      this.storeArvore.pastaAnterior(instancia);
    },
    pastaAcima(instancia) {
      this.storeArvore.pastaAcima(instancia);
    },
    barraPathOpcaoClique(item) {
      this.storeArvore.instancias[this.instancia].barraPath = item.path;
      this.enterBarraIrManual({ keyCode: 13, stopPropagation: () => { } }, true);
      this.storeArvore.instancias[
        this.instancia
      ].barraPathOpcoesMostrar = false;
    },
    downloadAnexo(instancia) {
      storeArvore.downloadAnexo(instancia);
    },
    abrirDialogUpload() {
      this.$refs.dialogUploadHtml.abrir();
    },
    abrirDialogLinkOpcoes(instancia) {
      this.$refs.dialogLinkOpcoes.abrir();
    },
    abrirArquivo()
    {
      this.$emit("abrir-arquivo",{instancia: this.instancia, _id: this.storeArvore.instancias[this.instancia].selecionados[Object.keys(this.storeArvore.instancias[this.instancia].selecionados)]._id});
    }
  },
};
</script>

<style></style>
