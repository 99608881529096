import axios from "axios";
//const fs = require("fs");
//const path = require("path");
//import fetch from "fetch";
//const fetch = require("fetch");

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use(
	function (config) {
		const token = localStorage.getItem("ckd");

		if (token) {
			config.headers.Authorization = token;
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

const config = {
	headers: { "Access-Control-Allow-Origin": "*","Enuve-Api-Versao": "v2"},
	withCredentials: false
};

export const api = {
	get(endpoint, token = null) {
		if (token == null) {
			return axiosInstance.get(endpoint, config);
		} else {
			return axiosInstance.get(endpoint, {
				transformRequest: (data, headers) => {
					headers["Authorization"] = token;
					headers["Enuve-Api-Versao"] = "v2";
					return data;
				}
			});
		}
	},
	post(endpoint, body, token = null) {
		if (token == null) {
			return axiosInstance.post(endpoint, body, config);
		} else {
			var customConfig = { ...config };
			customConfig.headers["Authorization"] = token;

			return axios.post(endpoint, body, customConfig);
		}
	},
	put(endpoint, body, token = null) {
		if (token == null) {
			return axiosInstance.put(endpoint, body, config);
		} else {
			var customConfig = { ...config };
			customConfig.headers["Authorization"] = token;

			return axios.put(endpoint, body, customConfig);
		}
	},
	delete(endpoint, token = null) {
		if (token == null) {
			return axiosInstance.delete(endpoint, config);
		} else {
			var customConfig = { ...config };
			customConfig.headers["Authorization"] = token;

			return axios.delete(endpoint, customConfig);
		}
	},
	valid(result) {
		if (result.erro && result.erroTipo && result.erroTipo === "TOKEN") {
			localStorage.removeItem("ckd");
			localStorage.removeItem("ckdCch");

			if (process.env.NODE_ENV == "development") {
				window.location = "/app/login";
			} else {
				window.location.replace("app://./index.html");
			}
		}
	},
	usuarioFoto(id, foto) {
		if (foto) {
			return process.env.VUE_APP_URL_PUBLIC + "/usuarios/" + id + ".png";
		} else {
			return "/assets/media/avatars/blank.png";
		}
	},
	uploadFileCompleto(arquivo) {
		const token = localStorage.getItem("ckd");

		const formData = new FormData();
		const req = new XMLHttpRequest();

		const chunk = arquivo.file.slice(arquivo.startingByte);

		formData.append("chunk", chunk, arquivo.fileName);
		//formData.append("chunk", arquivo.file, arquivo.fileName);
		formData.append("fileId", arquivo.fileId);

		req.open("POST", process.env.VUE_APP_URL_API + "/upload", true);
		req.setRequestHeader("Authorization", token);
		req.setRequestHeader("Content-Range", `bytes=${arquivo.startingByte}-${arquivo.startingByte + arquivo.file.size}/${arquivo.file.size}`);
		req.setRequestHeader("Access-Control-Allow-Origin", "*");
		req.setRequestHeader("X-File-Id", arquivo._id);
		req.setRequestHeader("X-File-Version", arquivo.arquivoIdExterno);

		req.onload = (e) => {
			if (req.status === 200) {
				arquivo.onComplete(e, arquivo);
			} else {
				arquivo.onError(e, arquivo);
			}
		};

		req.upload.onprogress = (e) => {
			arquivo.onProgress(e, arquivo);
		};

		req.ontimeout = (e) => arquivo.onError(e, arquivo);

		req.onabort = (e) => arquivo.onAbort(e, arquivo);

		req.onerror = (e) => arquivo.onError(e, arquivo);

		req.send(formData);

		arquivo.request = req;
		return req;
	},
	uploadFile(arquivo, apiNuvem, chunk, customToken = null) {
		try {
			//* Carrega o token
			var token = localStorage.getItem("ckd");

			//* Verifica se esta conectado a outra nuvem
			if (customToken !== null) {
				token = customToken;
			}

			const formData = new FormData();
			const req = new XMLHttpRequest();

			//* Define os dados a serem enviados
			formData.append("chunk", chunk, arquivo.fileName);
			formData.append("fileId", arquivo.arquivoIdExterno);

			//* Configura o post dos dados do arquivo
			req.open("POST", apiNuvem + "/upload", true);
			req.setRequestHeader("Authorization", token);
			req.setRequestHeader("Content-Range", `bytes=${arquivo.startingByte}-${arquivo.startingByte + chunk.size}/${arquivo.file.size}`);
			req.setRequestHeader("Access-Control-Allow-Origin", "*");
			req.setRequestHeader("X-File-Id", arquivo._id);
			req.setRequestHeader("X-File-Version", arquivo.arquivoIdExterno);

			req.onload = (e) => {
				if (req.status === 200) {
					arquivo.onComplete(e, arquivo);
				} else {
					arquivo.onError("UPLOAD");
				}
			};

			req.upload.onprogress = (e) => {
				arquivo.onProgress(e, arquivo);
			};

			req.ontimeout = (e) => arquivo.onError(e, arquivo);
			req.onabort = (e) => arquivo.onAbort(e, arquivo);
			req.onerror = () => arquivo.onError("UPLOAD");

			//* Envia os dados
			req.send(formData);

			//* Armazena o request
			arquivo.request = req;

			//* Retorna o request
			return req;
		} catch (err) {
			arquivo.onError("UPLOAD");
			return null;
		}
	},
	downloadFile(arquivo, apiNuvem, customToken = null) {
		//* Carrega o token
		var token = localStorage.getItem("ckd");

		//* Verifica se esta conectado a outra nuvem
		if (customToken !== null) {
			token = customToken;
		}

		var headers = {
			Range: "bytes=" + arquivo.startingByte,
			Authorization: token
		};

		fetch(apiNuvem + "/upload/download/" + arquivo.id + "?startByte=" + arquivo.startingByte + (arquivo.versaoId == "" ? "" : "&versao=" + arquivo.versaoId), { useElectronNet: false, headers: headers })
			.then(
				(res) =>
					new Promise(() => {
						//* Trata os eventos do arquivo
						res.body.on("data", (chuck) => {
							arquivo.onProgress(chuck, arquivo);
						});
						res.body.on("end", () => {
							arquivo.onComplete(arquivo);
						});
						res.body.on("error", () => {
							arquivo.onError("CONEXAO");
						});

						//* Define como padrao para substituir o arquivo
						var propriedadesArquivo = { flags: "w" };

						//* Verifica se esta retomando um upload
						if (arquivo.startingByte > 0) {
							//* Define que vai append no arquivo
							propriedadesArquivo.flags = "a";
						}

						var dest = null;

						try {
							//* Cria/Abre o arquivo de destino  do download
							//* XX dest = fs.createWriteStream(path.resolve(arquivo.diretorioDestinoCaminho, arquivo.fileName), propriedadesArquivo);

							dest.on("error", () => {
								arquivo.onError("SALVAR");
							});

							//* Efetua o stream do conteudo recebido para o arquivo no disco
							res.body.pipe(dest);

							//* Armazena o request
							arquivo.request = res;
						} catch (errDestination) {
							arquivo.onError("SALVAR");
						}
					})
			)
			.catch((error) => {
				console.log(error);
				arquivo.onError("CONEXAO");
			});
	},
	uploadFileAxios(arquivo, apiNuvem) {
		const token = localStorage.getItem("ckd");

		const FormData = require("form-data"); // npm install --save form-data
		const chunk = arquivo.file.slice(arquivo.startingByte);

		const form = new FormData();
		form.append("file", chunk, arquivo.fileName);

		const request_config = {
			headers: {
				Authorization: token,
				"Content-Range": `bytes=${arquivo.startingByte}-${arquivo.startingByte + arquivo.file.size}/${arquivo.file.size}`,
				"Access-Control-Allow-Origin": "*",
				"X-File-Id": arquivo._id,
				"X-File-Version": arquivo.arquivoIdExterno,
				"Content-Type": "multipart/form-data"
			}
		};

		axios.post(apiNuvem + "/upload", form, request_config);

		return true;
	},
	async uploadFileHtml(apiNuvem, arquivo, customToken) {
		let token = localStorage.getItem("ckd");

		if(customToken !== null){
			token = customToken;
		}

		const FormData = require("form-data"); 
		
		const form = new FormData();
		form.append("file", arquivo.file.slice(0), arquivo.name);
	
		const request_config = {
			headers: {
				Authorization: token,
				"Content-Range": `bytes=0-${arquivo.file.size}/${arquivo.file.size}`,
				"Access-Control-Allow-Origin": "*",
				"X-File-Id": arquivo._id,
				"X-File-Version": arquivo.versao,
				"Content-Type": "multipart/form-data"
			},
			withCredentials: false,
			onUploadProgress: (progressEvent) => {
			
				let progresso = Math.round(
					(progressEvent.loaded * 100) / progressEvent.total
				);

				arquivo.progresso = progresso;

			},
		};

		var result = await axios.post(apiNuvem + "/upload", form, request_config);
		return result;
	},
	async getItensObjectId(endpoint, token = null) {
		var resultItens;

		if (token == null) {
			resultItens = await axiosInstance.get(endpoint, config);
		} else {
			resultItens = await axiosInstance.get(endpoint, {
				transformRequest: (data, headers) => {
					headers["Authorization"] = token;
					return data;
				}
			});
		}

		var resultObject = {};

		resultItens.data.itens.map(function (item) {
			resultObject[item._id] = item;
		});

		return resultObject;
	},
	errosLimpar(itemErros) {
		Object.entries(itemErros).forEach(([key]) => {
			itemErros[key] = { status: false, mensagem: "" };
		});
		return itemErros;
	},
	errosProcessar(itemErros, erros) {
		for (let indiceErro = 0; indiceErro < erros.length; indiceErro++) {
			const itemErro = erros[indiceErro];
			itemErros[itemErro.campo] = { status: true, mensagem: itemErro.mensagem };
		}

		return itemErros;
	}
};
