<template>
  <div>
    <div class="row gy-5 g-xl-8">
      <div class="mb-5 mb-xl-8" :class="{ 'card card-xl-stretch': !dialog }">
        <div
          class="border-0"
          :class="{
            'card-header pt-6': !dialog,
            'd-flex justify-content-between': dialog,
          }"
        >
          <div class="col">
            <div class="row">
              <div class="col-lg-6 fv-row">
                <div class="fv-row mb-7">
                  <div class="d-flex align-items-center position-relative my-1">
                    <span class="svg-icon svg-icon-1 position-absolute ms-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="17.0365"
                          y="15.1223"
                          width="8.15546"
                          height="2"
                          rx="1"
                          transform="rotate(45 17.0365 15.1223)"
                          fill="currentColor"
                        ></rect>
                        <path
                          d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    <input
                      type="text"
                      class="form-control form-control-solid w-250px ps-14"
                      placeholder="Pesquisar"
                      @keyup="keyupFiltroTexto"
                      v-model="filtroTexto"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>

              <div class="col-lg-6 fv-row" v-if="sysadmin">
                <div class="fv-row mb-7">
                  <div class="d-flex align-items-center position-relative my-1">
                    <button
                      class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Admin"
                      @click="filtroTipoUsuarioAdmin = !filtroTipoUsuarioAdmin"
                    >
                      <span
                        :class="
                          'svg-icon svg-icon-3 text-' +
                          (filtroTipoUsuarioAdmin ? 'primary' : 'dark')
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-person-fill"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                          />
                        </svg>
                      </span>
                    </button>
                    <button
                      class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Admin Nuvem"
                      @click="
                        filtroTipoUsuarioAdminNuvem =
                          !filtroTipoUsuarioAdminNuvem
                      "
                    >
                      <span
                        :class="
                          'svg-icon svg-icon-3 text-' +
                          (filtroTipoUsuarioAdminNuvem ? 'primary' : 'dark')
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-cloud"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                          />
                        </svg>
                      </span>
                    </button>
                    <button
                      class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Usuário Nuvem"
                      @click="
                        filtroTipoUsuarioUsuarioNuvem =
                          !filtroTipoUsuarioUsuarioNuvem
                      "
                    >
                      <span
                        :class="
                          'svg-icon svg-icon-3 text-' +
                          (filtroTipoUsuarioUsuarioNuvem ? 'primary' : 'dark')
                        "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-cloud"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="py-3" :class="{ 'card-body': !dialog }">
          <div class="table-responsive">
            <table
              class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
            >
              <thead>
                <tr class="fw-bolder text-muted">
                  <th class="w-25px" style="display: none">
                    <div
                      class="form-check form-check-sm form-check-custom form-check-solid"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value="1"
                        data-kt-check="true"
                        data-kt-check-target=".widget-9-check"
                      />
                    </div>
                  </th>
                  <th class="min-w-200px">Sessões ({{ itensTotal }})</th>
                  <th class="mw-200px min-w-200px">Tipo de usuário</th>
                  <th class="mw-200px min-w-200px" v-if="colunaNuvem">Nuvem</th>
                  <th class="mw-200px min-w-200px">Dispositivo</th>
                  <th class="mw-200px min-w-200px text-center">Data</th>
                  <th class="w-40px text-end">Opções</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in itens" :key="item._id" :item="item">
                  <td style="display: none">
                    <div
                      class="form-check form-check-sm form-check-custom form-check-solid"
                    >
                      <input
                        class="form-check-input widget-9-check"
                        type="checkbox"
                        value="1"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-45px me-5">
                        <img
                          :src="
                            $funcoes.usuarioFoto(
                              item.usuario._id,
                              item.usuario.foto == true ? true : false
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="d-flex justify-content-start flex-column">
                        <span
                          v-if="sysadmin"
                          class="text-dark fw-bolder text-hover-primary fs-6"
                          style="cursor: pointer"
                          @click="editarItem(item.usuario._id)"
                        >
                          {{ item.usuario.nome }}
                        </span>
                        <span v-if="!sysadmin" class="text-dark fw-bolder fs-6">
                          {{ item.usuario.nome }}
                        </span>
                        <span
                          class="text-muted fw-bold text-muted d-block fs-7"
                          >{{ item.usuario.nomeUsuario }}</span
                        >
                      </div>
                    </div>
                  </td>

                  <td>
                    <span class="text-dark fw-bolder d-block fs-6">
                      {{
                        item.usuario.tipoUsuario
                          ? $funcoes.tiposUsuario()[item.usuario.tipoUsuario]
                              .nome
                          : "Admin"
                      }}</span
                    >
                    <span class="text-muted fw-bold d-block fs-7">
                      {{ item.usuario.email }}</span
                    >
                  </td>

                  <td v-if="colunaNuvem">
                    <span class="text-dark fw-bolder d-block fs-6">
                      {{ item.cliente ? item.cliente.nome : "Admin" }}
                    </span>
                    <span class="text-muted fw-bold text-muted d-block fs-7">{{
                      item.nuvem ? item.nuvem.nome : ""
                    }}</span>
                  </td>

                  <td>
                    <span class="text-dark fw-bolder d-block fs-6">
                      {{ item.deviceName ? item.deviceName : "" }}
                    </span>
                  </td>

                  <td class="text-center">
                    <span class="text-dark d-block fs-7">
                      {{ $funcoes.formatarDataHora(item.data) }}
                    </span>
                  </td>

                  <td>
                    <div class="d-flex justify-content-end flex-shrink-0">
                      <button
                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        data-bs-dismiss="click"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Desconectar"
                        @click="desconectar(item._id)"
                        onclick="this.blur()"
                      >
                        <span class="svg-icon svg-icon-3">
                          <i class="bi bi-wifi-off"></i>
                        </span>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Paginacao
            ref="paginacao"
            :total="itensTotal"
            :limit="20"
            @change-pagina="carregarItens()"
          />
        </div>
      </div>
    </div>

    <UsuarioEdit
      :id="'formUsuario'"
      ref="formUsuario"
      view="modal"
      :sysadmin="sysadmin"
      @save-item="saveItem()"
      @change-foto="carregarItens()"
      @dialog-on="
        if (this.dialog) {
          $emit('dialog-on');
        }
      "
      @dialog-off="
        if (this.dialog) {
          $emit('dialog-off');
        }
      "
    />

    <UsuarioSelectDialog
      ref="usuarioSelectDialog"
      @dialog-on="
        if (this.dialog) {
          $emit('dialog-on');
        }
      "
      @dialog-off="
        if (this.dialog) {
          $emit('dialog-off');
        }
      "
    />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

// import Combo from "../components/ComponentCombo.vue";
import Paginacao from "../components/ComponentPaginacao.vue";
import UsuarioEdit from "./UsuarioEdit.vue";
import UsuarioSelectDialog from "./UsuarioSelectDialog.vue";

export default {
  components: { UsuarioEdit, UsuarioSelectDialog, Paginacao },
  props: {
    dialog: { type: Boolean, default: false },
    buttonSelecionar: { type: Boolean, default: false },
    colunaNuvem: { type: Boolean, default: false },
    sysadmin: { type: Boolean, default: false },
  },
  computed: {
    tiposUsuario: function () {
      var thisCall = this;

      var arrTiposUsuario = this.$funcoes.tiposUsuarioArray();

      return arrTiposUsuario;
    },
  },

  data() {
    return {
      timerFiltroTexto: null,
      itensTotal: 0,
      filtroTexto: "",
      filtroTipoUsuarioAdmin: true,
      filtroTipoUsuarioAdminNuvem: true,
      filtroTipoUsuarioUsuarioNuvem: true,
      filtroCliente: "",
      filtroNuvem: "",
      ordem: "tipoUsuario_ASC,nome_ASC,data_DESC",
      itens: [],
      estatisticas: {
        espacoTotal: 0,
        espacoUtilizado: 0,
        usuariosTotal: 0,
        usuariosUtilizado: 0,
      },
    };
  },

  mounted() {
    if (!this.dialog) {
      this.carregarItens();
    }
  },

  watch: {
    filtroTipoUsuarioAdmin() {
      this.carregarItens();
    },
    filtroTipoUsuarioAdminNuvem() {
      this.carregarItens();
    },
    filtroTipoUsuarioUsuarioNuvem() {
      this.carregarItens();
    },
  },

  methods: {
    async saveItem() {
      if (this.dialog) {
        this.$emit("dialog-off");
      }

      this.carregarItens();
    },
    async carregarItens() {
      let filtros = "";

      filtros += "?_limit=" + 5;

      filtros += "&_page=" + this.$refs.paginacao.pagina;

      if (this.sysadmin) {
        filtros += "&sysadmin=1";
      } else {
        this.ordem = "data_DESC";
      }

      this.filtroTipoUsuario = "";
      if (this.filtroTipoUsuarioAdmin) {
        this.filtroTipoUsuario += (this.filtroTipoUsuario ? "," : "") + "admin";
      }
      if (this.filtroTipoUsuarioAdminNuvem) {
        this.filtroTipoUsuario +=
          (this.filtroTipoUsuario ? "," : "") + "adminNuvem";
      }
      if (this.filtroTipoUsuarioUsuarioNuvem) {
        this.filtroTipoUsuario +=
          (this.filtroTipoUsuario ? "," : "") + "usuarioNuvem";
      }

      filtros +=
        "&tipoUsuario=" +
        (this.filtroTipoUsuario ? this.filtroTipoUsuario : "nenhum");

      if (this.filtroNuvem !== "") {
        filtros += "&nuvem=" + this.filtroNuvem;
      }

      if (this.filtroCliente !== "") {
        filtros += "&cliente=" + this.filtroCliente;
      }

      if (this.filtroTexto !== "") {
        filtros += "&_all=" + this.filtroTexto;
      }

      filtros += "&_ordem=" + this.ordem;

      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/sessao" + filtros);

      api.valid(response.data, this.$router);

      this.itens = response.data.itens;
      this.itensTotal = response.data.total;

      setTimeout(function () {
        window.KTApp.initBootstrapTooltips();
      }, 200);
    },
    editarItem(id) {
      if (this.dialog) {
        this.$emit("dialog-on");
      }

      this.$refs.formUsuario.carregarItem(id);
      this.$refs.formUsuario.abrir();
    },
    async novoItem() {
      if (this.dialog) {
        this.$emit("dialog-on");
      }

      await this.$refs.formUsuario.novo();

      if (this.filtroCliente !== "" && this.filtroNuvem !== "") {
        this.$refs.formUsuario.item.cliente = this.filtroCliente;
        this.$refs.formUsuario.item.nuvem = this.filtroNuvem;
      }

      this.$refs.formUsuario.abrir();
    },
    async desconectar(id) {
      try {
        if (confirm("Desconectar sessão do usuário ?")) {
          await api.delete(process.env.VUE_APP_URL_API + "/admin/sessao/" + id);
          this.carregarItens();
        }
      } catch (e) {
        console.log(e);
      }
    },
    // selecionaUsuarios() {
    //   if (this.dialog) {
    //     this.$emit("dialog-on");
    //   }
    //   //this.$refs.usuarioSelectDialog.carregarItem(id);
    //   this.$refs.usuarioSelectDialog.abrir(this.filtroCliente);
    // },
    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const obj = this;

      this.timerFiltroTexto = setTimeout(function () {
        //obj.$refs.paginacao.pagina = 1;
        obj.carregarItens();
      }, 300);
    },
  },
};
</script>

<style></style>
