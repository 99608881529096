<template>
  <div class="row justify-content-center mt-10">
    <div class="text-center col-8 col-sm-10 col-xs-12">
      <div class="row justify-content-center mb-7">
        <div class="text-center col-10">
          <h5>Fila de Transferências</h5>
          <h5 class="text-gray-700">
            <small>Fila de arquivos a serem transferidos</small>
          </h5>
        </div>
      </div>
      <UploadBusboy />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UploadBusboy from "../../../../_shared/components/ComponentUploadBusboy.vue";

export default {
  name: "FilaApp",
  components: { UploadBusboy },
  beforeCreate: function () {
    document.body.classList.remove("bodyOverflow");
  },
};
</script>
