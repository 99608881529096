import { defineStore } from "pinia";

//import { fetchWrapper, router } from '@/helpers';
//import { router } from "@/router/index.js";
import { api } from "@/plugins/api.js";

//const baseUrl = `${import.meta.env.VITE_API_URL}/users`;

export const useAuthStore = defineStore({
	id: "auth",
	state: () => ({
		sessao: JSON.parse(localStorage.getItem("ckdCch")),
		diretorioLocalUltimo: localStorage.getItem("diretorioLocalUltimo") !== null ? localStorage.getItem("diretorioLocalUltimo") : "",
		returnUrl: null,
		token: { esquerda: "", direita: "" },
		nuvem: { esquerda: null, direita: null }
	}),
	actions: {
		apiNuvem(instancia = "", url=true) {
			var sessao = this.sessao;
			var urlApi = process.env.VUE_APP_URL_API;

			//* Verifica se foi definido uma instancia
			if (instancia !== "" && this.token[instancia] && this.token[instancia] !== "") {

				//* Verifica se deve retornar com a url
				if(url == false)
				{
					return "/0/" + this.nuvem[instancia].alias;
				}

				//* Verifica se consta a regiao
				if(this.nuvem[instancia].regiao && process.env["VUE_APP_URL_API_" + this.nuvem[instancia].regiao.toUpperCase()])
				{
					urlApi = process.env["VUE_APP_URL_API_" + this.nuvem[instancia].regiao.toUpperCase()];
				}

				return urlApi + "/0/" + this.nuvem[instancia].alias;
			}

			if (sessao?.usuario?.nuvem) {

				//* Verifica se deve retornar com a url
				if(url == false)
				{
					return "/0/" + this.sessao.usuario.nuvem.alias;
				}

				//* Verifica se consta a regiao
				if(this.sessao.usuario.nuvem.regiao && process.env["VUE_APP_URL_API_" + this.sessao.usuario.nuvem.regiao.toUpperCase()])
				{
					urlApi = process.env["VUE_APP_URL_API_" + this.sessao.usuario.nuvem.regiao.toUpperCase()];
				}

				return urlApi + "/0/" + this.sessao.usuario.nuvem.alias;
			} else {
				return process.env.VUE_APP_URL_API;
			}
		},
		apiAdmin(instancia = "") {
			var sessao = this.sessao;
			var urlApi = process.env.VUE_APP_URL_API;

			//* Verifica se foi definido uma instancia
			if (instancia !== "" && this.token[instancia] && this.token[instancia] !== "") {

				//* Verifica se consta a regiao
				if(this.nuvem[instancia].regiao && process.env["VUE_APP_URL_API_" + this.nuvem[instancia].regiao.toUpperCase()])
				{
					urlApi = process.env["VUE_APP_URL_API_" + this.nuvem[instancia].regiao.toUpperCase()];
				}

				return urlApi;
			}

			if (sessao?.usuario?.nuvem) {

				//* Verifica se consta a regiao
				if(this.sessao.usuario.nuvem.regiao && process.env["VUE_APP_URL_API_" + this.sessao.usuario.nuvem.regiao.toUpperCase()])
				{
					urlApi = process.env["VUE_APP_URL_API_" + this.sessao.usuario.nuvem.regiao.toUpperCase()];
				}

				return urlApi;
			} else {
				return process.env.VUE_APP_URL_API;
			}
		},
		async entrar(nomeUsuario, senha, lembrar) {
			const apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/sessao/nuvem", { nomeUsuario, senha });

			if (apiRequest.data.erro) {
				return { erro: true, erros: apiRequest.data.erros };
			} else {
				// eslint-disable-next-line no-lonely-if
				if (apiRequest.data.sessao && apiRequest.data.sessao.key) {
					localStorage.setItem("ckd", apiRequest.data.sessao.key);
					localStorage.setItem("ckdCch", JSON.stringify(apiRequest.data.sessao));
					localStorage.setItem("loginLembrar", lembrar);

					window.location = "/app/home";

					return { erro: false };
				}

				return { erro: true };
			}
		},
		async adicionarNuvem(nomeUsuario, senha) {
			const apiRequest = await api.post(process.env.VUE_APP_URL_API + "/admin/sessao/nuvem/adicionar", { nomeUsuario, senha });

			if (apiRequest.data.erro == true) {
				return { erro: true, erros: apiRequest.data.erros };
			} else {
				return { erro: false };
			}
		},
		async alterarDiretorioLocalUltimo(diretorio) {
			//* Atualiza o ultimo diretorio
			this.diretorioLocalUltimo = diretorio;

			//* Atualiza o local storage
			localStorage.setItem("diretorioLocalUltimo", diretorio);
		},
		async atualizarSessao(instancia) {
			var apiRequest;

			//* Verifica a instancia
			if (this.token[instancia] == "") {
				apiRequest = await api.get(process.env.VUE_APP_URL_API + "/admin/sessao/refresh");
			} else {
				apiRequest = await api.get(process.env.VUE_APP_URL_API + "/admin/sessao/refresh", this.token[instancia] !== "" ? this.token[instancia] : null);
			}

			//* Verifica se ocorreu erro ao atualizar
			if (apiRequest.data.erro == true) {
				return { erro: true };
			} else {
				//* Verifica se esta conectado a outro servidor
				if (this.token[instancia] == "") {
					//* Atualiza os dados da sessao
					this.sessao = apiRequest.data.item;
				} else {
					//* Carrega os dados atual da nuvem
					var nuvemAtualizada = apiRequest.data.item.usuario.nuvem;

					//* Define os dados do usuario da nuvem
					nuvemAtualizada.usuarioNuvem = apiRequest.data.item.usuario;

					//* Atualiza as informacoes no store
					this.nuvem[instancia] = nuvemAtualizada;
				}

				return { erro: false };
			}
		},
		fotoAlterada() {
			this.sessao.usuario.foto = false;
			this.sessao.usuario.foto = true;
			localStorage.setItem("ckdCch", JSON.stringify(this.sessao));
		},
		sair() {
			this.sessao = null;
			localStorage.removeItem("ckd");
			localStorage.removeItem("ckdCch");
			//router.push("/app");
			//window.location = "/app/login";

			window.location = "/app/home";
		}
	}
});
