import { defineStore } from "pinia";
import { api } from "@/plugins/api.js";
import { funcoes } from "@/plugins/funcoes.js";

import { useAuthStore } from "@/stores/auth.js";

//const FileAPI = require("file-api");
const streamToBlob = require("stream-to-blob");
//const crypto = require("crypto");

//import { fileFromSync } from "fetch-blob/from.js";

var fs;
var path;

try {
	fs = require("fs");
} catch (errFS) {
	console.log(errFS);
}

try {
	path = require("path");
} catch (errPath) {
	console.log(errPath);
}

export const useStoreUpload = defineStore({
	id: "upload",
	state: () => ({
		arquivos: {},
		fila: {},
		arquivosAtivos: {},
		arquivosUltimos: {},
		timeoutProximoUpload: null,
		status: "processando"
	}),
	actions: {
		criarFila(tipo, apiNuvem = "", token = null, local = true, filaId = null, data = null) {
			if (filaId == null) {
				filaId = funcoes.guidGenerator();
			}

			//* Cria os dados da fila
			this.fila[filaId] = {
				id: filaId,
				local: local,
				status: "fila", // fila, concluido
				carregando: true,
				data: data == null ? new Date() : data,
				tipo: tipo, // upload, download
				diretorios: {},
				subdiretorios: {},
				concluidos: [],
				erros: {},
				token: token,
				apiNuvem: apiNuvem
			};

			//* Limpar as filas concluidas
			this.limparFilasConcluidas();

			return filaId;
		},
		async limparFilasConcluidas() {
			//* Carrega as keys das filas
			var filaKeys = Object.keys(this.fila);

			//* Define a quantidade a ser removida
			var filasRemover = filaKeys.length - 30;

			//* Verifica se tem alguma fila a ser removida
			if (filasRemover > 0) {
				//* Percorre para limpar as filas concluidas
				for (var indiceFila = filaKeys.length - 1; indiceFila >= 0; indiceFila--) {
					//* Verifica se a fila foi concluida
					if (this.fila[filaKeys[indiceFila]].status == "concluido") {
						//* Remove a fila
						await this.removerFilaConcluida(filaKeys[indiceFila]);

						//* Decrementa a quantidade de filas a remover
						filasRemover = filasRemover - 1;
					}

					//* Verifica se ja removeu a quantidade necessarias de filas
					if (filasRemover <= 0) {
						break;
					}
				}
			}
		},
		async removerFilaConcluida(filaId) {
			try {
				//* Verifica se a fila nao foi concluida
				if (this.fila[filaId].status !== "concluido") {
					return;
				}

				//* Carrega as keys dos diretorios
				var diretorioKeys = Object.keys(this.fila[filaId].diretorios);

				//* Percorre os diretorios da fila
				for (var indiceDiretorio = 0; indiceDiretorio < diretorioKeys.length - 1; indiceDiretorio++) {
					//* Percorre os arquivos do diretorio
					for (var indiceArquivo = 0; indiceArquivo < this.fila[filaId].diretorios[diretorioKeys[indiceDiretorio]].concluidos; indiceArquivo++) {
						//* Remove o arquivo do diretorio
						await this.removerArquivo(this.fila[filaId].diretorios[diretorioKeys[indiceDiretorio]].concluidos[indiceArquivo]);
					}
				}

				//* Apaga a fila caso tenha acabado todos os itens
				delete this.fila[filaId];

				//* Define para limpar os arquivos concluidos
				this.limparArquivosConcluidos();
			} catch (err) {
				console.log(err);
			}
		},
		criarFilaDiretorio(filaId, diretorioId, diretorioRaiz, diretorioPai, diretorioCaminho, tipo = "local", lazy = false, destino = { caminhoParent: "", diretorioParent: "" }) {
			
			//* Verifica se consta o diretorio na fila
			if (!this.fila[filaId].diretorios[diretorioId]) {
				//* Cria o diretorio na fila
				var diretorio = {
					id: diretorioId,
					diretorioRaiz: diretorioRaiz == null ? diretorioId : diretorioRaiz,
					diretorioPai: diretorioPai,
					tipo: tipo, // local, remoto
					lazy: lazy,
					lazyStatus: lazy ? "carregar" : "",
					status: "fila",
					diretorioCaminho: diretorioCaminho,
					diretorioNome: "",
					diretorioRemoto: "",
					//* Subdiretorios ao serem transferidos precisamos saber qual o destino
					destinoCaminhoParent: destino.caminhoParent,
					destinoDiretorioParent: destino.diretorioParent,
					destinoCaminho: "",
					destinoDiretorio: "",
					arquivos: [],
					concluidos: [],
					erros: [],
					subdiretorios: {},
					subdiretorio: "",
					indiceSubdiretorios: -1,
					isSubdiretorio: filaId == null
				};

				//* Desmembra o nome
				var arrNome = diretorioCaminho.replaceAll("\\", "/").split("/");

				//* Verifica se consta o nome
				if (arrNome.length > 0 && arrNome[arrNome.length - 1] !== "") {
					diretorio.diretorioNome = arrNome[arrNome.length - 1];
				} else {
					diretorio.diretorioNome = "/";
				}

				//* Caso tipo remoto,
				if (tipo == "remoto") {
					//* Caso lazy,  nao precisa criar o subdiretorio, vai colocar os arquivos na raiz do diretorio padrao
					if (lazy == false) {
						//* Define diretorio de destino no disco local
						diretorio.destinoCaminho = destino.caminhoParent;

						//* Ajusta o path do parent para consta no diretorio anterior
						diretorio.destinoCaminhoParent = path.resolve(destino.caminhoParent, "..");
					} else {
						//* Define diretorio de destino no disco local com o nome do subdiretorio a ser criado
						diretorio.destinoCaminho = path.resolve(destino.caminhoParent, diretorio.diretorioNome);
					}
				}

				//* Caso copia do disco local para o disco local
				if (this.fila[filaId].tipo == "copia" && diretorio.tipo == "local") {
					diretorio.destinoCaminho = path.resolve(diretorio.destinoCaminhoParent, diretorio.diretorioNome);
				}

				//* Insere o diretorio nos dados
				this.fila[filaId].diretorios[diretorioId] = diretorio;
				return diretorio;
			}

			return false;
		},
		async filasProximoArquivo() {
			//* Verifica se esta pausado
			if (this.status !== "processando") {
				return;
			}

			//* Carrega a key dos uploads
			var arquivosAtivosKeys = Object.keys(this.arquivosAtivos);

			//* Percorre os uploads ativos
			for (var indiceArquivosAtivos = 0; indiceArquivosAtivos < arquivosAtivosKeys.length; indiceArquivosAtivos++) {
				//* Define o arquivo como ativo
				var arquivoAtivo = this.arquivos[arquivosAtivosKeys[indiceArquivosAtivos]];

				//* Verifica se nao esta processando o arquivo
				if (arquivoAtivo.status !== "ativo" && arquivoAtivo.status !== "processando" && arquivoAtivo.status !== "transferindo") {
					//* Deleta ele dos itens ativos
					delete this.arquivosAtivos[arquivosAtivosKeys[indiceArquivosAtivos]];
				}
			}

			if (Object.keys(this.arquivosAtivos).length >= 3) {
				this.filasProximoArquivoTimer();
				return;
			}

			//* Carrega as keys de fila
			var arrFilaKey = Object.keys(this.fila);

			//* Define que nenhum arquivo esta sendo processado
			var arquivoEncontrado = false;

			//* Percorre todas as filas
			for (var indiceFila = 0; indiceFila < arrFilaKey.length; indiceFila++) {
				//* Instancia a fila
				var fila = this.fila[arrFilaKey[indiceFila]];

				//* Verifica se a fila ainda nao foi concluida
				if (fila.status !== "concluido" && fila.local == true && fila.carregando == false) {
					var arrArquivosConcluidos = [];

					//* Instancia os diretorios
					var arrDiretorioKey = Object.keys(fila.subdiretorios);

					//* Percorre os diretorios da fila
					for (var indiceDiretorio = 0; indiceDiretorio < arrDiretorioKey.length; indiceDiretorio++) {
						//* Instancia o diretorio
						var diretorio = fila.diretorios[arrDiretorioKey[indiceDiretorio]];

						//* Verifica se deve tratar um subdiretorio
						if (diretorio.subdiretorio !== "") {
							diretorio = this.fila[fila.id].diretorios[diretorio.subdiretorio];
						}

						//* Verifica se o diretorio ainda nao foi concluido
						if (diretorio.status !== "concluido") {
							//* Define que o diretorio esta sendo processado
							diretorio.status = "processando";

							//* Carrega os arquivos do diretorio caso necessario
							var carregarDiretorio = await this.carregarDiretorioArquivos(fila.id, diretorio);

							//* Verifica se vai precisar carregar o diretorio
							if (carregarDiretorio || diretorio.lazyStatus == "carregando") {
								return;
							}

							//* Percorre todos os arquivos ate encontrar um na fila
							for (var indiceArquivo = 0; indiceArquivo < diretorio.arquivos.length; indiceArquivo++) {
								//* Instancia o arquivo
								var arquivo = this.arquivos[diretorio.arquivos[indiceArquivo]];

								//* Verifica se esta na fila
								if (arquivo.status == "fila") {
									//* Define que um arquivo vai ser processado
									arquivoEncontrado = true;

									//* Verifica se nao chegou no limite de transferencias
									if (Object.keys(this.arquivosAtivos).length < 3) {
										//* Define o arquivo como processando
										arquivo.status = "processando";

										//* Insere ele na lista de trasnferencias ativas
										this.arquivosAtivos[arquivo.fileId] = true;

										//* Inicia a proxima transferencia
										await this.iniciarTransferenciaArquivo(arquivo.fileId);

										//* Carrega as keys dos ultimos arquivos
										var arquivosUltimosKeys = Object.keys(this.arquivosUltimos);

										//* Verifica se ja chegou no limite da lista
										if (arquivosUltimosKeys.length >= 5) {
											//* Localiza concluido mais antigo
											for (var indiceExcluirUltimo = 0; indiceExcluirUltimo < arquivosUltimosKeys.length; indiceExcluirUltimo++) {
												if (this.arquivos[arquivosUltimosKeys[indiceExcluirUltimo]].status == "concluido") {
													delete this.arquivosUltimos[arquivosUltimosKeys[indiceExcluirUltimo]];
													break;
												}
											}
										}

										//* Insere ele na lista de ultimas
										this.arquivosUltimos[arquivo.fileId] = true;

										//* Ja manda processar o proximo arquivo
										this.filasProximoArquivoTimer(1);
									} else {
										//* Ja manda processar o proximo arquivo
										this.filasProximoArquivoTimer();
										return;
									}

									break;
								} else if (arquivo.status == "concluido") {
									//* Limpa o arquivo concluido
									arrArquivosConcluidos.push(indiceArquivo);
								} else if (arquivo.status == "erro" && arquivo.erroTipo == "CONEXAO") {
									//* Inicia a proxima transferencia
									await this.iniciarTransferenciaArquivo(arquivo.fileId);
									return;
								}
							}

							this.filaConcluir(fila.id, diretorio.id);
						}

						/*

						//* Caso diretorio lazy, apaga os itens concluidos
						if (diretorio.lazy) {
							//* Verifica se deve remover algum item
							if (arrArquivosConcluidos.length >= 1) {
								//* Remove o item concluido mais antigo
								this.fila[arrFilaKey[indiceFila]].diretorios[diretorio.id].arquivos.splice(arrArquivosConcluidos[0], 1);
							}
						}*/

						//* Verifica se um arquivo ja foi encontrado
						if (arquivoEncontrado) {
							break;
						}
					}

					//* Verifica se um arquivo ja foi encontrado na fila
					if (arquivoEncontrado) {
						break;
					} else {
						this.filaConcluir(fila.id);
					}
				}
			}
		},
		filaConcluir(filaId, diretorioId = null) {
			//* Verifica se deve concluir o diretorio
			if (diretorioId !== null) {
				console.log("VAI CONCLUIR");

				//* Instancia a fila
				var fila = this.fila[filaId];

				//* Instancia o diretorio
				var diretorio = this.fila[filaId].diretorios[diretorioId];

				//* Verifica se a lista de arquivos esta vazia
				if (diretorio.arquivos.length == diretorio.concluidos.length) {
					//* Carrega as keys
					var arrSubdiretorios = Object.keys(diretorio.subdiretorios);

					//* Verifica se constam subdiretorios a serem verificados
					if (arrSubdiretorios.length > 0) {
						//* Verifica se encontrou o subdiretorio
						if (arrSubdiretorios.length > diretorio.indiceSubdiretorios + 1) {
							//* Passa para o proximo subdiretorio
							diretorio.indiceSubdiretorios = diretorio.indiceSubdiretorios + 1;

							/// Define o status para processando
							fila.diretorios[arrSubdiretorios[diretorio.indiceSubdiretorios]].status = "processando";

							//* Define para carregar o proximo subdiretorio
							fila.diretorios[diretorio.diretorioRaiz].subdiretorio = arrSubdiretorios[diretorio.indiceSubdiretorios];

							//* Manda carregar os arquivos do subdiretorio
							this.filasProximoArquivoTimer();
							return;
						} else {
							diretorio.status = "concluido";
						}
					} else {
						diretorio.status = "concluido";
					}

					if (diretorio.diretorioPai !== null) {
						//* Define para carregar o proximo subdiretorio
						this.fila[filaId].diretorios[diretorio.diretorioRaiz].subdiretorio = diretorio.diretorioPai;
					} else {
						//* Insere o diretorio na lista de concluidos
						this.fila[filaId].concluidos.push(diretorio.id);
					}
				}
			}

			//* Verifica se processou todos os diretorios da fila
			if (Object.keys(this.fila[filaId].subdiretorios).length == this.fila[filaId].concluidos.length) {
				//* Define a fila como concluida
				this.fila[filaId].status = "concluido";
			}

			//* Manda carregar os arquivos do subdiretorio
			this.filasProximoArquivoTimer();
		},
		filasProximoArquivoTimer(timeout = 200) {
			var thisCall = this;

			//* Verifica se funcao de controle ja estava programada
			if (this.timeoutProximoUpload !== null) {
				clearTimeout(this.timeoutProximoUpload);
			}

			this.timeoutProximoUpload = setTimeout(async function () {
				//* Passa para o proximo upload
				await thisCall.filasProximoArquivo();
			}, timeout);
		},
		async filaProximoArquivo(filaId, diretorioId) {
			//* Percorre todos os arquivos ate encontrar um na fila
			for (var indiceArquivo = 0; indiceArquivo < this.fila[filaId].diretorios[diretorioId].arquivos.length; indiceArquivo++) {
				//* Verifica se esta na fila
				if (this.arquivos[this.fila[filaId].diretorios[diretorioId].arquivos[indiceArquivo]].status == "fila" && this.fila[filaId].carregando == false) {
					//* Inicia o proximo upload do diretorio
					await this.iniciarTransferenciaArquivo(this.fila[filaId].diretorios[diretorioId].arquivos[indiceArquivo]);
					break;
				}

				//if (Object.keys(this.arquivosAtivos).length >= 3) {
				//	break;
				//}
			}
		},
		async filaArquivoConcluido(filaId, diretorioId, arquivoId) {
			var thisCall = this;

			//* Verifica se algum arquivo foi finalizado, ou se esta verificando o diretorio pai
			if (arquivoId !== null) {
				//* Define o arquivo como concluido
				thisCall.fila[filaId].diretorios[diretorioId].concluidos.push(arquivoId);

				//* Verifica se o arquivo constava como erro
				if (thisCall.fila[filaId].erros[arquivoId]) {
					//* Remove da lista de erros
					delete thisCall.fila[filaId].erros[arquivoId];
				}
			}

			//* Conclui o diretorio e a fila caso finalizados
			this.filaConcluir(filaId, diretorioId);
		},
		proximoArquivoSubdiretorio(filaId, diretorioId) {
			var thisCall = this;

			//* Carrega os subdiretorios do diretorio atual
			var arrSubdiretoriosKeys = Object.keys(thisCall.fila[filaId].diretorios[diretorioId].subdiretorios);

			//* Verifica se constam subdiretorios
			for (var indiceSubdiretorio = 0; indiceSubdiretorio < arrSubdiretoriosKeys.length; indiceSubdiretorio++) {
				//* Verifica se tem algum subdiretorio na fila
				if (thisCall.fila[filaId].diretorios[arrSubdiretoriosKeys[indiceSubdiretorio]].status == "fila") {
					//* Define o proximo diretorio a ser verificado
					thisCall.fila[filaId].diretorios[thisCall.fila[filaId].diretorios[arrSubdiretoriosKeys[indiceSubdiretorio]].diretorioRaiz].subdiretorio = arrSubdiretoriosKeys[indiceSubdiretorio];
					return true;
				}
			}

			return false;
		},
		async carregarDiretorioArquivos(filaId, diretorio) {
			var thisCall = this;

			//* Verifica se deve carregar os dados do diretorio
			if (diretorio.lazy && diretorio.lazyStatus == "carregar") {
				//* Define que esta carregando os arquivos do subdiretorio
				diretorio.lazyStatus = "carregando";

				//* Verifica se o diretorio é do tipo local
				if (diretorio.tipo == "local") {
					//* # Verifica se o diretorio existe
					let apiRequestDiretorio = null;

					//* Verifica se esta fazendo um upload
					if (this.fila[filaId].tipo == "upload") {
						//* Dados diretorio
						let bodyDiretorio = {
							nome: diretorio.diretorioNome,
							diretorioPai: diretorio.destinoDiretorioParent,
							diretorioPaiFullPath: diretorio.destinoCaminhoParent
						};

						//* Envia os dados do diretorio
						apiRequestDiretorio = await api.post(this.fila[filaId].apiNuvem + "/diretorio", bodyDiretorio, this.fila[filaId].token);

						//* Verifica se criou o diretorio ou se ele ja existia
						if (apiRequestDiretorio.data.erro == false || (apiRequestDiretorio.data.erro && apiRequestDiretorio.data._id)) {
							//* Armazena os dados e define como destino do diretorio criado
							diretorio.destinoDiretorio = apiRequestDiretorio.data._id;
							diretorio.destinoCaminho = diretorio.destinoCaminhoParent + (diretorio.destinoCaminhoParent == "/" ? "" : "/") + diretorio.diretorioNome;
						} else {
							diretorio.status = "erro";
							return false;
						}
					} else if (this.fila[filaId].tipo == "copia") {
						//* Cria o diretorio caso nao exista
						if (!fs.existsSync(diretorio.destinoCaminho)) {
							fs.mkdirSync(diretorio.destinoCaminho, { recursive: true });
						}
					}

					//* Carrega os arquivos do diretorio local
					fs.readdir(diretorio.diretorioCaminho, async (err, files) => {
						if (!err) {
							//* Percorre todos os arquivos
							for (var indiceArquivoLocal = 0; indiceArquivoLocal < files.length; indiceArquivoLocal++) {
								try {
									var item = files[indiceArquivoLocal];
									var absolutePath = path.resolve(diretorio.diretorioCaminho, item);
									var itemStats = fs.lstatSync(absolutePath);

									//* Verifica se esta tratando um arquivo
									if (itemStats.isFile()) {
										//* Verifica se esta fazendo um upload
										if (this.fila[filaId].tipo == "upload") {
											//* Coloca o arquivo na lista de upload
											await thisCall.uploadArquivo(filaId, item, absolutePath, diretorio.destinoDiretorio, diretorio.destinoCaminho, null, diretorio.isSubdiretorio ? diretorio : null);
										} else if (this.fila[filaId].tipo == "copia") {
											//* Coloca o arquivo na lista de copia
											await thisCall.copiaArquivoLocal(filaId, absolutePath, path.resolve(diretorio.destinoCaminho, path.basename(absolutePath)), diretorio.isSubdiretorio ? diretorio : null);
										}
									} else {
										//* Verifica se esta fazendo um upload
										if (this.fila[filaId].tipo == "upload") {
											//* Insere o subdiretorio para ser processado posteriormente
											await thisCall.criarFilaDiretorio(filaId, absolutePath, diretorio.diretorioRaiz == null ? diretorio.id : diretorio.diretorioRaiz, diretorio.id, absolutePath, "local", true, { caminhoParent: diretorio.destinoCaminho, diretorioParent: diretorio.destinoDiretorio });

											//* Insere na lista de subdiretorios
											diretorio.subdiretorios[absolutePath] = true;
										} else if (this.fila[filaId].tipo == "copia") {
											//* Insere o subdiretorio para ser processado posteriormente
											await thisCall.criarFilaDiretorio(filaId, absolutePath, diretorio.diretorioRaiz == null ? diretorio.id : diretorio.diretorioRaiz, diretorio.id, absolutePath, "local", true, { caminhoParent: diretorio.destinoCaminho, diretorioParent: "" });

											//* Insere na lista de subdiretorios
											diretorio.subdiretorios[absolutePath] = true;
										}
									}

									////* Agenda a execucao do proximo arquivo
									//thisCall.filasProximoArquivoTimer();
								} catch (e) {
									console.log(e);
								}
							}
						}

						//* Define que o diretorio foi carregado com sucesso
						diretorio.lazyStatus = "concluido";

						//* Agenda a execucao do proximo arquivo
						thisCall.filasProximoArquivoTimer();

						//* Agenda a execucao do proximo arquivo
						thisCall.filasProximoArquivoTimer();
					});
				} else if (diretorio.tipo == "remoto") {
					//* Verifica se o diretorio existe
					if (!fs.existsSync(diretorio.destinoCaminho)) {
						//* Cria o diretorio
						fs.mkdirSync(diretorio.destinoCaminho, { recursive: true });
					}

					//* Carrega a estrutura de itens do diretorio
					const apiRequestEstrutura = await api.get(this.fila[filaId].apiNuvem + "/diretorio/list?diretorio=" + diretorio.id, this.fila[filaId].token);
					api.valid(apiRequestEstrutura.data, this.$router);

					//* Percorre os itens encontrados
					for (var indiceEstruturaItem = 0; indiceEstruturaItem < apiRequestEstrutura.data.itens.length; indiceEstruturaItem++) {
						//* Instancia o item
						var estruturaItem = apiRequestEstrutura.data.itens[indiceEstruturaItem];

						//* Verifica se é um arquivo
						if (estruturaItem.isArquivo) {
							//* Insere o arquivo para download
							this.downloadArquivo(filaId, estruturaItem, diretorio.destinoCaminho, diretorio.id, null, diretorio.isSubdiretorio ? diretorio : null);
						} else {
							//* Cria o diretorio
							thisCall.criarFilaDiretorio(filaId, estruturaItem._id, diretorio.diretorioRaiz == null ? diretorio.id : diretorio.diretorioRaiz, diretorio.id, diretorio.diretorioCaminho + "/" + estruturaItem.nome, "remoto", true, { caminhoParent: diretorio.destinoCaminho, diretorioParent: "" });

							//* Insere na lista de subdiretorios
							diretorio.subdiretorios[estruturaItem._id] = true;

							//* Atualiza a execucao da fila
							thisCall.filasProximoArquivoTimer(1500);
						}
					}

					//* Define que o diretorio foi carregado com sucesso
					diretorio.lazyStatus = "concluido";

					//* Agenda a execucao do proximo arquivo
					thisCall.filasProximoArquivoTimer();
				}

				return true;
			} else if (diretorio.lazyStatus == "carregando") {
				return true;
			}

			return false;
		},
		async pausarUploadArquivo(fileId) {
			if (this.arquivos[fileId].status == "transferindo") {
				if (this.arquivos[fileId].tipo == "upload") {
					this.arquivos[fileId].request.abort();
					this.arquivos[fileId].file.fileStream.pause();
				} else if (this.arquivos[fileId].tipo == "copia") {
					this.arquivos[fileId].file.fileStream.pause();
					this.arquivos[fileId].file.fileStream.unpipe(this.arquivos[fileId].file.writeStream);
					this.arquivos[fileId].file.fileStream.destroy();
					//this.arquivos[fileId].file.writeStream.end();
				} else {
					if (this.arquivos[fileId].request.useElectronNet) {
						this.arquivos[fileId].request.body.abort();
					} else {
						this.arquivos[fileId].request.body.destroy();
					}
				}

				this.arquivos[fileId].status = "pausado";
			}
		},
		async pausarTodasFilas() {
			//* Carrega as keys
			var filaKeys = Object.keys(this.fila);

			//* Percorre as filas
			for (var indiceFila = 0; indiceFila < filaKeys.length; indiceFila++) {
				//* Pausa a fila
				var fila = this.fila[filaKeys[indiceFila]];
				fila.status = "pausado";
			}
		},
		async iniciarTransferenciaArquivo(fileId) {
			if (this.arquivos[fileId].status == "fila" || this.arquivos[fileId].status == "pausado" || this.arquivos[fileId].status == "erro" || this.arquivos[fileId].status == "processando") {
				if (this.arquivos[fileId].tipo == "upload") {
					await this.uploadArquivo(null, this.arquivos[fileId].fileName, this.arquivos[fileId].diretorioFonteCaminho, this.arquivos[fileId].diretorioDestino, this.arquivos[fileId].diretorioDestinoCaminho, fileId);
				} else if (this.arquivos[fileId].tipo == "copia") {
					this.copiaArquivoLocal(null, this.arquivos[fileId].fullPath, this.arquivos[fileId].arquivoDestinoCaminho, fileId);
				} else {
					this.downloadArquivo(null, null, null, null, fileId);
				}
			}
		},
		async uploadArquivo(filaId = null, fileName = null, diretorioFonteCaminho = null, diretorioDestino = null, diretorioDestinoCaminho = null, fileId = null, subdiretorio = null) {
			var formData = { fileName: fileName, diretorio: diretorioDestino };

			var thisCall = this;
			var diretorioId = null;

			if (fileId !== null) {
				///* Carrega a posicao onde foi inserida o item
				filaId = thisCall.arquivos[fileId].filaId;
				diretorioId = thisCall.arquivos[fileId].diretorioId;
			} else {
				//* Verifica se o arquivo foi colocado em um diretorio anteriormente
				if (subdiretorio == null) {
					//* Define o diretorio id
					diretorioId = path.dirname(diretorioFonteCaminho);
				} else {
					diretorioId = subdiretorio.id;
				}
			}

			//* Verifica se o arquivo existe e os dados dele
			if (fs.existsSync(diretorioFonteCaminho)) {
				//* Carrega os dados do arquivo
				const arquivoStats = fs.statSync(diretorioFonteCaminho);

				//* Request do arquivo
				var resultUploadRequest;

				try {
					var arquivo = null;

					//* Verifica se esta inserindo um novo item
					if (fileId == null) {
						//* Cria o novo arquivo
						arquivo = {
							status: "fila",
							tipo: "upload",
							fileId: funcoes.guidGenerator(),
							arquivoIdExterno: null,
							fileName: formData.fileName,
							diretorioDestino: diretorioDestino,
							diretorioDestinoCaminho: diretorioDestinoCaminho,
							diretorioFonteCaminho: diretorioFonteCaminho,
							diretorioFonte: path.dirname(diretorioFonteCaminho),
							startingByte: 0,
							progresso: 0,
							progressoBytes: 0,
							progressoVelocidade: 0,
							file: null,
							chunkSize: 0,
							request: null,
							onProgress: null,
							onComplete: null,
							onError: null,
							onTimeout: null,
							onAbort: null,
							filaId: filaId,
							diretorioId: diretorioId,
							erroTipo: "",
							erroData: null
						};

						//* Evento ao ocorrer um erro
						arquivo.onError = (erroTipo) => {
							//* Define o tipo de erro
							thisCall.arquivos[arquivo.fileId].erroTipo = erroTipo;

							//* Verifica se ocorreu erro anteriormente
							if (this.status !== "erro") {
								//* Define que ocorreu erro
								thisCall.arquivos[arquivo.fileId].status = "erro";
								thisCall.arquivos[arquivo.fileId].erroData = new Date();

								//* Insere na fila de erros
								thisCall.fila[filaId].erros[arquivo.fileId] = true;
							}
						};

						//* Insere o arquivo na lista
						thisCall.arquivos[arquivo.fileId] = arquivo;

						//* Verifica se esta inserindo em um subdiretorio
						if (subdiretorio == null) {
							//* Insere o arquivo
							thisCall.fila[filaId].diretorios[diretorioId].arquivos.push(arquivo.fileId);
						} else {
							subdiretorio.arquivos.push(arquivo.fileId);
						}

						//* Manda executar o proximo arquivo
						thisCall.filasProximoArquivoTimer(200);

						return;
					} else {
						arquivo = thisCall.arquivos[fileId];
					}

					//* Verifica se
					if (arquivo.arquivoIdExterno !== null) {
						formData.fileId = arquivo.arquivoIdExterno;
					}

					//* Define a data de alteracao do arquivo
					formData.dataAlteracao = arquivoStats.mtimeMs;
					formData.tamanho = arquivoStats.size;

					try {
						//* Efetua o request do arquivo a ser enviado
						resultUploadRequest = await api.post(thisCall.fila[filaId].apiNuvem + "/upload/request", formData, this.fila[filaId].token);
					} catch (errArquivoRequest) {
						resultUploadRequest = { data: { erro: true, erroTipo: "CONEXAO" } };
					}

					//* Verifica se o arquivo ja consta na nuvem
					if (resultUploadRequest.data.erro == true && resultUploadRequest.data.erroTipo == "VERSAO_ATUAL") {
						//* Define que o progresso do arquivo foi concluido
						thisCall.arquivos[arquivo.fileId].progresso = 100;
						thisCall.arquivos[arquivo.fileId].progressoVelocidade = 0;
						thisCall.arquivos[arquivo.fileId].file = { size: formData.tamanho };

						//* Define que foi concluido o item
						thisCall.arquivos[arquivo.fileId].progressoDataFim = new Date();
						thisCall.arquivos[arquivo.fileId].status = "concluido";

						//* Processa a conclusao da fila
						thisCall.filaArquivoConcluido(arquivo.filaId, arquivo.diretorioId, arquivo.fileId);
						return;
					} else if (resultUploadRequest.data.erro == true) {
						arquivo.onError(resultUploadRequest.data.erroTipo);

						//* Manda executar o proximo arquivo
						thisCall.filasProximoArquivoTimer(thisCall.arquivos[arquivo.fileId].erroTipo == "CONEXAO" ? 2000 : 200);
						return;
					}

					//* Define os dados do arquivo enviado
					arquivo._id = resultUploadRequest.data._id;
					arquivo.arquivoIdExterno = resultUploadRequest.data.fileId;
					arquivo.startingByte = resultUploadRequest.data.size;
					arquivo.criptografia = resultUploadRequest.data.criptografia;

					//* Verifica se deve iniciar a transferencia ao inserir arquivo
					if (fileId !== null) {
						//* Atualiza o progresso
						arquivo.onProgress = (e, arquivo) => {
							if (!arquivo.progressoDataInicio) {
								arquivo.progressoDataInicio = new Date();
								arquivo.status = "transferindo";
							}

							var loaded = e.loaded;

							thisCall.arquivos[arquivo.fileId].progressoVelocidade = thisCall.arquivos[arquivo.fileId].startingByte + loaded - thisCall.arquivos[arquivo.fileId].progressoBytes;

							//* Verifica se finalizou
							if (thisCall.arquivos[arquivo.fileId].startingByte + loaded >= arquivo.file.size) {
								//* Define o tamanho maximo
								thisCall.arquivos[arquivo.fileId].progressoBytes = arquivo.file.size;

								//* Verifica se definiu
								if (!thisCall.arquivos[arquivo.fileId].progressoDataFim) {
									//* Define que foi concluido o item
									thisCall.arquivos[arquivo.fileId].progressoDataFim = new Date();
									thisCall.arquivos[arquivo.fileId].status = "concluido";

									//* Processa a conclusao da fila
									thisCall.filaArquivoConcluido(arquivo.filaId, arquivo.diretorioId, arquivo.fileId);
								}
							} else {
								//* Verifica se finalizou o chunk, e calcula pelo tamanho do chunk ao inves da velocidade que pode vir maior
								if (loaded >= arquivo.chunkSize) {
									thisCall.arquivos[arquivo.fileId].progressoBytes = thisCall.arquivos[arquivo.fileId].startingByte + arquivo.chunkSize;
								} else {
									thisCall.arquivos[arquivo.fileId].progressoBytes = thisCall.arquivos[arquivo.fileId].startingByte + loaded;
								}
							}

							thisCall.arquivos[arquivo.fileId].progresso = ((thisCall.arquivos[arquivo.fileId].progressoBytes * 100) / thisCall.arquivos[arquivo.fileId].file.size).toFixed(2);

							//console.log("size: ", thisCall.arquivos[arquivo.fileId].file.size, "/", " startingByte: ", arquivo.startingByte, "/", " progresso atual: ", thisCall.arquivos[arquivo.fileId].progressoBytes, "/", " progresso velocidade: ", thisCall.arquivos[arquivo.fileId].progressoVelocidade, "/");
						};

						arquivo.onComplete = (e, arquivo) => {
							//* Define como inicia o proximo item
							thisCall.arquivos[arquivo.fileId].startingByte = thisCall.arquivos[arquivo.fileId].progressoBytes;

							//* Processa o proximo chuck
							arquivo.file.fileStream.resume();
						};

						//* Define o status
						thisCall.fila[arquivo.filaId].diretorios[diretorioId].status = "processando";
						arquivo.status = "processando";

						try {
							//* Carrega o primeiro chuck do arquivo
							arquivo.file = await thisCall.loadFileChuck(arquivo, diretorioFonteCaminho);
						} catch {
							//* Define o erro
							arquivo.onError("ARQUIVO_LOCAL");
						}

						//* Verifica se consta o tamanho do arquivo
						if (arquivo.file.size == 0 || (resultUploadRequest.data.erro == true && resultUploadRequest.data.existe == true)) {
							//* Define que o progresso do arquivo
							thisCall.arquivos[arquivo.fileId].progresso = 100;
							thisCall.arquivos[arquivo.fileId].progressoVelocidade = 0;
							thisCall.arquivos[arquivo.fileId].file = { size: 0 };

							//* Define que foi concluido o item
							thisCall.arquivos[arquivo.fileId].progressoDataFim = new Date();
							thisCall.arquivos[arquivo.fileId].status = "concluido";

							//* Processa a conclusao da fila
							thisCall.filaArquivoConcluido(arquivo.filaId, arquivo.diretorioId, arquivo.fileId);
						} else {
							//* Calcula o progresso atual
							arquivo.progresso = ((arquivo.startingByte * 100) / arquivo.file.size).toFixed(2);
						}

						//* Define quando ocorrer erro
						arquivo.file.fileStream.on("error", function () {
							//* Define o erro
							arquivo.onError("ARQUIVO_LOCAL");
						});

						//* Recebe cada chuck
						arquivo.file.fileStream.on("data", async (chunk) => {
							//* Pausa o envio de um proximo chuck
							arquivo.file.fileStream.pause();

							//* Converte para blob
							var blob = new Blob([chunk]);

							//* Armazena o tamanho do chunk
							arquivo.chunkSize = blob.size;

							//* Executa o upload
							await api.uploadFile(arquivo, thisCall.fila[filaId].apiNuvem, blob, thisCall.fila[filaId].token);
						});

						arquivo.file.fileStream.on("end", () => {
							return true;
						});
					}

					return { fileId: arquivo.fileId, filaId: filaId };
				} catch (err) {
					console.log(err);
				}
			} else {
				//* Verifica se o arquivo ja estava na lista
				if (fileId !== null) {
					thisCall.arquivos[fileId].onError("NAO_EXISTE");
				}
			}
		},
		async downloadArquivo(filaId = null, item, diretorioCaminho, diretorioFonte, fileId = null, subdiretorio = null) {
			var thisCall = this;

			var arquivo = null;

			var diretorioId = null;

			//* Verifica se é um novo envio
			if (fileId == null) {
				var fileName = "";
				var versaoId = "";
				var tamanho = 0;

				//* Desmembra o id
				var arrId = item._id.split("_");

				//* Verifica se consta uma versao especifica
				if (arrId.length == 1) {
					versaoId = "";
					fileName = item.nome + item.tipo;
					tamanho = item.tamanho;
				} else {
					versaoId = arrId[1];
					fileName = item.nome + "_v" + item.versao + item.tipo;
					tamanho = item.tamanho;
				}

				//* Verifica se o arquivo foi colocado em um diretorio anteriormente
				if (subdiretorio == null) {
					//* Define o diretorio id
					diretorioId = diretorioFonte;
				} else {
					diretorioId = subdiretorio.id;
				}

				arquivo = {
					id: arrId[0],
					versaoId: versaoId,
					tipo: "download",
					status: "fila",
					fileId: funcoes.guidGenerator(),
					fileName: fileName,
					diretorioDestinoCaminho: diretorioCaminho,
					diretorioFonte: diretorioFonte,
					dataAlteracao: item.dataAlteracao ? item.dataAlteracao : item.data,
					startingByte: 0,
					progresso: 0,
					progressoBytes: 0,
					progressoVelocidade: 0,
					file: { size: tamanho },
					request: null,
					onProgress: null,
					onComplete: null,
					onError: null,
					onTimeout: null,
					onAbort: null,
					filaId: filaId,
					diretorioId: diretorioId,
					erroTipo: "",
					erroData: null
				};

				//* Evento ao ocorrer um erro
				arquivo.onError = (erroTipo) => {
					//* Define o tipo de erro
					thisCall.arquivos[arquivo.fileId].erroTipo = erroTipo;

					//* Verifica se ocorreu erro anteriormente
					if (this.status !== "erro") {
						//* Define que ocorreu erro
						thisCall.arquivos[arquivo.fileId].status = "erro";
						thisCall.arquivos[arquivo.fileId].erroData = new Date();

						//* Insere na fila de erros
						thisCall.fila[filaId].erros[arquivo.fileId] = true;
					}
				};
			} else {
				//* Inståncia o arquivo que estava sendo enviado
				arquivo = thisCall.arquivos[fileId];

				//* Define para reiniciar de onde parou
				thisCall.arquivos[arquivo.fileId].startingByte = thisCall.arquivos[arquivo.fileId].progressoBytes;

				//* Remove a sinalizaçåo do envio
				delete thisCall.arquivos[arquivo.fileId].progressoDataInicio;
			}

			//* Verifica se deve iniciar a transferencia ao inserir arquivo
			if (fileId !== null) {
				//* Atualiza o progresso
				arquivo.onProgress = (chuck, arquivo) => {
					if (!arquivo.progressoDataInicio) {
						arquivo.progressoDataInicio = new Date();
						arquivo.status = "transferindo";
					}
					const loaded = thisCall.arquivos[arquivo.fileId].progressoBytes + chuck.length;
					thisCall.arquivos[arquivo.fileId].progressoVelocidade = loaded - thisCall.arquivos[arquivo.fileId].progressoBytes;
					thisCall.arquivos[arquivo.fileId].progressoBytes = loaded;

					var progressoAtual = (loaded * 100) / arquivo.file.size;

					//* So altera o progresso caso maior que 10
					//if (progressoAtual - parseFloat(thisCall.arquivos[arquivo.fileId].progresso) > 10 || progressoAtual >= 100) {
					thisCall.arquivos[arquivo.fileId].progresso = progressoAtual.toFixed(2);
					//}
				};

				arquivo.onComplete = (arquivo) => {
					if (!arquivo.progressoDataFim) {
						arquivo.progresso = 100;
						arquivo.progressoDataFim = new Date();
						arquivo.status = "concluido";

						setTimeout(async function () {
							try {
								//* Corrige a setagem
								var dataAlteracao = new Date(arquivo.dataAlteracao);

								fs.utimesSync(path.resolve(arquivo.diretorioDestinoCaminho, arquivo.fileName), dataAlteracao, dataAlteracao);
							} catch (errUtimes) {
								console.log(errUtimes);
							}
						}, 2000);

						//* Processa a conclusao da fila
						thisCall.filaArquivoConcluido(arquivo.filaId, arquivo.diretorioId, arquivo.fileId);
					}
				};
			}

			//* Insere o arquivo na lista
			thisCall.arquivos[arquivo.fileId] = arquivo;

			//* Verifica se deve inserir o arquivo na fila
			if (fileId == null) {
				//* Insere o arquivo
				if (subdiretorio == null) {
					thisCall.fila[filaId].diretorios[diretorioId].arquivos.push(arquivo.fileId);
				} else {
					subdiretorio.arquivos.push(arquivo.fileId);
				}
			}

			//* Verifica se deve efetuar o download agora
			if (fileId !== null) {
				//* Processa o download do arquivo
				await api.downloadFile(arquivo, thisCall.fila[arquivo.filaId].apiNuvem, thisCall.fila[arquivo.filaId].token);
			} else {
				//* Manda executar o proximo arquivo
				thisCall.filasProximoArquivoTimer(1500);
			}
		},
		async copiaArquivoLocal(filaId, arquivoFonte, arquivoDestino, fileId = null, subdiretorio = null) {
			var thisCall = this;

			var arquivo = null;

			//* Carrega as estatisticas do arquivo
			const fileStats = fs.statSync(arquivoFonte);

			//* Armazena o tamanho
			var tamanho = fileStats.size;

			var diretorioId = null;

			//* Verifica se é um novo envio
			if (fileId == null) {
				//* Verifica se o arquivo foi colocado em um diretorio anteriormente
				if (subdiretorio == null) {
					//* Define o diretorio id
					diretorioId = path.dirname(arquivoFonte);
				} else {
					diretorioId = subdiretorio.id;
				}

				arquivo = {
					tipo: "copia",
					status: "fila",
					fileId: funcoes.guidGenerator(),
					fileName: path.basename(arquivoFonte),
					fullPath: arquivoFonte,
					diretorioDestinoCaminho: path.dirname(arquivoDestino),
					arquivoDestinoCaminho: arquivoDestino,
					startingByte: 0,
					progresso: 0,
					progressoBytes: 0,
					progressoVelocidade: 0,
					file: { size: tamanho },
					request: null,
					onProgress: null,
					onComplete: null,
					onError: null,
					onTimeout: null,
					onAbort: null,
					filaId: filaId,
					diretorioId: diretorioId,
					erroTipo: "",
					erroData: null
				};

				//* Insere o arquivo na lista
				thisCall.arquivos[arquivo.fileId] = arquivo;
			} else {
				//* Inståncia o arquivo que estava sendo enviado
				arquivo = thisCall.arquivos[fileId];

				//* Verifica se estava pausado
				if (arquivo.status == "pausado") {
					//* Verifica se o arquivo existe
					if (fs.existsSync(arquivoDestino)) {
						//* Carrega o tamanho atual do arquivo
						const fileStatsDestino = fs.statSync(arquivoDestino);

						//* Define para reiniciar de onde parou
						thisCall.arquivos[arquivo.fileId].startingByte = fileStatsDestino.size;
					}
				}

				//* Remove a sinalizaçåo do envio
				delete thisCall.arquivos[arquivo.fileId].progressoDataInicio;
			}

			//* Verifica se deve iniciar a transferencia ao inserir arquivo
			if (fileId !== null) {
				//* Define o tipo de abertura do arquivo
				var writeFlogs = "w";

				//* Verifica se abre o arquivo para inserir
				if (arquivo.status == "pausado") {
					writeFlogs = "a";
				}

				//* Carrega o stream
				const readStreamArquivo = fs.createReadStream(arquivo.fullPath, { highWaterMark: 50 * 1024 * 1024, start: thisCall.arquivos[arquivo.fileId].startingByte });

				//* Controla o progresso
				readStreamArquivo.on("data", function (chuck) {
					if (!arquivo.progressoDataInicio) {
						arquivo.progressoDataInicio = new Date();
						arquivo.status = "transferindo";
					}
					const loaded = thisCall.arquivos[arquivo.fileId].progressoBytes + chuck.length;
					thisCall.arquivos[arquivo.fileId].progressoVelocidade = loaded - thisCall.arquivos[arquivo.fileId].progressoBytes;
					thisCall.arquivos[arquivo.fileId].progressoBytes = loaded;
					thisCall.arquivos[arquivo.fileId].progresso = ((loaded * 100) / arquivo.file.size).toFixed(2);
				});

				//* Verifica se o arquivo existe
				if (fs.existsSync(arquivoDestino) == true) {
					//* Define se encontrou um arquivo de destino que nao exista
					var validouArquivoDestinoNovo = false;
					var indiceArquivoDestinoNovo = 1;

					//* Carrega os dados do arquivo de destino
					var pathArquivoDestino = path.parse(arquivoDestino);

					//* Enquanto nao encontrou, define um novo nome
					while (validouArquivoDestinoNovo == false) {
						//* Define o novo nome
						var novoArquivoDestino = path.resolve(thisCall.arquivos[arquivo.fileId].diretorioDestinoCaminho, pathArquivoDestino.name + " (" + indiceArquivoDestinoNovo + ")" + pathArquivoDestino.ext);

						//* Verifica se existe novo destino existe
						if (fs.existsSync(novoArquivoDestino) == false) {
							//* Define que o destino pode ser utilizado
							validouArquivoDestinoNovo = true;

							//* Salva o novo destino
							arquivoDestino = novoArquivoDestino;

							//* Atualiza nos arquivos o novo destino
							thisCall.arquivos[arquivo.fileId].diretorioDestinoCaminho = path.dirname(arquivoDestino);
							thisCall.arquivos[arquivo.fileId].arquivoDestinoCaminho = arquivoDestino;

							break;
						}

						//* Incrementa o nome do destino para proxima tentativa
						indiceArquivoDestinoNovo = indiceArquivoDestinoNovo + 1;
					}
				}

				//* Cria o arquivo de saida
				const writeStream = fs.createWriteStream(arquivoDestino, { flags: writeFlogs });

				//* Inicia a transferencia
				readStreamArquivo.pipe(writeStream).on("finish", function () {
					//* Verifica se arquivo nao tem condeuo
					if (arquivo.file.size <= 0) {
						thisCall.arquivos[arquivo.fileId].progresso = 100;
					}

					thisCall.arquivos[arquivo.fileId].progressoDataFim = new Date();
					thisCall.arquivos[arquivo.fileId].status = "concluido";

					//* Processa a conclusao da fila
					thisCall.filaArquivoConcluido(arquivo.filaId, arquivo.diretorioId, arquivo.fileId);
				});

				//* Define o stream
				thisCall.arquivos[arquivo.fileId].file.fileStream = readStreamArquivo;
				thisCall.arquivos[arquivo.fileId].file.writeStream = writeStream;
			}

			//* Verifica se deve criar a fila
			if (fileId == null) {
				//* Insere o arquivo na fila de transferencia
				thisCall.fila[filaId].diretorios[path.dirname(arquivoFonte)].arquivos.push(arquivo.fileId);

				//* Manda executar o proximo arquivo
				thisCall.filasProximoArquivoTimer(1500);
			}
		},
		async removerArquivo(fileId) {
			try {
				//* Instancia  o arquivo
				var arquivo = this.arquivos[fileId];

				//* Verifica se existe o arquivo
				if (this.fila[arquivo.filaId] && this.fila[arquivo.filaId].diretorios[arquivo.diretorioId]) {
					//* Remove o arquivo
					this.fila[arquivo.filaId].diretorios[arquivo.diretorioId].arquivos.splice(this.fila[arquivo.filaId].diretorios[arquivo.diretorioId].arquivos.indexOf(arquivo.fileId), 1);
				}

				//* Verifica se o diretorio contem arquivos
				if (this.fila[arquivo.filaId].diretorios[arquivo.diretorioId].arquivos.length == 0) {
					//* Remove o diretorio
					delete this.fila[arquivo.filaId].diretorios[arquivo.diretorioId];
				}

				//* Verifica se o diretorio contem arquivos
				if (Object.keys(this.fila[arquivo.filaId].diretorios).length == 0) {
					//* Remove o diretorio
					delete this.fila[arquivo.filaId];
				}

				//* Remove o arquivo do cache central
				delete this.arquivos[fileId];

				//* Limpa os arquivos concluidos
				this.limparArquivosConcluidos();
			} catch (err) {
				console.log(err);
			}
		},
		async removerDiretorio(filaId, diretorioId) {
			try {
				//* Verifica se existe o diretorio
				if (this.fila[filaId].diretorios[diretorioId]) {
					//* Percorre os arquivos do diretorio
					for (var indiceArquivo = 0; indiceArquivo < this.fila[filaId].diretorios[diretorioId].arquivos; indiceArquivo++) {
						//* Remove o arquivo do diretorio
						this.removerArquivo(this.fila[filaId].diretorios[diretorioId].arquivos[indiceArquivo]);
					}

					//* Verifica se consta o diretorio
					if (this.fila[filaId].diretorios[diretorioId]) {
						//* Remove o diretorio
						delete this.fila[filaId].diretorios[diretorioId];
					}
				}

				//* Verifica se deve remover a fila
				if (this.fila[filaId] && Object.keys(this.fila[filaId].diretorios).length == 0) {
					//* Apaga a fila caso tenha acabado todos os itens
					delete this.fila[filaId];
				}

				//* Limpa os arquivos concluidos
				this.limparArquivosConcluidos();
			} catch (err) {
				console.log(err);
			}
		},
		async loadFile(arquivo, path) {
			//path

			/*//* Abre a stream
			const fileStream = fs.createReadStream(path);

			//* Carrega o tamanho
			const fileStats = fs.statSync(path);
			fileStream.size = fileStats.size;

			

			return fileStream;*/

			/*

			const Stream = require("stream");

			const createReadableStream = (buffer) => {
				const readableInstanceStream = new Stream.Readable({
					read() {
						for (const bytes of buffer) {
							this.push(bytes);
						}
						this.push(null);
					}
				});
				return readableInstanceStream;
			};

			const fileStream = createReadableStream(path);

			//* Carrega o tamanho
			const fileStats = fs.statSync(path);
			fileStream.size = fileStats.size;

			return fileStream;
			*/

			/*const filePost = new FileAPI.File(path);
			const fileStream = fs.createReadStream(path);
			const blob = await streamToBlob(fileStream);

			filePost.blob = blob;
			filePost.size = blob.size;
			filePost.slice = function (start) {
				return this.blob.slice(start);
			};

			return filePost;*/

			const filePost = {};

			const fileStream = fs.createReadStream(path, { highWaterMark: 15 * 1024 });

			fileStream.on("error", function (error) {
				console.log(`error: ${error.message}`);
			});

			fileStream.on("data", () => {
				//console.log("chuck ", arquivo.fileName + " (" + arquivo.fileId + ")");
			});

			fileStream.on("end", () => {
				return true;
			});

			const blob = await streamToBlob(fileStream);

			filePost.blob = blob;
			filePost.size = blob.size;
			filePost.slice = function (start) {
				return this.blob.slice(start);
			};

			return filePost;

			/*
			var CHUNK_SIZE = 10 * 1024 * 1024, // 10MB
				buffer = Buffer.alloc(CHUNK_SIZE);

			fs.open(path, "r", function (err, fd) {
				if (err) throw err;
				function readNextChunk() {
					fs.read(fd, buffer, 0, CHUNK_SIZE, null, function (err, nread) {
						if (err) throw err;

						if (nread === 0) {
							// done reading file, do any necessary finalization steps

							fs.close(fd, function (err) {
								if (err) throw err;
							});
							return data;
						}

						var data;
						if (nread < CHUNK_SIZE) data = buffer.slice(0, nread);
						else data = buffer;

						console.log(data);

						// do something with `data`, then call `readNextChunk();`
					});
				}
				readNextChunk();
			});
			*/

			//console.log("load");
		},
		loadFileChuck(arquivo, path) {
			const filePost = {};

			//* Carrega o tamanho total
			const fileStats = fs.statSync(path);
			filePost.size = fileStats.size;

			//* Verifica se o arquivo tem conteudo
			if (filePost.size > 0) {
				//* Verifica se deve criptografar o conteudo localmente
				/*
				if (1 == 2) {
					//* Define a chave iv
					var ivKey = arquivo._id.split("").reverse().join("").toString("hex").slice(0, 16);

					//* Cria a criptografia
					var cryptoEncriptar = crypto.createCipheriv("aes-256-cbc", arquivo.criptografia.chave, ivKey);

					//* Carrega o stream do arquivo
					filePost.fileStream = fs.createReadStream(path, { highWaterMark: 5 * 1024 * 1024, start: arquivo.startingByte }).pipe(cryptoEncriptar);
				}*/

				//* Carrega o stream do arquivo
				filePost.fileStream = fs.createReadStream(path, { highWaterMark: 15 * 1024 * 1024, start: arquivo.startingByte });
			}

			return filePost;
		},
		dataURIToBlob(dataURI) {
			const splitDataURI = dataURI.split(",");
			const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
			const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

			const ia = new Uint8Array(byteString.length);
			for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

			return new Blob([ia], { type: mimeString });
		},
		async carregarTransferencias() {
			var storeAuth = useAuthStore();

			//* Carrega os dados das transferencias
			const response = await api.get(storeAuth.apiNuvem() + "/transferencia/fila");
			api.valid(response.data, this.$router);

			//* Percorre todos os itens
			for (var indiceTransferencia = response.data.itens.length - 1; indiceTransferencia >= 0 ; indiceTransferencia--) {
				//* Instancia o item
				var itemTransferencia = response.data.itens[indiceTransferencia];

				//* Cria a fila
				this.criarFila(itemTransferencia.tipo, storeAuth.apiNuvem(), null, false, itemTransferencia._id, new Date(itemTransferencia.data));

				//* Verifica se tem arquivos a serem inseridos
				if (itemTransferencia.arquivos.length > 0) {
					var diretorioPrincipalConcluido = true;

					//* Percorre os arquivos
					for (var indiceArquivo = 0; indiceArquivo < itemTransferencia.arquivos.length; indiceArquivo++) {
						//* Instancia o arquivo
						var itemArquivoInfo = itemTransferencia.arquivosInfo[indiceArquivo];
						var itemArquivo = itemTransferencia.arquivos[indiceArquivo];

						//* Verifica se esta inserindo o primeiro item
						if (indiceArquivo == 0) {

							//* Insere o diretorio na lista
							this.criarFilaDiretorio(itemTransferencia._id, itemTransferencia.diretorioDe == null ? "" : itemTransferencia.diretorioDe._id, itemTransferencia.diretorioDe == null ? "" : itemTransferencia.diretorioDe._id, null, itemTransferencia.diretorioDe == null ? "/" : itemTransferencia.diretorioDe.fullPath, "remoto", false, {
								caminhoParent: itemTransferencia.diretorioPara == null ? "" : itemTransferencia.diretorioPara.fullPath,
								diretorioParent: itemTransferencia.diretorioPara == null ? "" : itemTransferencia.diretorioPara._id
							});

							//* Insere nos subdiretorios o diretorio
							this.fila[itemTransferencia._id].subdiretorios[itemTransferencia.diretorioDe == null ? "" : itemTransferencia.diretorioDe._id] = true;
						}

						var fileName = "";
						var versaoId = "";
						var tamanho = 0;

						//* Desmembra o id
						var arrId = itemArquivoInfo._id.split("_");

						//* Verifica se consta uma versao especifica
						if (arrId.length == 1) {
							versaoId = "";
							fileName = itemArquivoInfo.nome + itemArquivoInfo.tipo;
							tamanho = itemArquivoInfo.tamanho;
						} else {
							versaoId = arrId[1];
							fileName = itemArquivoInfo.nome + "_v" + itemArquivoInfo.versao + itemArquivoInfo.tipo;
							tamanho = itemArquivoInfo.tamanho;
						}

						var arquivo = {
							id: arrId[0],
							versaoId: versaoId,
							tipo: "download",
							status: itemArquivo.status,
							fileId: itemTransferencia._id + "_" + itemArquivoInfo._id,
							fileName: fileName,
							diretorioDestinoCaminho: itemTransferencia.diretorioPara == null ? "" : itemTransferencia.diretorioPara.fullPath,
							diretorioFonte: itemTransferencia.diretorioDe == null ? "" : itemTransferencia.diretorioDe._id,
							dataAlteracao: itemArquivoInfo.dataAlteracao ? itemArquivoInfo.dataAlteracao : itemArquivoInfo.data,
							startingByte: 0,
							progresso: itemArquivo.status == "concluido" ? 0 : 100,
							progressoBytes: 0,
							progressoVelocidade: 0,
							file: { size: tamanho },
							request: null,
							onProgress: null,
							onComplete: null,
							onError: null,
							onTimeout: null,
							onAbort: null,
							filaId: itemTransferencia._id,
							diretorioId: itemTransferencia.diretorioDe == null ? "" : itemTransferencia.diretorioDe._id,
							erroTipo: "",
							erroData: null
						};

						//* Insere o arquivo na lista
						this.arquivos[arquivo.fileId] = arquivo;
						this.fila[arquivo.filaId].diretorios[arquivo.diretorioId].arquivos.push(arquivo.fileId);

						//* Verifica se o arquivo foi concluido
						if (itemArquivo.status == "concluido") {
							//* Define o arquivo como concluido
							this.arquivos[arquivo.fileId].progresso = 100;
							this.fila[arquivo.filaId].diretorios[arquivo.diretorioId].concluidos.push(arquivo.fileId);
						} else {
							diretorioPrincipalConcluido = false;
						}
					}

					//* Define que o diretorio principal foi concluido
					if (diretorioPrincipalConcluido) {
						this.fila[itemTransferencia._id].diretorios[itemTransferencia.diretorioDe == null ? "" : itemTransferencia.diretorioDe._id].status = "concluido";
					}
				}

				//* Percorre os diretorios
				for (var indiceDiretorio = 0; indiceDiretorio < itemTransferencia.diretorios.length; indiceDiretorio++) {
					var itemDiretorio = itemTransferencia.diretorios[indiceDiretorio];
					var itemDiretorioInfo = itemTransferencia.diretoriosInfo[indiceDiretorio];

					//* Insere o diretorio na lista
					this.criarFilaDiretorio(itemTransferencia._id, itemDiretorio._id, itemDiretorio._id, null, itemDiretorioInfo.fullPath, "remoto", true, { caminhoParent: itemDiretorio.diretorioPara == null ? "" : itemDiretorio.diretorioPara.fullPath, diretorioParent: itemDiretorio.diretorioPara == null ? "" : itemDiretorio.diretorioPara._id });

					//* Insere nos subdiretorios o diretorio
					this.fila[itemTransferencia._id].subdiretorios[itemDiretorio._id] = true;

					//* Define o status
					this.fila[itemTransferencia._id].diretorios[itemDiretorio._id].status = itemDiretorio.status;
				}

				//* Define que finalizou de carregar esta fila
				this.fila[itemTransferencia._id].carregando = false;

			}

			//* Atualiza o progresso
			this.carregarTransferenciasProgresso();
		},
		async carregarTransferenciasProgresso() {
			var thisCall = this;
			var itensPendentes = false;

			//* Define os filtros
			var filtros = "";

			//* Carrega o store de autenticacao
			var storeAuth = useAuthStore();

			//* Carrega as keys das filas
			var filaKeys = Object.keys(this.fila);

			//* Percorre as filas
			for (var indiceFila = 0; indiceFila < filaKeys.length; indiceFila++) {
				//* Verifica se a fila remota esta pendente
				if (this.fila[filaKeys[indiceFila]].local == false && this.fila[filaKeys[indiceFila]].status !== "concluido") {
					filtros = (filtros == "" ? "?id=" : filtros + ",") + filaKeys[indiceFila];
				}
			}

			//* Carrega os dados das transferencias
			const response = await api.get(storeAuth.apiNuvem() + "/transferencia/fila" + filtros);
			api.valid(response.data, this.$router);

			//* Percorre todos os itens
			for (var indiceTransferencia = 0; indiceTransferencia < response.data.itens.length; indiceTransferencia++) {
				//* Instancia o item
				var itemTransferencia = response.data.itens[indiceTransferencia];

				//* Verifica se tem arquivos a serem inseridos
				if (itemTransferencia.arquivos.length > 0) {
					//* Verifica se o diretorio ja tinha sido eoncluido anteriormente
					if (this.fila[itemTransferencia._id].diretorios[itemTransferencia.diretorioDe == null ? "" : itemTransferencia.diretorioDe._id].status !== "concluido") {
						var diretorioPrincipalConcluido = true;

						//* Percorre os arquivos
						for (var indiceArquivo = 0; indiceArquivo < itemTransferencia.arquivos.length; indiceArquivo++) {
							//* Instancia o arquivo
							var itemArquivo = itemTransferencia.arquivos[indiceArquivo];
							var itemArquivoFila = this.arquivos[itemArquivo._id];

							//* Atualiza o status do arquivo
							this.arquivos[itemArquivo._id].status = itemArquivo.status;

							//* Verifica se o arquivo foi concluido
							if (itemArquivo.status == "concluido") {
								//* Atualiza o progresso
								this.arquivos[itemArquivo._id].progresso = 100;

								//* Define o arquivo como concluido
								this.fila[itemArquivoFila.filaId].diretorios[itemArquivoFila.diretorioId].concluidos.push(itemArquivoFila.fileId);
							} else {
								diretorioPrincipalConcluido = false;
							}
						}

						//* Define que o diretorio principal foi concluido
						if (diretorioPrincipalConcluido) {
							this.fila[itemTransferencia._id].diretorios[itemTransferencia.diretorioDe == null ? "" : itemTransferencia.diretorioDe._id].status = "concluido";
						}
					}
				}

				//* Percorre os diretorios
				for (var indiceDiretorio = 0; indiceDiretorio < itemTransferencia.diretorios.length; indiceDiretorio++) {
					//* Instancia o diretorio
					var itemDiretorio = itemTransferencia.diretorios[indiceDiretorio];

					//* Insere nos subdiretorios o diretorio
					this.fila[itemTransferencia._id].diretorios[itemDiretorio._id].status = itemDiretorio.status;
				}

				//* Atualiza o status
				this.fila[itemTransferencia._id].status = itemTransferencia.status;

				//* Verifica se constam itens pendentes
				if (itemTransferencia.status !== "concluido") {
					itensPendentes = true;
				}
			}

			var timeout = 10000;

			//* Verifica se deve continuar verificando status
			if (itensPendentes) {
				setTimeout(function () {
					thisCall.carregarTransferenciasProgresso();				
				}, timeout);
			}

		},
		async limparArquivosConcluidos() {
			//* Carrega as filas
			var arrFilaKeys = Object.keys(this.fila);

			//* Verifica se nao consta mais nenhuma fila
			if (arrFilaKeys.length > 0) {
				//* Carrega os arquivos
				var arrArquivoKeys = Object.keys(this.arquivosUltimos);

				//* Percorre os indices
				for (var indiceArquivo = 0; indiceArquivo < arrArquivoKeys.length; indiceArquivo++) {
					//* Instancia o arquivo
					var arquivo = this.arquivos[arrArquivoKeys[indiceArquivo]];

					console.log(arquivo);

					//* Por padrao, define que o arquivo nao sera removido
					var removeArquivo = false;

					try {
						//* Verifica se consta a fila do arquivo
						if (!this.fila[arquivo.filaId]) {
							removeArquivo = true;
						} else {
							//* Instancia o diretorio do arquivo
							var diretorio = this.fila[arquivo.filaId].diretorios[arquivo.diretorioId];

							//* Verifica se se o diretorio foi removido
							if (!this.fila[arquivo.filaId].diretorios[diretorio.diretorioRaiz]) {
								removeArquivo = true;
							}
						}
					} catch (err) {
						removeArquivo = true;
					}

					//* Verifica se deve remover o arquivo
					if (removeArquivo) {
						delete this.arquivosUltimos[arrArquivoKeys[indiceArquivo]];
					}
				}
			} else {
				//* Limpa os ultimos arquivos
				this.arquivosUltimos = [];
			}
		}
	}
});
