<template>
  <div>
    <Dialog
      :id="'dialogNuvemConectar'"
      ref="dialogNuvemConectar"
      :hide-header="false"
      :hideFooter="true"
      :modal="true"
      :tamanho="'md'"
      :classModalBody="'mx-5 mx-xl-1 my-1'"
      :titulo="'Conectar'"
      @dialog-off="fechar()"
    >
      <div>
        <div>
          <h3 class="text-gray-800 text-center mb-8" v-if="false">
            <small>Nuvens associadas a sua conta</small>
          </h3>

          <!--begin::Item-->
          <div
            class="d-flex align-items-center mb-8"
            v-for="item in itens"
            :key="item._id"
            :item="item"
          >
            <span class="svg-icon svg-icon-2" v-if="item.token == 'local'">
              <i
                class="bi bi-pc-display-horizontal fs-2x gx-5 p-2 text-primary"
              ></i>
            </span>
            <span class="svg-icon svg-icon-2" v-if="item.token !== 'local'">
              <i class="bi bi-cloud fs-2hx gx-5 p-2 text-primary"></i>
            </span>
            <!--begin::Description-->
            <div class="flex-grow-1 ms-3">
              <span class="text-gray-800 text-hover-primary fw-bold fs-6">{{
                item.nuvem.nome
              }}</span>
              <span
                v-if="item.usuarioNuvem !== null"
                class="text-muted fw-semibold d-block"
                >{{ item.usuarioNuvem.nomeUsuario }}@{{
                  item.nuvem.alias
                }}</span
              >
            </div>
            <!--end::Description-->
            <span
              class="badge badge-warning fs-8 fw-bold"
              style="cursor: pointer"
              v-if="item.token !== 'local' && !item.sessao && item.usuario"
              @click="loginNuvem()"
              >Reconectar</span
            >
            <span
              class="badge badge-success fs-8 fw-bold"
              style="cursor: pointer"
              @click="conectar(item)"
              v-if="item.sessao || !item.usuario"
              >Conectar</span
            >
          </div>
          <!--end:Item-->

          <div class="separator separator-dashed my-4"></div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-light-info"
              @click="loginNuvem"
            >
              Conectar outra nuvem
            </button>
          </div>
        </div>
      </div>
    </Dialog>
    <LoginDialog ref="loginDialog" @dialog-off="abrir()" />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import Dialog from "../components/ComponentDialog.vue";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreArvore } from "../stores/storeArvore.js";
import { useStoreUpload } from "../stores/storeUpload.js";

import LoginDialog from "./LoginDialog.vue";

export default {
  components: { Dialog, LoginDialog },
  props: {
    instancia: { type: String, default: "" },
  },

  setup() {
    const storeAuth = useAuthStore();
    const storeArvore = useStoreArvore();
    const storeUpload = useStoreUpload();

    return { storeAuth, storeArvore, storeUpload };
  },

  data() {
    return {
      timerFiltroTexto: null,
      itensTotal: 0,
      ordem: "nome_ASC",
      itens: [],
    };
  },

  mounted() {},

  methods: {
    abrir() {
      this.$emit("dialog-on");
      this.carregarItens();
      this.$refs.dialogNuvemConectar.abrir();
    },
    fechar() {
      this.$refs.dialogNuvemConectar.fechar();
      this.$emit("dialog-off");
    },
    async inserirItens() {
      if (this.dialog) {
        this.$emit("dialog-off");
      }
    },
    async carregarItens() {
      let filtros = "";

      //* Limpa os itens
      this.itens = [];

      filtros += "?_limit=" + 500;
      filtros += "&_ordem=" + this.ordem;

      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/usuario/minhas-nuvens" + filtros);
      api.valid(response.data, this.$router);

      if (this.$funcoes.appTipo() == "desktop") {
        this.itens.push({
          _id: "local",
          token: "local",
          nuvem: { nome: "Árvore Local" },
          usuarioNuvem: null,
        });
      }

      this.itens.push({
        _id: this.storeAuth.sessao.usuario.nuvem._id,
        token: this.storeAuth.sessao.token,
        nuvem: this.storeAuth.sessao.usuario.nuvem,
        usuarioNuvem: this.storeAuth.sessao.usuario,
      });

      if (response.data.itens.length > 0) {
        this.itens.push(...response.data.itens);
      }

      this.itensTotal = response.data.total;
    },
    conectar(item) {
      //* Define como padrao que esta tratando uma arvore remota
      var tipo = "remoto";

      //* Limpar filas
      this.storeUpload.fila = {};
      this.storeUpload.arquivos = {};

      //* Limpa a conexao a outra nuvem
      this.storeAuth.token[this.instancia] = "";
      this.storeAuth.nuvem[this.instancia] = null;

      //* Verifica se esta conectando nos diretorios locais
      if (item.token == "local") {
        //* Altera o tipo para local
        tipo = "local";
      } else {
        //* Verifica se conectou na mesma sessao do usuario conectado
        if (item.token !== this.storeAuth.sessao.token) {
          //* Define o token da nuvem conectada
          this.storeAuth.token[this.instancia] = item.token;

          //* Define os dados da nuvem
          this.storeAuth.nuvem[this.instancia] = item.nuvem;

          //* Armazena o usuario autenticado da nuvem
          this.storeAuth.nuvem[this.instancia].usuarioNuvem = item.usuarioNuvem;
        }
      }

      //* Recarrega a arvore
      this.storeArvore.recarregarInstancia(this.instancia, tipo);

      //* Fecha a dialog
      this.fechar();
    },
    loginNuvem() {
      //* Fecha a janela de nuvens
      this.fechar();

      //* Abre a dialog de login
      this.$refs.loginDialog.abrir();
    },

    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const obj = this;

      this.timerFiltroTexto = setTimeout(function () {
        obj.carregarItens();
      }, 300);
    },
  },
};
</script>

<style>
</style>
