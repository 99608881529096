<template>
  <div>
    <Dialog
      :id="'dialogPropriedades'"
      ref="dialogPropriedades"
      :hide-header="true"
      :hideFooter="true"
      :modal="true"
      :tamanho="'lg'"
      :classModalBody="'mx-1 mx-xl-1 my-1'"
      :titulo="'Propriedades'"
      @dialog-off="fechar()"
    >
      <template #header>
        <div class="me-9 ms-9 mb-0 mt-5">
          <div class="d-flex">
            <div class="flex-grow-1">
              <h1 class="d-flex fs-2 mt-2" v-if="true">
                <span
                  class="fs-7 ms-2 mt-2 cursor-pointer"
                  :class="{
                    'fw-bolder': view == 'info',
                    'text-success': view == 'info',
                    'fw-normal': view !== 'info',
                    'text-dark': view !== 'info',
                  }"
                  @click="view = 'info'"
                  >Info</span
                >
                <span v-if="(storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem != null && storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia] && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')"
                  class="h-22px border-1 border-gray-200 border-start ms-3 mx-2 me-1"
                ></span>
                <span
                  v-if="(storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem != null && storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia] && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')"
                  class="fs-7 ms-2 mt-2 cursor-pointer"
                  :class="{
                    'fw-bolder': view == 'config',
                    'text-success': view == 'config',
                    'fw-normal': view !== 'config',
                    'text-dark': view !== 'config',
                  }"
                  @click="view = 'config'"
                  >Config</span
                >
                <span
                  class="h-22px border-1 border-gray-200 border-start ms-3 mx-2 me-1"
                  v-if="item._id !== '' && (storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem != null && storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia] && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')"
                ></span>
                <span
                  class="fs-7 ms-2 mt-2 cursor-pointer"
                  :class="{
                    'fw-bolder': view == 'compartilhamento',
                    'text-success': view == 'compartilhamento',
                    'fw-normal': view !== 'compartilhamento',
                    'text-dark': view !== 'compartilhamento',
                  }"
                  @click="view = 'compartilhamento'"
                  v-if="item._id !== '' && (storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem != null && storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia] && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')"
                  >Compartilhamento</span
                >
                <span
                  class="h-22px border-1 border-gray-200 border-start ms-3 mx-2 me-1"
                  v-if="(storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem != null && storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia] && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')"
                ></span>
                <span
                  class="fs-7 ms-2 mt-2 cursor-pointer"
                  :class="{
                    'fw-bolder': view == 'atividade',
                    'text-success': view == 'atividade',
                    'fw-normal': view !== 'atividade',
                    'text-dark': view !== 'atividade',
                  }"
                  @click="view = 'atividade'"
                  v-if="(storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem != null && storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia] && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')"
                  >Atividades</span
                >
              </h1>
            </div>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="separator m-0 mt-3" v-if="true"></div>
        </div>
      </template>
      <div>
        <div :class="{ 'd-none': view != 'info' }">
          <div class="text-center mb-5">
            <h5 class="text-gray-600 fw-bolder fs-5">Nuvem</h5>
            <span
              class="spinner-border text-primary"
              role="status"
              v-if="carregandoDadosBasicos"
            ></span>
            <h1
              class="text-primary fw-bolder fs-2 mt-2"
              v-if="!carregandoDadosBasicos"
            >
              {{ item.nuvem }}
            </h1>

            <h5 class="text-gray-600 fw-bolder fs-5 mt-3">Caminho</h5>
            <span
              class="spinner-border text-primary"
              role="status"
              v-if="carregandoDadosBasicos"
            ></span>
            <h1
              class="text-gray-800 fs-4 fw-bold ms-2 mt-2"
              style="word-break: break-all"
              v-if="!carregandoDadosBasicos"
            >
              {{ item.fullPath }}
            </h1>

            <div class="row mt-5">
              <div class="col-6">
                <h5 class="text-gray-600 fw-bolder fs-5 mt-3">Arquivos</h5>
                <span
                  class="spinner-border text-primary"
                  role="status"
                  v-if="carregandoDadosTotais"
                ></span>
                <h5
                  class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2 mt-5"
                  v-if="!carregandoDadosTotais"
                >
                  {{ item.arquivos }}
                </h5>
              </div>
              <div class="col-6">
                <h5 class="text-gray-600 fw-bolder fs-5 mt-3">Tamanho</h5>
                <span
                  class="spinner-border text-primary"
                  role="status"
                  v-if="carregandoDadosTotais"
                ></span>
                <h5
                  class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2 mt-5"
                  v-if="!carregandoDadosTotais"
                >
                  {{ $funcoes.tamanhoTexto(item.tamanho) }}
                </h5>
              </div>
            </div>

            <div class="row mt-5">
              <div class="col-12 text-center">
                <h5 class="text-gray-600 fw-bolder fs-5 mt-3">
                  Total com todas as versões
                </h5>
                <span
                  class="spinner-border text-primary"
                  role="status"
                  v-if="carregandoDadosTotais"
                ></span>
                <h5
                  class="fs-2x fw-bold text-gray-800 me-2 lh-1 ls-n2 mt-5"
                  v-if="!carregandoDadosTotais"
                >
                  {{ $funcoes.tamanhoTexto(item.tamanhoVersoes) }}
                </h5>
              </div>
            </div>

            <div class="row mt-5" v-if="(storeAuth.nuvem[instancia] == null && storeAuth?.sessao.usuario.tipoUsuario == 'adminNuvem') || (storeAuth.nuvem != null && storeAuth.nuvem[instancia] !== null && storeAuth?.nuvem[instancia] && storeAuth?.nuvem[instancia].usuarioNuvem.tipoUsuario == 'adminNuvem')">
              <div class="col-12 text-center">
                <button
                  class="btn btn-secondary btn-sm me-1"
                  @click="abrirLimparVersoes()"
                >
                  Limpar versões
                </button>
                <button
                  class="btn btn-secondary btn-sm"
                  @click="abrirLimparExcluidos()"
                >
                  Limpar excluidos
                </button>
              </div>
            </div>
          </div>
        </div>
        <div :class="{ 'd-none': view != 'config' }" class="text-center">
          <h5 class="text-info fw-bolder fs-5 mt-0">Armazenar até</h5>

          <div class="mb-10">
            <span
              class="spinner-border text-primary"
              role="status"
              v-if="carregandoDadosBasicos"
            ></span>
            <h5
              class="fs-2x fw-bold text-gray-800 me-2 lh-1 ls-n2 mt-5"
              v-if="!carregandoDadosBasicos"
            >
              {{ item.versoesArquivo }}
            </h5>

            <h5 class="fs-6 fw-semibold text-gray-400">Versões por arquivo</h5>
            <div class="mb-2"><span style="cursor:pointer;" class="badge" :class="{'badge-primary':!item.editandoVersoesArquivo, 'badge-secondary':item.editandoVersoesArquivo, }" @click="editarVersoesArquivo()">{{!item.editandoVersoesArquivo ? "Editar" : "Cancelar edição"}}</span></div>
            <input
              type="range"
              class="form-range"
              v-model="item.versoesArquivo"
              min="1"
              max="100"
              :disabled="!item.editandoVersoesArquivo"
            />
          </div>

          <h5 class="text-info fw-bolder fs-5 mt-8">
            Manter arquivos excluidos por
          </h5>

          <div class="mb-10">
            <span
              class="spinner-border text-primary"
              role="status"
              v-if="carregandoDadosBasicos"
            ></span>
            <h5
              class="fs-2x fw-bold text-gray-800 me-2 lh-1 ls-n2 mt-5"
              v-if="!carregandoDadosBasicos"
            >
              {{ item.diasLimpeza }}
            </h5>
            <h5 class="fs-6 fw-semibold text-gray-400">Dias</h5>
            <div class="mb-2"><span style="cursor:pointer;" class="badge" :class="{'badge-primary':!item.editandoDiasLimpeza, 'badge-secondary':item.editandoDiasLimpeza}" @click="editarDiasLimpeza()">{{!item.editandoDiasLimpeza ? "Editar" : "Cancelar edição"}}</span></div>
            <input
              type="range"
              class="form-range"
              v-model="item.diasLimpeza"
              min="0"
              max="365"
              :disabled="!item.editandoDiasLimpeza"
            />
          </div>

          <h5 class="text-info fw-bolder fs-5 mt-8" v-if="item._id == ''">
            Manter relatórios por
          </h5>

          <div class="mb-10" v-if="item._id == ''">
            <span
              class="spinner-border text-primary"
              role="status"
              v-if="carregandoDadosBasicos"
            ></span>
            <h5
              class="fs-2x fw-bold text-gray-800 me-2 lh-1 ls-n2 mt-5"
              v-if="!carregandoDadosBasicos"
            >
              {{ item.logDiasLimpeza }}
            </h5>
            <h5 class="fs-6 fw-semibold text-gray-400">Dias</h5>
            <input
              type="range"
              class="form-range"
              v-model="item.logDiasLimpeza"
              min="1"
              max="365"
            />
          </div>

          <div class="fv-row mb-7" v-if="item._id == ''">
            <h5 class="text-info fw-bolder fs-5 mt-8">Criptografia</h5>
            <span
              class="spinner-border text-primary"
              role="status"
              v-if="carregandoDadosBasicos"
            ></span>
            <div
              class="d-flex justify-content-center mt-5"
              :class="{ 'd-none': carregandoDadosBasicos }"
            >
              <div
                class="form-check form-check-custom form-check-solid mb-2 text-center"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  :checked="item.criptografia == 0"
                  @click="item.criptografia = 0"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="status"
                  @click="item.criptografia = 0"
                  >Sem criptografia</label
                >
              </div>

              <div
                class="ms-4 form-check form-check-custom form-check-solid mb-2 text-center"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  :checked="item.criptografia == 1"
                  @click="item.criptografia = 1"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="status"
                  @click="item.criptografia = 1"
                  >Com criptografia</label
                >
              </div>
            </div>
          </div>

          <div class="text-center mt-5">
            <button type="button" class="btn btn-success" @click="salvarItem()">
              Salvar
            </button>
          </div>
        </div>
        <div :class="{ 'd-none': view != 'compartilhamento' }">
          <div class="text-center mb-0">
            <div class="row mt-2 mb-0">
              <div class="col-12 text-center">
                <p
                  class="fs-6 mb-10"
                  :class="{ 'd-none': item.compartilhamento.status }"
                >
                  O compartilhamento desta pasta esta inativo neste momento
                </p>

                <div class="fv-row mb-5">
                  <label class="fs-6 fw-bold mb-1">Nome</label>

                  <input
                    class="form-control form-control-solid"
                    :class="{
                      'is-invalid': itemCompartilhamentoErros.nome.status,
                      'd-none':
                        item.compartilhamento.status &&
                        !item.compartilhamento.editando,
                    }"
                    type="text"
                    autofocus="true"
                    ref="nome"
                    autocomplete="off"
                    placeholder="Nome do compartilhamento"
                    v-model="itemCompartilhamento.nome"
                    @keyup="enterSalvarCompartilhamento"
                  />

                  <div
                    v-if="itemCompartilhamentoErros.nome.status"
                    class="invalid-feedback"
                  >
                    {{ itemCompartilhamentoErros.nome.mensagem }}
                  </div>

                  <h1
                    class="text-primary fw-bolder fs-2 mt-2 cursor-pointer"
                    :class="{
                      'd-none':
                        !item.compartilhamento.status ||
                        item.compartilhamento.editando,
                    }"
                    title="Editar"
                    @click="item.compartilhamento.editando = true"
                  >
                    {{ itemCompartilhamento.nome }}
                  </h1>
                </div>

                <button
                  class="btn btn-warning btn-sm mb-5"
                  :class="{ 'd-none': !item.compartilhamento.editando }"
                  @click="compartilhamentoAlterar()"
                >
                  Salvar
                </button>

                <button
                  class="btn btn-warning btn-sm"
                  :class="{ 'd-none': item.compartilhamento.status }"
                  @click="compartilhamentoIniciar()"
                >
                  Ativar
                </button>
              </div>
            </div>

            <div class="row mt-3 mb-5">
              <div class="col-12 text-center">
                <button
                  class="btn btn-warning btn-sm"
                  :class="{
                    'd-none':
                      !item.compartilhamento.status ||
                      item.compartilhamento.editando,
                  }"
                  @click="abrirCompartilhamentoExcluir()"
                >
                  Excluir Compartilhamento
                </button>
              </div>
            </div>

            <div>
              <div class="row mt-0">
                <div class="col-12 text-center">
                  <button
                    class="btn btn-secondary btn-sm me-2 w-130px"
                    @click="compartilhamentoAdicionarUsuario()"
                  >
                    Adicionar Usuário
                  </button>
                  <button
                    class="btn btn-secondary btn-sm w-130px"
                    @click="compartilhamentoAdicionarGrupo()"
                  >
                    Adicionar Grupo
                  </button>
                </div>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                >
                  <thead>
                    <tr class="fw-bolder text-muted">
                      <th class="min-w-200px text-start">
                        Usuários ({{
                          item.direitoUsuarios
                            ? item.direitoUsuarios.length
                            : 0
                        }})
                      </th>
                      <th class="w-150px text-center">Ler</th>
                      <th class="w-150px text-center">Escrever</th>
                      <th class="w-150px text-center">Excluir</th>
                      <th class="w-40px text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(usuario, usuarioIndex) in item.direitoUsuarios"
                      :key="usuario"
                      :item="usuario"
                    >
                      <td>
                        <div class="d-flex align-items-center">
                          <div class="symbol symbol-45px me-5">
                            <img
                              :src="
                                $funcoes.usuarioFoto(
                                  usuario,
                                  usuariosDados[usuario] &&
                                    usuariosDados[usuario].foto == true
                                    ? true
                                    : false
                                )
                              "
                              alt=""
                            />
                          </div>
                          <div class="d-flex justify-content-start flex-column">
                            <span class="text-start text-dark fw-bolder fs-6">{{
                              !usuariosDados[usuario]
                                ? usuario
                                : usuariosDados[usuario].nome
                            }}</span
                            ><span
                              class="text-start text-muted fw-bold text-muted d-block fs-7"
                              >{{
                                !usuariosDados[usuario]
                                  ? usuario
                                  : usuariosDados[usuario].email
                              }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge cursor-pointer"
                          @click="
                            compartilhamentoAlterarOpcao(
                              'direitoUsuarios',
                              'ler',
                              usuarioIndex
                            )
                          "
                          :class="{
                            'badge-light-success':
                              item.direitoUsuariosOpcoes[usuarioIndex].ler ==
                              true,
                            'badge-light-danger':
                              item.direitoUsuariosOpcoes[usuarioIndex].ler ==
                              false,
                          }"
                          >{{
                            item.direitoUsuariosOpcoes[usuarioIndex].ler == true
                              ? "Sim"
                              : "Nao"
                          }}</span
                        >
                      </td>
                      <td class="text-center">
                        <span
                          class="badge cursor-pointer"
                          @click="
                            compartilhamentoAlterarOpcao(
                              'direitoUsuarios',
                              'escrever',
                              usuarioIndex
                            )
                          "
                          :class="{
                            'badge-light-success':
                              item.direitoUsuariosOpcoes[usuarioIndex]
                                .escrever == true,
                            'badge-light-danger':
                              item.direitoUsuariosOpcoes[usuarioIndex]
                                .escrever == false,
                          }"
                          >{{
                            item.direitoUsuariosOpcoes[usuarioIndex].escrever ==
                            true
                              ? "Sim"
                              : "Nao"
                          }}</span
                        >
                      </td>
                      <td class="text-center">
                        <span
                          class="badge cursor-pointer"
                          @click="
                            compartilhamentoAlterarOpcao(
                              'direitoUsuarios',
                              'excluir',
                              usuarioIndex
                            )
                          "
                          :class="{
                            'badge-light-success':
                              item.direitoUsuariosOpcoes[usuarioIndex]
                                .excluir == true,
                            'badge-light-danger':
                              item.direitoUsuariosOpcoes[usuarioIndex]
                                .excluir == false,
                          }"
                          >{{
                            item.direitoUsuariosOpcoes[usuarioIndex].excluir ==
                            true
                              ? "Sim"
                              : "Nao"
                          }}</span
                        >
                      </td>
                      <td>
                        <div class="d-flex justify-content-end flex-shrink-0">
                          <button
                            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Excluir"
                            aria-label="Excluir"
                            @click="
                              compartilhamentoRemoverDireito(
                                'direitoUsuarios',
                                usuario
                              )
                            "
                          >
                            <span class="svg-icon svg-icon-3"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                /></svg
                            ></span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  class="text-start"
                  :class="{
                    'd-none':
                      !item.direitoUsuarios || item.direitoUsuarios.length > 0,
                  }"
                >
                  Nenhum usuario com acesso
                </p>
              </div>

              <div class="table-responsive">
                <table
                  class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                >
                  <thead>
                    <tr class="fw-bolder text-muted">
                      <th class="min-w-200px text-start">
                        Grupos ({{
                          item.direitoGrupos ? item.direitoGrupos.length : 0
                        }})
                      </th>
                      <th class="w-150px text-center">Ler</th>
                      <th class="w-150px text-center">Escrever</th>
                      <th class="w-150px text-center">Excluir</th>
                      <th class="w-40px text-end"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(grupo, grupoIndex) in item.direitoGrupos"
                      :key="grupo"
                      :item="grupo"
                    >
                      <td>
                        <div class="d-flex align-items-center text-start">
                          <div class="symbol symbol-45px me-5 mt-1 mb-1 mx-1">
                            <i
                              class="bi bi-people text-primary fs-2hx gx-5"
                            ></i>
                          </div>
                          <div class="d-flex justify-content-start flex-column">
                            <span class="text-dark fw-bolder fs-6">
                              {{
                                !gruposDados[grupo]
                                  ? grupo
                                  : gruposDados[grupo].nome
                              }}
                            </span>
                            <span
                              class="text-muted fw-bold text-muted d-block fs-7"
                              v-if="gruposDados[grupo]"
                              >{{ gruposDados[grupo].usuarios.length
                              }}{{
                                gruposDados[grupo].usuarios.length == 1
                                  ? " usuário"
                                  : " usuários"
                              }}</span
                            >
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge cursor-pointer"
                          @click="
                            compartilhamentoAlterarOpcao(
                              'direitoGrupos',
                              'ler',
                              grupoIndex
                            )
                          "
                          :class="{
                            'badge-light-success':
                              item.direitoGruposOpcoes[grupoIndex].ler == true,
                            'badge-light-danger':
                              item.direitoGruposOpcoes[grupoIndex].ler == false,
                          }"
                          >{{
                            item.direitoGruposOpcoes[grupoIndex].ler == true
                              ? "Sim"
                              : "Nao"
                          }}</span
                        >
                      </td>
                      <td class="text-center">
                        <span
                          class="badge cursor-pointer"
                          @click="
                            compartilhamentoAlterarOpcao(
                              'direitoGrupos',
                              'escrever',
                              grupoIndex
                            )
                          "
                          :class="{
                            'badge-light-success':
                              item.direitoGruposOpcoes[grupoIndex].escrever ==
                              true,
                            'badge-light-danger':
                              item.direitoGruposOpcoes[grupoIndex].escrever ==
                              false,
                          }"
                          >{{
                            item.direitoGruposOpcoes[grupoIndex].escrever ==
                            true
                              ? "Sim"
                              : "Nao"
                          }}</span
                        >
                      </td>
                      <td class="text-center">
                        <span
                          class="badge cursor-pointer"
                          @click="
                            compartilhamentoAlterarOpcao(
                              'direitoGrupos',
                              'excluir',
                              grupoIndex
                            )
                          "
                          :class="{
                            'badge-light-success':
                              item.direitoGruposOpcoes[grupoIndex].excluir ==
                              true,
                            'badge-light-danger':
                              item.direitoGruposOpcoes[grupoIndex].excluir ==
                              false,
                          }"
                          >{{
                            item.direitoGruposOpcoes[grupoIndex].excluir == true
                              ? "Sim"
                              : "Nao"
                          }}</span
                        >
                      </td>
                      <td>
                        <div class="d-flex justify-content-end flex-shrink-0">
                          <button
                            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Excluir"
                            aria-label="Excluir"
                            @click="
                              compartilhamentoRemoverDireito(
                                'direitoGrupos',
                                grupo
                              )
                            "
                          >
                            <span class="svg-icon svg-icon-3"
                              ><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                                />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                /></svg
                            ></span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  class="text-start"
                  :class="{
                    'd-none':
                      !item.direitoGrupos || item.direitoGrupos.length > 0,
                  }"
                >
                  Nenhum grupo com acesso
                </p>
              </div>
            </div>
          </div>
        </div>
        <div :class="{ 'd-none': view != 'atividade' }">
          <AtividadeList ref="listAtividade" :dialog="false" />
        </div>
      </div>
    </Dialog>
    <DialogMensagem
      ref="dialogLimparVersoes"
      titulo="Limpar "
      mensagem="Confirme, caso deseje limpar as versões. Este processo pode demorar alguns minutos para ser concluído"
      classBody="mx-5 mx-xl-15 my-1 text-center"
      @confirmar="confirmarLimparVersoes(true)"
      @cancelar="confirmarLimparVersoes(false)"
    />
    <DialogMensagem
      ref="dialogLimparExcluidos"
      titulo="Limpar excluidos"
      mensagem="Confirme, caso deseje limpar os arquivos excluidos. Este processo pode demorar alguns minutos para ser concluído"
      classBody="mx-5 mx-xl-15 my-1 text-center"
      @confirmar="confirmarLimparExcluidos(true)"
      @cancelar="confirmarLimparExcluidos(false)"
    />
    <DialogMensagem
      ref="dialogExcluirCompartilhamento"
      titulo="Excluir compartilhamento"
      mensagem="Confirme, caso deseje excluir o compartilhamento ativo."
      classBody="mx-5 mx-xl-15 my-1 text-center"
      @confirmar="confirmarCompartilhamentoExcluir(true)"
      @cancelar="confirmarCompartilhamentoExcluir(false)"
    />
    <UsuarioSelectDialog
      ref="dialogCompartilhamentoUsuario"
      @cancelar="compartilhamentoAdicionarUsuarioRetorno(null)"
      @selecionar="compartilhamentoAdicionarUsuarioRetorno"
    />
    <GrupoSelectDialog
      ref="dialogCompartilhamentoGrupo"
      @cancelar="compartilhamentoAdicionarGrupoRetorno(null)"
      @selecionar="compartilhamentoAdicionarGrupoRetorno"
    />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import Dialog from "../components/ComponentDialog.vue";
import DialogMensagem from "../components/ComponentDialogMensagem.vue";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreArvore } from "../stores/storeArvore.js";
import UsuarioSelectDialog from "./UsuarioSelectDialog.vue";
import GrupoSelectDialog from "./GrupoSelectDialog.vue";
import AtividadeList from "./AtividadeList.vue";

export default {
  components: {
    Dialog,
    DialogMensagem,
    UsuarioSelectDialog,
    GrupoSelectDialog,
    AtividadeList,
  },
  props: {
    instancia: { type: String, default: "" },
  },

  setup() {
    const storeAuth = useAuthStore();
    const storeArvore = useStoreArvore();

    return { storeAuth, storeArvore };
  },
  data() {
    return {
      timerFiltroTexto: null,
      instancia: "",
      carregandoDadosBasicos: false,
      carregandoDadosTotais: false,
      item: {
        _id: "",
        nuvem: "",
        nome: "",
        fullPath: "",
        arquivos: 0,
        tamanho: 0,
        tamanhoVersoes: 0,
        logDiasLimpeza: 0,
        diasLimpeza: 0,
        versoesArquivo: 0,
        editandoDiasLimpeza: false,
        editandoVersoesArquivo: false,
        criptografia: 0,
        direitoUsuarios: [],
        direitoGrupos: [],
        direitoUsuariosOpcoes: [],
        direitoGruposOpcoes: [],
        compartilhamento: {},
      },
      itemCompartilhamento: {
        nome: "",
      },
      itemCompartilhamentoErros: {
        nome: { status: false, mensagem: "" },
      },
      temp:{
        diasLimpeza: 0,
        versoesArquivo: 0
      },
      usuariosDados: {},
      gruposDados: {},
      view: "info",
      diretorioRoot: false,
    };
  },

  mounted() {},

  methods: {
    abrir() {
      this.$emit("dialog-on");

      //* Define que consta uma dialog aberta
      this.storeArvore.dialogAberta = true;

      //* Define a visualizacao padrao
      this.view = "info";

      //this.carregarItens();
      this.$refs.dialogPropriedades.abrir();
    },
    fechar() {
      //* Define que consta uma dialog fechada
      this.storeArvore.dialogAberta = false;

      this.$refs.dialogPropriedades.fechar();
      this.$emit("dialog-off");
    },
    async carregarItem(instancia, diretorioRoot = false) {
      var thisCall = this;

      var itemId = null;

      //* Acerta o status
      this.carregandoDadosBasicos = true;
      this.carregandoDadosTotais = true;

      //* Limpa os itens
      this.novo();

      //* Define se o item root
      this.diretorioRoot = diretorioRoot;

      //* Verifica se deve selecionar o diretorio root
      if (diretorioRoot == true) {
        itemId = "";
      } else {
        //* Carrega keys selecionadas
        var keysSelecionadas = Object.keys(
          this.storeArvore.instancias[instancia].selecionados
        );

        //* Verifica se um item esta selecionado
        if (keysSelecionadas.length > 0) {
          //* Carrega o item selecionado
          itemId =
            this.storeArvore.instancias[instancia].selecionados[
              keysSelecionadas[0]
            ]._id;
        }
      }

      var response;

      if (diretorioRoot == false) {
        //* Carrega os dados do diretorio
        response = await api.get(
          this.storeAuth.apiNuvem(instancia) +
            "/diretorio/" +
            itemId +
            "?compartilhamento",
          this.storeAuth.token[instancia] !== ""
            ? this.storeAuth.token[instancia]
            : null
        );

        api.valid(response.data, this.$router);

        //* Verifica se carregou com sucesso
        if (response.data.erro == false && response.data.itens.length > 0) {
          //* Define o item
          var item = response.data.itens[0];

          //* Define os campos
          this.item._id = item._id;
          this.item.nuvem = item.nuvem.nome;
          this.item.nome = item.nome;
          this.item.fullPath = item.fullPath;
          this.item.diasLimpeza = item.hasOwnProperty("diasLimpeza")
            ? item.diasLimpeza
            : item.nuvem.hasOwnProperty("diasLimpeza")
            ? item.nuvem.diasLimpeza
            : 30;

          this.item.logDiasLimpeza = item.hasOwnProperty("logDiasLimpeza")
            ? item.logDiasLimpeza
            : item.nuvem.hasOwnProperty("logDiasLimpeza")
            ? item.nuvem.logDiasLimpeza
            : 14;

          this.item.versoesArquivo = item.versoesArquivo
            ? item.versoesArquivo
            : item.nuvem.versoesArquivo
            ? item.nuvem.versoesArquivo
            : 10;

          this.item.editandoDiasLimpeza = false;
          this.item.editandoVersoesArquivo = false;

          this.item.arquivos = 0;
          this.item.tamanho = 0;
          this.item.tamanhoVersoes = 0;
          this.item.criptografia = 0;

          this.item.direitoUsuarios = item.direitoUsuarios
            ? item.direitoUsuarios
            : [];
          this.item.direitoGrupos = item.direitoGrupos
            ? item.direitoGrupos
            : [];

          this.item.direitoUsuariosOpcoes = item.direitoUsuariosOpcoes
            ? item.direitoUsuariosOpcoes
            : [];
          this.item.direitoGruposOpcoes = item.direitoGruposOpcoes
            ? item.direitoGruposOpcoes
            : [];

          //* Verifica se o compartilhamento existe
          if (item.compartilhamento) {
            this.item.compartilhamento = item.compartilhamento;
            this.itemCompartilhamento.nome = item.compartilhamento.nome;
          } else {
            this.item.compartilhamento = {};
            this.itemCompartilhamento.nome = this.item.nome;
          }

          //* Carrega as atividades
          this.$refs.listAtividade.itens = [];
          this.$refs.listAtividade.item = {
            tipo: "diretorio",
            _id: itemId,
          };
          this.$refs.listAtividade.aplicarFiltro();
        }
      } else {
        //* Carrega os dados do diretorio
        response = await api.get(
          process.env.VUE_APP_URL_API + "/admin/sessao/refresh",
          this.storeAuth.token[instancia] !== ""
            ? this.storeAuth.token[instancia]
            : null
        );

        api.valid(response.data, this.$router);

        //* Verifica se carregou com sucesso
        if (response.data.erro == false) {
          //* Define o item
          var item = response.data.item;

          //* Define os campos
          this.item._id = "";
          this.item.nuvem = item.usuario.nuvem.nome;
          this.item.nome = "/";
          this.item.fullPath = "/";
          this.item.diasLimpeza = item.usuario.nuvem.hasOwnProperty(
            "diasLimpeza"
          )
            ? item.usuario.nuvem.diasLimpeza
            : 30;

          this.item.logDiasLimpeza = item.usuario.nuvem.hasOwnProperty(
            "logDiasLimpeza"
          )
            ? item.usuario.nuvem.logDiasLimpeza
            : 14;

          this.item.versoesArquivo = item.usuario.nuvem.versoesArquivo
            ? item.usuario.nuvem.versoesArquivo
            : 10;

          this.item.editandoDiasLimpeza = false;
          this.item.editandoVersoesArquivo = false;

          this.item.arquivos = 0;
          this.item.tamanho = 0;
          this.item.tamanhoVersoes = 0;
          this.item.criptografia = item.usuario.nuvem.criptografia;

          //* Carrega as atividades
          this.$refs.listAtividade.itens = [];
          this.$refs.listAtividade.item = null;
          this.$refs.listAtividade.aplicarFiltro();
          
        }
      }

      //* Acerta o status
      this.carregandoDadosBasicos = false;

      //* Armazena o id que esta sendo carregado
      var itemIdCarregando = itemId.toString();

      //* Carrega os totais do diretorio
      api
        .get(
          this.storeAuth.apiNuvem(instancia) +
            "/diretorio/" +
            itemId +
            "?totais=true" +
            (this.storeArvore.instancias[instancia].view == "excluidos"
              ? "&excluidos=true"
              : ""),
          this.storeAuth.token[instancia] !== ""
            ? this.storeAuth.token[instancia]
            : null
        )
        .then(function (body) {
          //* Verifica se carregou com sucesso
          if (body.data.erro == false && body.data.totais) {
            if (itemIdCarregando == thisCall.item._id) {
              thisCall.item.arquivos = body.data.totais.totalArquivos;
              thisCall.item.tamanho = body.data.totais.totalTamanho;
              thisCall.item.tamanhoVersoes = body.data.totais.totalVersoes;

              //* Acerta o status
              thisCall.carregandoDadosTotais = false;
            }
          }
        });

      //* Carrega os usuarios e grupos
      this.carregarUsuariosCompartilhamento();
      this.carregarGruposCompartilhamento();
    },
    async carregarUsuariosCompartilhamento() {
      this.usuariosDados = await api.getItensObjectId(process.env.VUE_APP_URL_API + "/admin/usuario");
    },
    async carregarGruposCompartilhamento() {
      this.gruposDados = await api.getItensObjectId(process.env.VUE_APP_URL_API + "/admin/grupo");
    },
    async salvarItem() {

      //* Define os dados a serem enviados para o diretorio
      let bodyDiretorio = {
        criptografia: this.item.criptografia        
      };

      if(this.item.editandoDiasLimpeza)
      {
        bodyDiretorio.diasLimpeza = this.item.diasLimpeza;
      }

      if(this.item.editandoVersoesArquivo)
      {
        bodyDiretorio.versoesArquivo = this.item.versoesArquivo;
      }
      
      //* Verifica se esta no diretorio root
      if (this.diretorioRoot) {

        let bodyNuvem = {
          criptografia: this.item.criptografia,
          diasLimpeza: this.item.diasLimpeza,
          versoesArquivo: this.item.versoesArquivo,
          logDiasLimpeza: this.item.logDiasLimpeza
        };

        //* Envia os dados para alteracao
        let apiRequest = await api.put(
          process.env.VUE_APP_URL_API + "/admin/nuvem",
          bodyNuvem,
          this.storeAuth.token[this.instancia] !== ""
            ? this.storeAuth.token[this.instancia]
            : null
        );

        //* Verifica se ocorreu algum erro
        if (apiRequest.data.erro == false) {

          api.put(
            this.storeAuth.apiNuvem(this.instancia) +
              "/diretorio/config",
              bodyDiretorio,
            this.storeAuth.token[this.instancia] !== ""
              ? this.storeAuth.token[this.instancia]
              : null
          );

          //* Fecha a dialog
          this.$refs.dialogPropriedades.fechar();
        }
      } else {

        //* Verifica se possui o id a ser alterado
        if (this.item._id !== "") {
          //* Envia os dados para alteracao
          let apiRequest = await api.put(
            this.storeAuth.apiNuvem(this.instancia) +
              "/diretorio/" +
              this.item._id,
              bodyDiretorio,
            this.storeAuth.token[this.instancia] !== ""
              ? this.storeAuth.token[this.instancia]
              : null
          );

          //* Verifica se ocorreu algum erro
          if (apiRequest.data.erro == false) {
            //* Fecha a dialog
            this.$refs.dialogPropriedades.fechar();
          }
        }
      }
    },
    abrirLimparVersoes() {
      this.$refs.dialogPropriedades.fechar();
      this.$refs.dialogLimparVersoes.abrir();
    },
    async confirmarLimparVersoes(confirmar) {
      this.$refs.dialogLimparVersoes.fechar();

      if (confirmar) {
        //* Define os dados a serem enviados
        var body = {};

        if (!this.diretorioRoot) {
          body.diretorio = this.item._id;
        }

        //* Envia os dados para alteracao
        await api.post(
          this.storeAuth.apiAdmin(this.instancia) + "/admin/nuvem/limpar/versoes/v2",
          body,
          this.storeAuth.token[this.instancia] !== ""
            ? this.storeAuth.token[this.instancia]
            : null
        );
      }

      this.$refs.dialogPropriedades.abrir();
    },
    abrirLimparExcluidos() {
      this.$refs.dialogPropriedades.fechar();
      this.$refs.dialogLimparExcluidos.abrir();
    },
    enterSalvarCompartilhamento(event) {
      if (event.keyCode === 13) {
        if (this.item.compartilhamento.editando) {
          this.compartilhamentoAlterar();
        } else {
          this.compartilhamentoIniciar();
        }
      }
      event.stopPropagation();
    },
    async compartilhamentoIniciar() {
      //* Limpa os erros
      this.itemCompartilhamentoErros = api.errosLimpar(
        this.itemCompartilhamentoErros
      );

      //* Verifica se possui o id a ser alterado
      if (this.item._id !== "") {
        //* Define os dados a serem enviados
        const body = {
          nome: this.itemCompartilhamento.nome,
          diretorio: this.item._id,
        };

        //* Envia os dados para alteracao
        let apiRequest = await api.post(
          this.storeAuth.apiNuvem(this.instancia) + "/compartilhamento",
          body,
          this.storeAuth.token[this.instancia] !== ""
            ? this.storeAuth.token[this.instancia]
            : null
        );

        //* Verifica se ocorreu algum erro
        if (apiRequest.data.erro == false) {
          this.item.compartilhamento = apiRequest.data.item;
        } else {
          api.errosProcessar(
            this.itemCompartilhamentoErros,
            apiRequest.data.erros
          );
          return;
        }
      }
    },
    async compartilhamentoAlterar() {
      //* Limpa os erros
      this.itemCompartilhamentoErros = api.errosLimpar(
        this.itemCompartilhamentoErros
      );

      //* Verifica se possui o id a ser alterado
      if (this.item.compartilhamento._id !== "") {
        //* Define os dados a serem enviados
        const body = {
          nome: this.itemCompartilhamento.nome,
        };

        //* Envia os dados para alteracao
        let apiRequest = await api.put(
          this.storeAuth.apiNuvem(this.instancia) +
            "/compartilhamento/" +
            this.item.compartilhamento._id,
          body,
          this.storeAuth.token[this.instancia] !== ""
            ? this.storeAuth.token[this.instancia]
            : null
        );

        if (apiRequest.data.erro) {
          api.errosProcessar(
            this.itemCompartilhamentoErros,
            apiRequest.data.erros
          );
          return;
        } else {
          delete this.item.compartilhamento.editando;
        }
      }
    },
    compartilhamentoAdicionarUsuario() {
      this.$refs.dialogPropriedades.fechar();
      this.$refs.dialogCompartilhamentoUsuario.abrir(
        this.item.direitoUsuarios.reduce((a, v) => ({ ...a, [v]: v }), {})
      );
    },
    async compartilhamentoAdicionarUsuarioRetorno(itensSelecionados) {
      //* Verifica se algum item foi selecionado
      if (itensSelecionados !== null) {
        //* Define os dados a serem enviados
        const body = {
          _id: this.item._id,
          tipo: "direitoUsuarios",
          itens: Object.keys(itensSelecionados),
        };

        //* Envia os dados para alteracao
        let apiRequest = await api.put(
          this.storeAuth.apiNuvem(this.instancia) + "/diretorio/direitos",
          body,
          this.storeAuth.token[this.instancia] !== ""
            ? this.storeAuth.token[this.instancia]
            : null
        );

        //* Verifica se ocorreu algum erro
        if (apiRequest.data.erro == false) {
          //* Atualiza a listagem
          this.item.direitoUsuarios = apiRequest.data.itens;
          this.item.direitoUsuariosOpcoes = apiRequest.data.itensOpcoes;
        }
      }

      //* Reabre a janela
      this.$refs.dialogPropriedades.abrir();
    },
    compartilhamentoAdicionarGrupo() {
      this.$refs.dialogPropriedades.fechar();
      this.$refs.dialogCompartilhamentoGrupo.abrir(
        this.item.direitoGrupos.reduce((a, v) => ({ ...a, [v]: v }), {})
      );
    },
    async compartilhamentoAdicionarGrupoRetorno(itensSelecionados) {
      //* Verifica se algum item foi selecionado
      if (itensSelecionados !== null) {
        //* Define os dados a serem enviados
        const body = {
          _id: this.item._id,
          tipo: "direitoGrupos",
          itens: Object.keys(itensSelecionados),
        };

        //* Envia os dados para alteracao
        let apiRequest = await api.put(
          this.storeAuth.apiNuvem(this.instancia) + "/diretorio/direitos",
          body,
          this.storeAuth.token[this.instancia] !== ""
            ? this.storeAuth.token[this.instancia]
            : null
        );

        //* Verifica se ocorreu algum erro
        if (apiRequest.data.erro == false) {
          //* Atualiza a listagem
          this.item.direitoGrupos = apiRequest.data.itens;
          this.item.direitoGruposOpcoes = apiRequest.data.itensOpcoes;
        }
      }

      //* Reabre a janela
      this.$refs.dialogPropriedades.abrir();
    },
    async compartilhamentoAlterarOpcao(tipo, opcao, index) {
      //* Define os dados a serem enviados
      let body = {
        _id: this.item._id,
        tipo: tipo,
        usuario: this.item[tipo][index],
      };

      //* Altera a opcao
      this.item[tipo + "Opcoes"][index][opcao] =
        !this.item[tipo + "Opcoes"][index][opcao];

      //* Define a opcao
      body[opcao] = this.item[tipo + "Opcoes"][index][opcao];

      //* Envia os dados para alteracao
      await api.put(
        this.storeAuth.apiNuvem(this.instancia) + "/diretorio/direitos/opcao",
        body,
        this.storeAuth.token[this.instancia] !== ""
          ? this.storeAuth.token[this.instancia]
          : null
      );
    },
    async compartilhamentoRemoverDireito(tipo, item) {
      //* Define os dados a serem enviados
      const body = {
        _id: this.item._id,
        tipo: tipo,
        itens: [item],
      };

      //* Envia os dados para alteracao
      let apiRequest = await api.put(
        this.storeAuth.apiNuvem(this.instancia) + "/diretorio/direitos/remover",
        body,
        this.storeAuth.token[this.instancia] !== ""
          ? this.storeAuth.token[this.instancia]
          : null
      );

      //* Verifica se ocorreu algum erro
      if (apiRequest.data.erro == false) {
        //* Atualiza a listagem
        this.item[tipo] = apiRequest.data.itens;
        this.item[tipo + "Opcoes"] = apiRequest.data.itensOpcoes;
      }
    },
    abrirCompartilhamentoExcluir() {
      this.$refs.dialogPropriedades.fechar();
      this.$refs.dialogExcluirCompartilhamento.abrir();
    },
    async confirmarCompartilhamentoExcluir(confirmar) {
      this.$refs.dialogExcluirCompartilhamento.fechar();

      if (confirmar) {
        //* Define os dados a serem enviados
        var body = {};
        body._id = this.item.compartilhamento._id;

        //* Exclui o compartilhamento
        var apiRequest = await api.put(
          this.storeAuth.apiNuvem(this.instancia) + "/compartilhamento/remover",
          body,
          this.storeAuth.token[this.instancia] !== ""
            ? this.storeAuth.token[this.instancia]
            : null
        );

        //* Verifica se excluiu
        if (!apiRequest.data.erro) {
          this.item.compartilhamento = {};
        }
      }

      this.$refs.dialogPropriedades.abrir();
    },
    editarVersoesArquivo()
    {

      this.item.editandoVersoesArquivo = !this.item.editandoVersoesArquivo;

      if(this.item.editandoVersoesArquivo)
      {
        this.temp.versoesArquivo = this.item.versoesArquivo;  
      }else{
        this.item.versoesArquivo = this.temp.versoesArquivo;
      }

    },
    editarDiasLimpeza()
    {

      this.item.editandoDiasLimpeza = !this.item.editandoDiasLimpeza;

      if(this.item.editandoDiasLimpeza)
      {
        this.temp.diasLimpeza = this.item.diasLimpeza;  
      }else{
        this.item.diasLimpeza = this.temp.diasLimpeza;
      }

    },
    novo() {
      //* Limpa os erros
      this.itemCompartilhamentoErros = api.errosLimpar(
        this.itemCompartilhamentoErros
      );

      //* Limpa os campos
      this.item._id = "";
      this.item.nuvem = "-";
      this.item.nome = "-";
      this.item.fullPath = "";
      this.item.logDiasLimpeza = 0;
      this.item.diasLimpeza = 0;
      this.item.versoesArquivo = 0;
      this.item.editandoDiasLimpeza = false;
      this.item.editandoVersoesArquivo = false;
      this.item.arquivos = 0;
      this.item.tamanho = 0;
      this.item.tamanhoVersoes = 0;
      this.item.criptografia = 0;

      //* Remove o compartilhamento
      this.item.compartilhamento = {};

      //* Limpa o nome do compartilhamento
      this.itemCompartilhamento.nome = "";

      //* Limpa o cache de itens
      this.usuariosDados = {};
      this.gruposDados = {};
    },
    async confirmarLimparExcluidos(confirmar) {
      this.$refs.dialogLimparExcluidos.fechar();

      if (confirmar) {
        //* Define os dados a serem enviados
        var body = {};

        if (!this.diretorioRoot) {
          body.diretorio = this.item._id;
        }

        //* Envia os dados para alteracao
        await api.post(
          this.storeAuth.apiAdmin(this.instancia) + "/admin/nuvem/limpar/excluidos/v2",
          body,
          this.storeAuth.token[this.instancia] !== ""
            ? this.storeAuth.token[this.instancia]
            : null
        );
      }

      this.$refs.dialogPropriedades.abrir();
    },
  },
};
</script>

<style>
</style>
