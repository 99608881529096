<template>
  <div>
    <Dialog
      :id="'dialogBackupTrigger'"
      ref="dialogBackupTrigger"
      :hide-header="false"
      :hideFooter="true"
      :modal="true"
      :tamanho="'xl'"
      :classModalBody="'mx-1 mx-xl-1 my-1'"
      :titulo="'Agendar'"
      @dialog-off="fechar()"
    >
      <div>
        <div>
          <div class="mx-6">
            <div class="">
              <table class="table table-row-dashed table-row-gray-300">
                <thead>
                  <tr class="fw-bold fs-6 text-gray-800">
                    <th>#</th>
                    <th class="min-w-130px">Horas</th>
                    <th class="min-w-130px">Minutos</th>
                    <th class="min-w-130px">Segundos</th>
                    <th class="min-w-130px">Dias</th>
                    <th class="min-w-130px">Meses</th>
                    <th class="min-w-130px">Semana</th>
                    <th class="min-w-130px">Completo</th>
                    <th class="w-40px text-end">Opções</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, itemIndex) in itens"
                    :key="item._id"
                    :item="item"
                  >
                    <td class="align-middle">{{ itemIndex + 1 }}</td>
                    <td class="align-middle">
                      <span
                        class="text-dark text-hover-primary fs-6"
                        style="cursor: pointer; word-break: break-all"
                        @click="
                          editarGatilho(item.cronHoras, 'horas', itemIndex)
                        "
                        >{{ item.textoHoras }}</span
                      >
                    </td>
                    <td class="align-middle">
                      <span
                        class="text-dark text-hover-primary fs-6"
                        style="cursor: pointer; word-break: break-all"
                        @click="
                          editarGatilho(item.cronMinutos, 'minutos', itemIndex)
                        "
                        >{{ item.textoMinutos }}</span
                      >
                    </td>
                    <td class="align-middle">
                      <span
                        class="text-dark text-hover-primary fs-6"
                        style="cursor: pointer; word-break: break-all"
                        @click="
                          editarGatilho(
                            item.cronSegundos,
                            'segundos',
                            itemIndex
                          )
                        "
                        >{{ item.textoSegundos }}</span
                      >
                    </td>
                    <td class="align-middle">
                      <span
                        class="text-dark text-hover-primary fs-6"
                        style="cursor: pointer; word-break: break-all"
                        @click="editarGatilho(item.cronDias, 'dias', itemIndex)"
                        >{{ item.textoDias }}</span
                      >
                    </td>
                    <td class="align-middle">
                      <span
                        class="text-dark text-hover-primary fs-6"
                        style="cursor: pointer; word-break: break-all"
                        @click="
                          editarGatilho(item.cronMeses, 'meses', itemIndex)
                        "
                        >{{ item.textoMeses }}</span
                      >
                    </td>
                    <td class="align-middle">
                      <span
                        class="text-dark text-hover-primary fs-6"
                        style="cursor: pointer; word-break: break-all"
                        @click="
                          editarGatilho(item.cronSemana, 'semana', itemIndex)
                        "
                        >{{ item.textoSemana }}</span
                      >
                    </td>
                    <td class="align-middle">
                      <span
                        class="text-dark text-hover-primary fs-6"
                        style="cursor: pointer; word-break: break-all"
                        @click="
                          editarGatilho(item.cronCompleto, 'completo', itemIndex)
                        "
                        >{{ item.textoCompleto }}</span
                      >
                    </td>
                    <td class="align-middle text-end">
                      <span
                        data-bs-dismiss="click"
                        data-bs-toggle="tooltip2"
                        data-bs-placement="top"
                        title="Remover"
                      >
                        <button
                          class="
                            btn
                            btn-icon
                            btn-bg-light
                            btn-active-color-primary
                            btn-sm
                          "
                          @click="remover(itemIndex)"
                          onclick="this.blur()"
                        >
                          <span class="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-trash"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                              />
                              <path
                                fill-rule="evenodd"
                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                              />
                            </svg>
                          </span>
                        </button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-sm btn-warning"
              @click="novoItem('* * * * * * P')"
            >
              <span class="svg-icon svg-icon-2"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="11.364"
                    y="20.364"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-90 11.364 20.364)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="4.36396"
                    y="11.364"
                    width="16"
                    height="2"
                    rx="1"
                    fill="currentColor"
                  ></rect></svg></span
              >Novo
            </button>
          </div>

          <BackupTriggerEditDialog
            ref="backupTriggerEditDialog"
            @dialog-off="abrir()"
            @definir="definirGatilho()"
          ></BackupTriggerEditDialog>

          <div class="separator separator-dashed my-4"></div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-secondary me-3"
              @click="fechar()"
            >
              Cancelar
            </button>
            <button type="button" class="btn btn-primary" @click="salvar()">
              Salvar
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import Dialog from "../components/ComponentDialog.vue";
import BackupTriggerEditDialog from "./BackupTriggerEditDialog.vue";

import { useAuthStore } from "@/stores/auth.js";

export default {
  components: { Dialog, BackupTriggerEditDialog },
  props: {},

  setup() {
    const storeAuth = useAuthStore();

    return { storeAuth };
  },

  data() {
    return {
      itens: [],
      _id: "",
      aba: "local",
    };
  },

  mounted() {},

  methods: {
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogBackupTrigger.abrir();
    },
    fechar() {
      this.$refs.dialogBackupTrigger.fechar();
      this.$emit("dialog-off");
    },
    async salvar() {
      var arrItens = [];

      //* Converte os itens
      this.itens.filter(function (item) {
        arrItens.push(item.cron);
        return true;
      });

      //* Envia os gatilhos do backup
      await api.put(this.storeAuth.apiNuvem() + "/backup/" + this._id, {
        gatilhos: arrItens,
      });

      //* Fecha
      this.$refs.dialogBackupTrigger.fechar();
      this.$emit("dialog-off");
    },
    async carregarItem(id) {
      //* Armazena o id
      this._id = id;

      //* Limpa os itens
      this.itens = [];

      //* Carrega os dados da api
      const response = await api.get(
        this.storeAuth.apiNuvem() + "/backup/" + id
      );

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemEdit = response.data.itens[0];

        //* Verifica se consta
        if (itemEdit.gatilhos) {
          //* Limpa os itens atuais
          this.itens = [];

          //itemEdit.gatilhos = ["* * * * * *", "*/2 1,10,15,30 * * * 1,2,3,4,5"];

          //* Percorre os itens
          for (
            var indiceItem = 0;
            indiceItem < itemEdit.gatilhos.length;
            indiceItem++
          ) {
            //* Cria o item na lista
            this.novoItem(itemEdit.gatilhos[indiceItem]);
          }
        }
      }
    },
    converterItem(item, semana = false, completo = false) {

      //* Verifica se esta tratando o item completo
      if(completo)
      {
        var itemConvertidoCompleto = {
          cron: item,
          tipo: "fixo",
          valores: [],
          texto: "Padrão",
        };  

        //* Define o valor
        itemConvertidoCompleto.valores = [item];

        if(item == "P")
        {
          itemConvertidoCompleto.texto = "Padrão";
        }else if(item == "S")
        {
          itemConvertidoCompleto.texto = "Sim";
        }else if(item == "N")
        {
          itemConvertidoCompleto.texto = "Não";
        }

        return itemConvertidoCompleto;
        
      }

      //* Define o padrao
      var itemConvertido = {
        cron: item,
        tipo: "fixo",
        valores: [],
        texto: "A qualquer",
      };

      //* Valida se deve executar sempre
      if (item !== "*") {
        //* Verifica o tipo
        if (item.indexOf("*/") > -1) {
          itemConvertido.tipo = "acada";
          itemConvertido.valores = item.split("/")[1];
          itemConvertido.texto = "A cada " + item.split("/")[1].toString();
        } else {
          itemConvertido.valores = item.split(",");

          if (semana) {
            var arrNomear = [];

            itemConvertido.valores.filter(function (item) {
              arrNomear.push(
                item == 0
                  ? "DOM"
                  : item == 1
                  ? "SEG"
                  : item == 2
                  ? "TER"
                  : item == 3
                  ? "QUA"
                  : item == 4
                  ? "QUI"
                  : item == 5
                  ? "SEX"
                  : item == 6
                  ? "SAB"
                  : ""
              );

              return true;
            });

            itemConvertido.texto = arrNomear.toString();
          } else {
            itemConvertido.texto = itemConvertido.valores.toString();
          }
        }
      }

      return itemConvertido;
    },
    novoItem(cron) {
      //* Converte o item
      var item = { cron: cron };

      //* Define as propriedades
      item.segundos = [];
      item.minutos = [];
      item.horas = [];
      item.dias = [];
      item.meses = [];
      item.semana = [];
      item.completo = [];

      //* Tipo
      item.tipoSegundos = "fixo";
      item.tipoMinutos = "fixo";
      item.tipoHoras = "fixo";
      item.tipoDias = "fixo";
      item.tipoMeses = "fixo";
      item.tipoSemana = "fixo";
      item.tipoCompleto = "fixo";

      //* Texto
      item.textoSegundos = "A qualquer";
      item.textoMinutos = "A qualquer";
      item.textoHoras = "A qualquer";
      item.textoDias = "A qualquer";
      item.textoMeses = "A qualquer";
      item.textoCompleto = "Padrão";

      //* Desmembra o cron
      var arrCron = item.cron.split(" ");

      //* Converte
      this.converter(item, "segundos", arrCron[0]);
      this.converter(item, "minutos", arrCron[1]);
      this.converter(item, "horas", arrCron[2]);
      this.converter(item, "dias", arrCron[3]);
      this.converter(item, "meses", arrCron[4]);
      this.converter(item, "semana", arrCron[5]);
      this.converter(item, "completo", arrCron.length >= 7 ? arrCron[6] : "P");

      //* Insere o item
      this.itens.push(item);

      return item;
    },
    converter(item, unidade, valor) {
      //* Converte os itens para serem apresentados na tela
      var converterItem;

      if (unidade == "segundos") {
        //* Converte o item
        converterItem = this.converterItem(valor);
        item.cronSegundos = valor;
        item.tipoSegundos = converterItem.tipo;
        item.segundos = converterItem.valores;
        item.textoSegundos = converterItem.texto;
      }

      if (unidade == "minutos") {
        //* Converte o item
        converterItem = this.converterItem(valor);
        item.cronMinutos = valor;
        item.tipoMinutos = converterItem.tipo;
        item.minutos = converterItem.valores;
        item.textoMinutos = converterItem.texto;
      }

      if (unidade == "horas") {
        //* Converte o item
        converterItem = this.converterItem(valor);
        item.cronHoras = valor;
        item.tipoHoras = converterItem.tipo;
        item.horas = converterItem.valores;
        item.textoHoras = converterItem.texto;
      }

      if (unidade == "dias") {
        //* Converte o item
        converterItem = this.converterItem(valor);
        item.cronDias = valor;
        item.tipoDias = converterItem.tipo;
        item.dias = converterItem.valores;
        item.textoDias = converterItem.texto;
      }

      if (unidade == "meses") {
        //* Converte o item
        converterItem = this.converterItem(valor);
        item.cronMeses = valor;
        item.tipoMeses = converterItem.tipo;
        item.meses = converterItem.valores;
        item.textoMeses = converterItem.texto;
      }

      if (unidade == "semana") {
        //* Converte o item
        converterItem = this.converterItem(valor, true);
        item.cronSemana = valor;
        item.tipoSemana = converterItem.tipo;
        item.semana = converterItem.valores;
        item.textoSemana = converterItem.texto;
      }

      if (unidade == "completo") {
        //* Converte o item
        converterItem = this.converterItem(valor, false, true);
        item.cronCompleto = valor;
        item.tipoCompleto = converterItem.tipo;
        item.completo = converterItem.valores;
        item.textoCompleto = converterItem.texto;
      }

      //* Define o cron
      item.cron = item.cronSegundos.concat(
        " ",
        item.cronMinutos,
        " ",
        item.cronHoras,
        " ",
        item.cronDias,
        " ",
        item.cronMeses,
        " ",
        item.cronSemana,
        " ",
        item.cronCompleto
      );
    },
    async trocarAba(novaAba) {
      this.aba = novaAba;
    },
    editarGatilho(cron, tipo, indice) {
      //* Define os dados de edicao
      this.$refs.backupTriggerEditDialog.indice = indice;
      this.$refs.backupTriggerEditDialog.tipo = tipo;
      this.$refs.backupTriggerEditDialog.valor = cron;

      //* Define os itens e valores
      this.$refs.backupTriggerEditDialog.carregarValor();

      //* Fecha a janela principal
      this.$refs.dialogBackupTrigger.fechar();

      //* Abre a dialog de edicao
      this.$refs.backupTriggerEditDialog.abrir();
    },
    remover(indice) {
      this.itens.splice(indice, 1);
    },
    definirGatilho() {
      this.converter(
        this.itens[this.$refs.backupTriggerEditDialog.indice],
        this.$refs.backupTriggerEditDialog.tipo,
        this.$refs.backupTriggerEditDialog.valor
      );
    },
  },
};
</script>

<style>
</style>
