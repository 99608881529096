<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <div v-if="carregando" style="margin-bottom: 30px; margin-top: 30px">
        <button type="button" class="btn btn-sm btn-warning">
          Carregando <img src="" style="" />
        </button>
      </div>
      <Dialog
        :id="'dialogFormBackup'"
        ref="dialogFormBackup"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div class="d-flex justify-content-end">
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <rect
                  opacity="0.5"
                  x="6"
                  y="17.3137"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(-45 6 17.3137)"
                  fill="currentColor"
                ></rect>
                <rect
                  x="7.41422"
                  y="6"
                  width="16"
                  height="2"
                  rx="1"
                  transform="rotate(45 7.41422 6)"
                  fill="currentColor"
                ></rect>
              </svg>
            </span>
          </div>
        </div>

        <div>
          <div v-if="item._id == ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Nova Tarefa</h2>
          </div>

          <div v-if="item._id !== ''" class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Editando Tarefa</h2>
          </div>

          <div class="fv-row mb-7">
            <div
              v-if="mensagem !== '' && view == 'modal'"
              class="alert alert-success alert-dismissible fade show"
              role="alert"
              style="margin-bottom: 10px"
            >
              <strong>{{ mensagem }}</strong>
            </div>
          </div>

          <div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Nome Tarefa</label>

              <input
                v-model="item.nome"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.nome.status }"
                type="text"
                @keyup="enterSalvar"
                autofocus="true"
                ref="nome"
                autocomplete="off"
              />
              <div v-if="itemErros.nome.status" class="invalid-feedback">
                {{ itemErros.nome.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-7">
              <div class="mb-2">
                <span class="fs-6 fw-bold">Pasta da Esquerda</span>
              </div>

              <div class="">
                <div
                  class="d-flex mb-1"
                  v-for="(pastaEsquerda, pastaEsquerdaIndex) in item.diretorio1"
                  :key="pastaEsquerda"
                >
                  <input
                    class="form-control form-control-solid me-2"
                    :class="{ 'is-invalid': itemErros.diretorio1.status }"
                    type="text"
                    @keyup="enterSalvar"
                    autocomplete="off"
                    placeholder="Digitar caminho da Pasta"
                    @blur="
                      onChangeDiretorioLocal($event.target, pastaEsquerdaIndex)
                    "
                    :value="pastaEsquerda"
                    :readonly="$funcoes.appTipo() == 'desktop'"
                  />
                  <button
                    type="button"
                    class="btn btn-icon btn-secondary"
                    v-if="$funcoes.appTipo() == 'desktop'"
                    @click="selecionarDiretorio('esquerda', pastaEsquerdaIndex)"
                  >
                    <i class="bi bi-folder fs-4 mx-2 py-1"></i>
                  </button>
                  <button
                    type="button"
                    class="ms-1 btn btn-icon btn-secondary"
                    @click="removerPasta(pastaEsquerdaIndex)"
                    v-if="item.diretorio1Tipo == 'local'"
                  >
                    <i class="bi bi-trash fs-4 mx-2 py-1"></i>
                  </button>
                </div>
              </div>

              <div class="mt-2">
                <span class="badge badge-info">{{
                  item.diretorio1Tipo == "remoto"
                    ? item.diretorio1Usuario
                    : "Local"
                }}</span>
                <span
                  class="badge badge-secondary ms-1"
                  style="cursor: pointer"
                  @click="
                    selecionarDiretorio(
                      'esquerda',
                      null,
                      'selecionar-diretorio-multiplo'
                    )
                  "
                  v-if="
                    item.diretorio1Tipo == 'local' &&
                    $funcoes.appTipo() == 'desktop'
                  "
                  >Selecionar Pastas</span
                >
                <span
                  class="badge badge-secondary ms-1"
                  style="cursor: pointer"
                  @click="item.diretorio1.push('')"
                  v-if="item.diretorio1Tipo == 'local'"
                  >+1 Pasta</span
                >
              </div>

              <div v-if="itemErros.diretorio1.status" class="invalid-feedback">
                {{ itemErros.diretorio1.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-7">
              <div class="mb-2">
                <span class="fs-6 fw-bold">Pasta da Direita</span>
              </div>

              <div class="d-flex">
                <input
                  v-model="item.diretorio2"
                  class="form-control form-control-solid me-2"
                  :class="{ 'is-invalid': itemErros.diretorio2.status }"
                  type="text"
                  @keyup="enterSalvar"
                  autocomplete="off"
                  placeholder="Digitar caminho da Pasta"
                  readonly
                />
                <button
                  type="button"
                  class="btn btn-icon btn-secondary"
                  @click="selecionarDiretorio('direita')"
                >
                  <i class="bi bi-folder fs-4 mx-2 py-1"></i>
                </button>
              </div>
              <div class="mt-2">
                <span class="badge badge-info">{{
                  item.diretorio2Tipo == "remoto"
                    ? item.diretorio2Usuario
                    : "Local"
                }}</span>
              </div>
              <div v-if="itemErros.diretorio2.status" class="invalid-feedback">
                {{ itemErros.diretorio2.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Direção</label>
              <div class="form-check form-check-custom form-check-solid mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  :checked="item.direcao == 1"
                  @click="item._id == '' ? item.direcao = 1 : false"
                  :disabled="item._id !== ''"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="status"
                  @click="item._id == '' ? item.direcao = 1 : false"
                  >Da Esquerda para Direita</label
                >
              </div>

              <div class="form-check form-check-custom form-check-solid mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  :checked="item.direcao == 2"
                  @click="item._id == '' ? item.direcao = 2 : false"
                  :disabled="item._id !== ''"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="status"
                  @click="item._id == '' ? item.direcao = 2 : false"
                  >Da Direita para Esquerda</label
                >
              </div>

              <div class="form-check form-check-custom form-check-solid">
                <input
                  class="form-check-input"
                  type="radio"
                  :checked="item.direcao == 3"
                  @click="item._id == '' ? item.direcao = 3 : false"
                  :disabled="item._id !== ''"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="status"
                  @click="item._id == '' ? item.direcao = 3 : false"
                  >Sincronismo Ambos Lados</label
                >
              </div>
            </div>
            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Criptografia</label>

              <div class="form-check form-check-custom form-check-solid mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  :checked="item.criptografia == 0"
                  @click="item.criptografia = 0"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="status"
                  @click="item.criptografia = 0"
                  >Sem criptografia</label
                >
              </div>

              <div class="form-check form-check-custom form-check-solid mb-2">
                <input
                  class="form-check-input"
                  type="radio"
                  :checked="item.criptografia == 1"
                  @click="item.criptografia = 1"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="status"
                  @click="item.criptografia = 1"
                  >Com criptografia</label
                >
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Filtrar arquivos</label>
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <label class="fs-6 fw-bold mb-2 text-gray-700">Incluir</label>

                  <input
                    v-model="item.filtroArquivoIncluir"
                    class="form-control form-control-solid"
                    :class="{
                      'is-invalid': itemErros.filtroArquivoIncluir.status,
                    }"
                    type="text"
                    @keyup="enterSalvar"
                    autocomplete="off"
                  />
                  <div
                    v-if="itemErros.filtroArquivoIncluir.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.filtroArquivoIncluir.mensagem }}
                  </div>
                </div>
                <div class="col-lg-6 fv-row">
                  <label class="fs-6 fw-bold mb-2 text-gray-700">Ignorar</label>

                  <input
                    v-model="item.filtroArquivoIgnorar"
                    class="form-control form-control-solid"
                    :class="{
                      'is-invalid': itemErros.filtroArquivoIgnorar.status,
                    }"
                    type="text"
                    @keyup="enterSalvar"
                    autocomplete="off"
                  />
                  <div
                    v-if="itemErros.filtroArquivoIgnorar.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.filtroArquivoIgnorar.mensagem }}
                  </div>
                </div>
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2">Filtrar diretórios</label>
              <div class="row">
                <div class="col-lg-6 fv-row">
                  <label class="fs-6 fw-bold mb-2 text-gray-700">Incluir</label>

                  <input
                    v-model="item.filtroDiretorioIncluir"
                    class="form-control form-control-solid"
                    :class="{
                      'is-invalid': itemErros.filtroDiretorioIncluir.status,
                    }"
                    type="text"
                    @keyup="enterSalvar"
                    autocomplete="off"
                  />
                  <div
                    v-if="itemErros.filtroDiretorioIncluir.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.filtroDiretorioIncluir.mensagem }}
                  </div>
                </div>
                <div class="col-lg-6 fv-row">
                  <label class="fs-6 fw-bold mb-2 text-gray-700">Ignorar</label>

                  <input
                    v-model="item.filtroDiretorioIgnorar"
                    class="form-control form-control-solid"
                    :class="{
                      'is-invalid': itemErros.filtroDiretorioIgnorar.status,
                    }"
                    type="text"
                    @keyup="enterSalvar"
                    autocomplete="off"
                  />
                  <div
                    v-if="itemErros.filtroDiretorioIgnorar.status"
                    class="invalid-feedback"
                  >
                    {{ itemErros.filtroDiretorioIgnorar.mensagem }}
                  </div>
                </div>
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-2"
                >Enviar informações por e-mail</label
              >

              <input
                v-model="item.email"
                class="form-control form-control-solid"
                :class="{ 'is-invalid': itemErros.email.status }"
                type="text"
                @keyup="enterSalvar"
                autocomplete="off"
                placeholder="contato@exemplo.com"
              />
              <div v-if="itemErros.email.status" class="invalid-feedback">
                {{ itemErros.email.mensagem }}
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="fs-6 fw-bold mb-3">Opções</label>

              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.status"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="status"
                  @click="item.status = !item.status"
                  >Ativo</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.flagProcessarAoIniciar"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="flagProcessarAoIniciar"
                  @click="
                    item.flagProcessarAoIniciar = !item.flagProcessarAoIniciar
                  "
                  >Processar ao iniciar</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.incluirSubDiretorio"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="incluirSubDiretorio"
                  @click="item.incluirSubDiretorio = !item.incluirSubDiretorio"
                  >Incluir subpastas</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.flagRemoverArquivos"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="flagRemoverArquivos"
                  @click="item.flagRemoverArquivos = !item.flagRemoverArquivos"
                  >Remover Arquivos Não Existente</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.flagCaminhoCompleto"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="flagCaminhoCompleto"
                  @click="item.flagCaminhoCompleto = !item.flagCaminhoCompleto"
                  >Caminho Completo</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.flagCopiarUpload"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="flagCopiarUpload"
                  @click="item.flagCopiarUpload = !item.flagCopiarUpload"
                  >Copiar Ao Enviar</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.flagSalvarLog"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="flagSalvarLog"
                  @click="item.flagSalvarLog = !item.flagSalvarLog"
                  >Salvar Relatório</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3 ms-10"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.flagSalvarLogVazio"
                  :disabled="!item.flagSalvarLog"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="flagSalvarLogVazio"
                  :disabled="!item.flagSalvarLog"
                  @click="
                    item.flagSalvarLog
                      ? (item.flagSalvarLogVazio = !item.flagSalvarLogVazio)
                      : false
                  "
                  >Salvar Relatório Vazio</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.flagVerificacaoCompleta"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="flagVerificacaoCompleta"
                  @click="
                    item.flagVerificacaoCompleta = !item.flagVerificacaoCompleta
                  "
                  >Verificação Completa</label
                >
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid mb-3"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="item.substituir"
                  checked="checked"
                />
                <label
                  class="form-check-label fw-bold ms-3"
                  for="substituir"
                  @click="item.substituir = !item.substituir"
                  >Substituir se mais novo</label
                >
              </div>
            </div>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button
                v-if="view == 'modal'"
                type="button"
                class="btn btn-outline-primary"
                style="margin-right: 5px"
                @click="fechar()"
              >
                Fechar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="salvarItem()"
              >
                Salvar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>

    <DiretorioSelecionarDialog
      ref="diretorioSelecionarDialog"
      @dialog-off="abrir()"
      @selecionar="alterarDiretorio"
      @selecionar-multiplo="alterarDiretorioMultiplo"
    ></DiretorioSelecionarDialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreArvore } from "../stores/storeArvore.js";

import Dialog from "../components/ComponentDialog.vue";
import DiretorioSelecionarDialog from "./DiretorioSelecionarDialog.vue";

export default {
  components: { Dialog, DiretorioSelecionarDialog },
  props: { view: { type: String, default: "modal" } },

  setup() {
    const storeAuth = useAuthStore();
    const storeArvore = useStoreArvore();

    return { storeAuth, storeArvore };
  },

  data() {
    return {
      item: {
        _id: "",
        nome: "",
        diretorio1: [],
        diretorio1Tipo: "",
        diretorio1Nuvem: "",
        diretorio1Token: "",
        diretorio1Usuario: "",
        diretorio1Id: "",
        diretorio2: "",
        diretorio2Tipo: "",
        diretorio2Nuvem: "",
        diretorio2Token: "",
        diretorio2Usuario: "",
        diretorio2Id: "",
        direcao: 1,
        criptografia: 0,
        filtroArquivoIncluir: "",
        filtroArquivoIgnorar: "",
        filtroDiretorioIncluir: "",
        filtroDiretorioIgnorar: "",
        email: "",
        status: true,
        incluirSubDiretorio: true,
        flagProcessarAoIniciar: true,
        flagRemoverArquivos: false,
        flagCaminhoCompleto: true,
        flagCopiarUpload: true,
        flagSalvarLog: true,
        flagSalvarLogVazio: false,
        flagVerificacaoCompleta: true,
        substituir: true,
      },
      diretorioEditando: "esquerda",
      diretorioIndexEditando: 0,
      itemErros: {
        nome: { status: false, mensagem: "" },
        diretorio1: { status: false, mensagem: "" },
        diretorio2: { status: false, mensagem: "" },
        filtroArquivoIncluir: { status: false, mensagem: "" },
        filtroArquivoIgnorar: { status: false, mensagem: "" },
        filtroDiretorioIncluir: { status: false, mensagem: "" },
        filtroDiretorioIgnorar: { status: false, mensagem: "" },
        flagVerificacaoCompleta: { status: false, mensagem: "" },
        email: { status: false, mensagem: "" },
      },
      mensagem: "",
      carregando: false,
    };
  },

  mounted() {},

  methods: {
    async salvarItem() {
      this.mensagem = "";
      this.itemErros = api.errosLimpar(this.itemErros);

      let apiRequest = null;

      const body = this.item;

      if (this.item._id === "") {
        apiRequest = await api.post(
          this.storeAuth.apiNuvem() + "/backup",
          body
        );
      } else {
        apiRequest = await api.put(
          this.storeAuth.apiNuvem() + "/backup/" + this.item._id,
          body
        );
      }

      if (apiRequest.data.erro) {
        api.errosProcessar(this.itemErros, apiRequest.data.erros);
        return;
      } else if (this.item._id === "") {
        this.carregarItem(apiRequest.data._id);
      }

      this.$emit("save-item");

      this.fechar();
    },
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogFormBackup.abrir();
      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["nome"].focus();
      }, 500);
    },
    fechar() {
      this.$refs.dialogFormBackup.fechar();
      this.$emit("dialog-off");
    },

    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.salvarItem();
      }
    },
    async novo() {
      this.mensagem = "";

      this.item._id = "";
      this.item.nome = "";
      this.item.diretorio1 = [""];
      this.item.diretorio1Tipo = "local";
      this.item.diretorio1Nuvem = "";
      this.item.diretorio1Token = "";
      this.item.diretorio1Usuario = "";
      this.item.diretorio1Id = "";
      this.item.diretorio2 = "";
      this.item.diretorio2Tipo = "remoto";
      this.item.diretorio2Nuvem = "";
      this.item.diretorio2Token = "";
      this.item.diretorio2Usuario = "";
      this.item.diretorio2Id = "";
      this.item.direcao = 1;
      this.item.criptografia = 0;
      this.item.filtroArquivoIncluir = "";
      this.item.filtroArquivoIgnorar = "";
      this.item.filtroDiretorioIncluir = "";
      this.item.filtroDiretorioIgnorar = "";
      this.item.email = "";
      this.item.status = true;
      this.item.incluirSubDiretorio = true;
      this.item.flagProcessarAoIniciar = true;
      this.item.flagRemoverArquivos = false;
      this.item.flagCaminhoCompleto = false;
      this.item.flagCopiarUpload = false;
      this.item.flagSalvarLog = true;
      this.item.flagSalvarLogVazio = true;
      this.item.flagVerificacaoCompleta = true;
      this.item.substituir = true;

      //* Define os dados do usuario remoto
      this.item.diretorio2Usuario =
        this.storeAuth?.sessao.usuario.nomeUsuario +
        "@" +
        this.storeAuth?.sessao.usuario.nuvem.alias;
      this.item.diretorio2Token = this.storeAuth?.sessao.key;
      this.item.diretorio2Nuvem = this.storeAuth?.sessao.usuario.nuvem._id;

      this.itemErros = api.errosLimpar(this.itemErros);
    },
    async carregarItem(id) {
      //* Limpa os campos
      await this.novo();

      //* Limpa o objeto
      //* Carrega os dados da api
      const response = await api.get(
        this.storeAuth.apiNuvem() + "/backup/" + id
      );

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemEdit = response.data.itens[0];

        this.item._id = itemEdit._id;
        this.item.nome = itemEdit.nome;
        this.item.diretorio1 = itemEdit.diretorio1;
        this.item.diretorio1Tipo = itemEdit.diretorio1Tipo;
        this.item.diretorio2 = itemEdit.diretorio2;
        this.item.diretorio2Tipo = itemEdit.diretorio2Tipo;
        this.item.direcao = itemEdit.direcao ? itemEdit.direcao : 1;
        this.item.criptografia = itemEdit.criptografia
          ? itemEdit.criptografia
          : 0;
        this.item.filtroArquivoIncluir = itemEdit.filtroArquivoIncluir;
        this.item.filtroArquivoIgnorar = itemEdit.filtroArquivoIgnorar;
        this.item.filtroDiretorioIncluir = itemEdit.filtroDiretorioIncluir;
        this.item.filtroDiretorioIgnorar = itemEdit.filtroDiretorioIgnorar;
        this.item.email = itemEdit.email;
        this.item.status = itemEdit.status ? itemEdit.status : false;
        this.item.incluirSubDiretorio = itemEdit.incluirSubDiretorio
          ? itemEdit.incluirSubDiretorio
          : false;
        this.item.flagProcessarAoIniciar = itemEdit.flagProcessarAoIniciar
          ? itemEdit.flagProcessarAoIniciar
          : false;
        this.item.flagRemoverArquivos = itemEdit.flagRemoverArquivos
          ? itemEdit.flagRemoverArquivos
          : false;
        this.item.flagCaminhoCompleto = itemEdit.flagCaminhoCompleto
          ? itemEdit.flagCaminhoCompleto
          : false;
        this.item.flagCopiarUpload = itemEdit.flagCopiarUpload
          ? itemEdit.flagCopiarUpload
          : false;
        this.item.flagSalvarLog = itemEdit.flagSalvarLog
          ? itemEdit.flagSalvarLog
          : false;
        this.item.flagSalvarLogVazio = itemEdit.flagSalvarLogVazio
          ? itemEdit.flagSalvarLogVazio
          : false;
        this.item.flagVerificacaoCompleta = itemEdit.flagVerificacaoCompleta
          ? itemEdit.flagVerificacaoCompleta
          : false;
        this.item.substituir = itemEdit.substituir
          ? itemEdit.substituir
          : false;

        if (itemEdit.diretorio2Tipo == "remoto") {
          this.diretorioEditando = "direita";
          this.diretorioIndexEditando = 0;
          this.alterarDiretorio({
            caminho: itemEdit.diretorio2,
            tipo: itemEdit.diretorio2Tipo,
            id:
              itemEdit.diretorio2Id && itemEdit.diretorio2Id._id
                ? itemEdit.diretorio2Id._id
                : "",
            nuvem: {
              _id: itemEdit.diretorio2Nuvem._id,
              alias: itemEdit.diretorio2Nuvem.alias,
              usuarioNuvem: itemEdit.diretorio2Nuvem.usuarioNuvem,
            },
          });
        }
      }
    },
    selecionarDiretorio(pasta, index = 0, funcao = "selecionar-diretorio") {
      this.diretorioEditando = pasta;
      this.diretorioIndexEditando = index;

      //* Define a funcao
      this.$refs.diretorioSelecionarDialog.funcao = funcao;

      if (pasta == "esquerda") {
        //* Posiciona a janela no diretorio atual
        this.$refs.diretorioSelecionarDialog.diretorioAtual(
          this.item.diretorio1Tipo,
          this.item.diretorio1[index] == "" && process.platform !== "win32"
            ? "/"
            : this.item.diretorio1[index],
          this.item.diretorio1Id,
          this.item.diretorio1Nuvem,
          this.item.diretorio1Token
        );
      } else if (pasta == "direita") {
        //* Posiciona a janela no diretorio atual
        this.$refs.diretorioSelecionarDialog.diretorioAtual(
          this.item.diretorio2Tipo,
          this.item.diretorio2 == "" ? "/" : this.item.diretorio2,
          this.item.diretorio2Id,
          this.item.diretorio2Nuvem,
          this.item.diretorio2Token
        );
      }

      this.$refs.dialogFormBackup.fechar();
      this.$refs.diretorioSelecionarDialog.abrir();
    },
    alterarDiretorio(selecao) {
      if (this.diretorioEditando == "esquerda") {
        this.item.diretorio1[this.diretorioIndexEditando] = selecao.caminho;
        this.item.diretorio1Tipo = selecao.tipo;
        this.item.diretorio1Id = selecao.tipo == "remoto" ? selecao.id : "";
        this.item.diretorio1Token = selecao.token;
        this.item.diretorio1Nuvem =
          selecao.tipo == "remoto" ? selecao.nuvem._id : "";
        this.item.diretorio1Usuario =
          selecao.tipo == "remoto"
            ? selecao.nuvem.usuarioNuvem.nomeUsuario + "@" + selecao.nuvem.alias
            : "";
      } else if (this.diretorioEditando == "direita") {
        this.item.diretorio2 = selecao.caminho;
        this.item.diretorio2Tipo = selecao.tipo;
        this.item.diretorio2Id = selecao.tipo == "remoto" ? selecao.id : "";
        this.item.diretorio2Token = selecao.token;
        this.item.diretorio2Nuvem =
          selecao.tipo == "remoto" ? selecao.nuvem._id : "";
        this.item.diretorio2Usuario =
          selecao.tipo == "remoto"
            ? selecao.nuvem.usuarioNuvem.nomeUsuario + "@" + selecao.nuvem.alias
            : "";
      }
    },
    alterarDiretorioMultiplo() {
      //* Carrega os indices dos diretorios atual
      //var indiceInicial = this.item.diretorio1.length;

      //* Carrega as keys dos diretorios selecionados
      var keysDiretoriosSelecionados = Object.keys(
        this.storeArvore.instancias["selecionarDiretorio"].selecaoMultipla
      );

      //* Percorre todos os itens
      for (
        var indiceDiretorioSelecionado = 0;
        indiceDiretorioSelecionado < keysDiretoriosSelecionados.length;
        indiceDiretorioSelecionado++
      ) {
        //* Insere o diretorio na lista
        this.item.diretorio1.push(
          keysDiretoriosSelecionados[indiceDiretorioSelecionado]
        );
      }

      //* Limpa a selecao atual
      this.storeArvore.instancias["selecionarDiretorio"].selecaoMultipla = {};
    },
    removerPasta(pastaIndex) {
      //* Verifica se pode ser removida a pasta
      if (this.item.diretorio1.length > 1) {
        //* Remove o item
        this.item.diretorio1.splice(pastaIndex, 1);
      }
    },
    onChangeDiretorioLocal(target, index) {
      if (this.$funcoes.appTipo() == "web") {
        this.item.diretorio1[index] = target.value;

        setTimeout(() => {
          target.focus();
          target.select();
        }, 10);
      }
    },
  },
};
</script>

<style></style>
