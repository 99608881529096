<template>
  <!--begin::Main-->
  <!--begin::Root-->

  <!--begin::Page-->
  <div class="page d-flex flex-row flex-column-fluid">
    <ASide v-if="false" />

    <!--begin::Wrapper-->
    <div
      class="wrapper d-flex flex-column flex-row-fluid"
      :style="{'padding-top':rotaLogin ? '0px' : '65px'}"
    >
      <Header v-if="true" />

      <!--begin::Content-->
      <div
        class="content d-flex flex-column flex-column-fluid"
        style="padding-top: 30px; padding-bottom: 15px"
      >
        <Toolbar1 v-if="false" />

        <!--begin::Post-->
        <div class="post d-flex flex-column-fluid">
          <div class="container-fluid">
            <slot name="content"></slot>
          </div>
        </div>
        <!--end::Post-->
      </div>
      <!--end::Content-->

      <Footer v-if="mostrar" />
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Page-->

  <!--end::Root-->
  <!--begin::Drawers-->
</template>

<script>
import ASide from "./ASide.vue";
import Header from "./LayoutHeader.vue";
import Footer from "./LayoutFooter.vue";
import Toolbar1 from "./LayoutToolbar1.vue";
import { useRouter } from "vue-router";

export default {
  components: { ASide, Header, Footer, Toolbar1 },

  props: {},

  data() {
    return { mostrar: false, rotaLogin:false };
  },

  async mounted() {
    const router = useRouter();

    await router.isReady();

    if (
      this.$route.name == "loginAdmin" ||
      this.$route.name == "loginApp" ||
      this.$route.name == "homeApp" ||
      this.$route.name == "backupsApp" ||
      this.$route.name == "backupEditApp" ||
      this.$route.name == "monitorApp" ||
      this.$route.name == "configuracaoApp"
    ) {
      this.mostrar = false;
    } else {
      this.mostrar = false;
    }

    if (
      this.$route.name == "loginAdmin" || this.$route.name == "loginApp" || this.$route.name == "loginSite" || this.$route.name == "downloadApp")
    {
      this.rotaLogin = true
    }

  },

  methods: {},
};
</script>

<style>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
