import { createRouter, createWebHistory } from "vue-router";

//import Vue from "vue";
//import VueRouter from "vue-router";

import Login from "../views/ViewLogin.vue";

import HomeApp from "../views/app/HomeApp.vue";
import FilaApp from "../views/app/FilaApp.vue";
import BackupsApp from "../views/app/BackupsApp.vue";
import BackupEditApp from "../views/app/BackupEditApp.vue";
import MonitorApp from "../views/app/MonitorApp.vue";
import DownloadStatus from "../../../_shared/features/DownloadStatus";

import { useAuthStore } from "@/stores/auth.js";

//Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: { name: "homeApp" }
	},
	{
		path: "/app",
		redirect: { name: "homeApp" }
	},
	{
		path: "/app/login",
		name: "loginApp",
		component: Login
	},
	{
		path: "/app/home",
		name: "homeApp",
		component: HomeApp
	},
	{
		path: "/app/fila",
		name: "filaApp",
		component: FilaApp
	},
	{
		path: "/app/backup",
		name: "backupsApp",
		component: BackupsApp
	},
	{
		path: "/app/backup-edit",
		name: "backupEditApp",
		component: BackupEditApp
	},
	{
		path: "/app/monitor",
		name: "monitorApp",
		component: MonitorApp
	},
	{
		path: "/app/download/:instancia/:nuvem/:key",
		name: "downloadApp",
		component: DownloadStatus
	}
];

const router = createRouter({ history: createWebHistory(), routes });

router.beforeEach(async (to) => {
	const publicPages = ["/admin/login", "/app/login", "/site/login", "/app/download"];
	let authRequired = !publicPages.includes(to.path);

	//* Verifica se o path eh de download
	if (to.path.startsWith("/app/download")) {
		authRequired = false;
	}

	const auth = useAuthStore();

	if (authRequired && !auth.sessao) {
		auth.returnUrl = to.fullPath;
		return "/app/login";
	}
});

export default router;

/*

 {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about"  "../views/AboutView.vue"),
    },

*/
