<template>
  <div
    class="timeline-item align-items-center mb-4"
    :class="{ 'ms-10': view == 'completa' }"
    v-if="
      view !== 'completa' ||
      diretorio.lazy == false ||
      storeUpload.arquivos[arquivo].status == 'pausado' ||
      (storeUpload.arquivos[arquivo].status == 'transferindo' &&
        storeUpload.arquivos[arquivo].file.size > 1496001)
    "
  >
    <!--begin::Timeline icon-->
    <div
      class="timeline-icon"
      :style="{ 'margin-left': view == 'completa' ? '11px' : '0px' }"
    >
      <!--begin::Svg Icon | path: icons/duotune/general/gen015.svg-->
      <span
        class="svg-icon svg-icon-2"
        :class="{
          'svg-icon-primary': storeUpload.arquivos[arquivo].status !== 'erro',
          'svg-icon-danger': storeUpload.arquivos[arquivo].status == 'erro',
        }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-file-earmark"
          viewBox="0 0 16 16"
        >
          <path
            d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
          />
        </svg>
      </span>
      <!--end::Svg Icon-->
    </div>
    <!--end::Timeline icon-->
    <!--begin::Timeline content-->
    <div class="timeline-content m-0 mt-1 text-start">
      <div class="d-flex justify-content-start">
        <div class="flex-grow-1">
          <!--begin::Title-->
          <div class="d-flex justify-content-between">
            <span class="fs-8 fw-bold text-gray-800"
              >{{ storeUpload.arquivos[arquivo].fileName }}
            </span>
            <span class="fs-8 fw-bold text-gray-800">
              <small
                ><span class="text-dark"
                  ><small
                    >{{
                      storeUpload.arquivos[arquivo].file &&
                      storeUpload.arquivos[arquivo].file.size &&
                      storeUpload.arquivos[arquivo].file.size > 0
                        ? $funcoes.tamanhoTexto(
                            storeUpload.arquivos[arquivo].file.size
                          )
                        : ""
                    }}
                    <span
                      v-if="
                        storeUpload.arquivos[arquivo].progresso < 100 && false
                      "
                    >
                      -
                      {{
                        storeUpload.arquivos[arquivo].progressoVelocidade >
                        1000000
                          ? (
                              storeUpload.arquivos[arquivo]
                                .progressoVelocidade /
                              1024 /
                              1000
                            ).toFixed(2) + " mbps"
                          : (
                              storeUpload.arquivos[arquivo]
                                .progressoVelocidade / 1024
                            ).toFixed(2) + " kbps"
                      }}
                      -
                    </span>
                    <span v-if="storeUpload.arquivos[arquivo].progresso < 100">
                      -
                      {{
                        storeUpload.arquivos[arquivo].progressoBytes > 0
                          ? $funcoes.tamanhoTexto(
                              storeUpload.arquivos[arquivo].progressoBytes
                            )
                          : ""
                      }}
                    </span>
                  </small></span
                ><span v-if="storeUpload.fila[fila].local">
                  - {{ storeUpload.arquivos[arquivo].progresso }}%</span
                ></small
              >
            </span>
          </div>
          <!--end::Title-->
          <div
            class="progress mt-1"
            style="height: 5px"
            :class="{
              'justify-content-end2':
                storeUpload.arquivos[arquivo].tipo == 'download',
            }"
          >
            <div
              role="progressbar"
              :aria-valuenow="storeUpload.arquivos[arquivo].progresso"
              aria-valuemin="0"
              aria-valuemax="100"
              class="progress-bar"
              :class="{
                'bg-success':
                  storeUpload.arquivos[arquivo].status !== 'erro' &&
                  storeUpload.arquivos[arquivo].progresso >= 100,
                'bg-warning':
                  storeUpload.arquivos[arquivo].status !== 'erro' &&
                  storeUpload.arquivos[arquivo].progresso < 100,
                'bg-danger': storeUpload.arquivos[arquivo].status == 'erro',
              }"
              :style="{
                width: storeUpload.arquivos[arquivo].progresso + '%',
              }"
            ></div>
          </div>
          <!--begin::Title-->
          <div class="d-flex justify-content-between">
            <span class="fs-8 text-gray-400 fw-semibold d-block mt-1">
              <small
                class="text-gray-600"
                v-if="
                  storeUpload.arquivos[arquivo].status == 'fila' ||
                  storeUpload.arquivos[arquivo].status == 'processando' ||
                  storeUpload.arquivos[arquivo].status == 'pausado' ||
                  storeUpload.arquivos[arquivo].status == 'erro'
                "
              >
                {{ storeUpload.arquivos[arquivo].status }}
                {{
                  storeUpload.arquivos[arquivo].status == "erro"
                    ? "- " + storeUpload.arquivos[arquivo].erroTipo
                    : ""
                }}
              </small>
            </span>
            <span class="fs-8 text-gray-400 fw-semibold d-block mt-1"
              ><small class="text-gray-600">{{
                storeUpload.arquivos[arquivo].diretorioDestinoCaminho
              }}</small>
            </span>
          </div>
          <!--end::Title-->
        </div>
        <div
          class="ms-3 mt-2"
          v-if="
            storeUpload.arquivos[arquivo].status !== 'concluido' &&
            (view == 'completa' || view == 'simples')
          "
        >
          <button
            class="btn btn-light me-3 p-2 py-1"
            :disabled="
              storeUpload.arquivos[arquivo].status == 'transferindo' ||
              storeUpload.arquivos[arquivo].progresso >= 100
            "
            @click="iniciarArquivo(storeUpload.arquivos[arquivo].fileId)"
          >
            <span class="svg-icon svg-icon-1x me-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-play"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
                />
              </svg>
            </span>
          </button>
          <button
            type="button"
            class="btn btn-light me-3 p-2 py-1"
            :disabled="
              storeUpload.arquivos[arquivo].progresso >= 100 ||
              storeUpload.arquivos[arquivo].status !== 'transferindo'
            "
            @click="pausarArquivo(storeUpload.arquivos[arquivo].fileId)"
          >
            <span class="svg-icon svg-icon-1x me-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pause-circle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"
                />
              </svg>
            </span>
          </button>
          <button
            type="button"
            class="btn btn-light me-0 p-2 py-1"
            :disabled="
              storeUpload.arquivos[arquivo].status == 'transferindo' ||
              storeUpload.arquivos[arquivo].status == 'processando'
            "
            @click="
              storeUpload.arquivos[arquivo]
                ? removerArquivo(storeUpload.arquivos[arquivo].fileId)
                : removerArquivo('')
            "
          >
            <span class="svg-icon svg-icon-1x me-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                />
                <path
                  fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
    </div>
    <!--end::Timeline content-->
  </div>
</template>

<script>
import { useStoreUpload } from "../stores/storeUpload";

export default {
  props: {
    arquivo: { type: String },
    diretorio: { type: Object, default: () => {} },
    fila: { type: String, default: "" },
    view: { type: String, default: "completa" },
  },
  setup() {
    const storeUpload = useStoreUpload();

    return { storeUpload };
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {
    async pausarArquivo(fileId) {
      this.storeUpload.pausarUploadArquivo(fileId);
    },
    async iniciarArquivo(fileId) {
      this.storeUpload.iniciarTransferenciaArquivo(fileId);
    },
    async removerArquivo(fileId) {
      this.storeUpload.removerArquivo(fileId);
    },
  },
};
</script>
