<template>
  <div>
    <Dialog
      :id="'dialogBackupTriggerEdit'"
      ref="dialogBackupTriggerEdit"
      :hide-header="true"
      :hideFooter="true"
      :modal="true"
      :tamanho="'md'"
      :classModalBody="'mx-1 mx-xl-1 my-1'"
      :titulo="'Editar'"
      @dialog-off="fechar()"
    >
      <div>
        <div>
          <div>
            <h1
              class="fw-bold mb-5 text-center"
              style="text-transform: capitalize"
            >
              {{ tipo }}
            </h1>
          </div>

          <div class="text-center mb-5" v-if="tipo !== 'completo'">
            <button
              class="btn ms-1 p-2 mh-2 mb-1 text-dark"
              :class="{
                'btn-outline btn-outline-dashed btn-outline-warning btn-active-light-warning':
                  funcao !== 'exato',
                'btn-warning': funcao == 'exato',
              }"
              style="min-width: 70px"
              @click="
                funcao = 'exato';
                carregarItens();
              "
            >
              Exato
            </button>
            <button
              class="btn ms-1 p-2 mh-2 mb-1 text-dark"
              :class="{
                'btn-outline btn-outline-dashed btn-outline-warning btn-active-light-warning':
                  funcao !== 'acada',
                'btn-warning': funcao == 'acada',
              }"
              style="min-width: 70px"
              @click="
                funcao = 'acada';
                carregarItens();
              "
            >
              A cada
            </button>
          </div>

          <div class="mx-6 text-center">
            <span v-for="item in itens" :key="item.key">
              <button
                class="btn ms-1 p-2 mh-2 mb-1"
                :class="{
                  'btn-outline btn-outline-dashed btn-outline-info btn-active-light-info':
                    !item.selecionado,
                  'btn-info': item.selecionado,
                }"
                style="min-width: 50px"
                @click="selecionar(item.key)"
              >
                {{ item.texto }}
              </button>
            </span>
          </div>

          <div class="separator separator-dashed my-4"></div>

          <div class="text-center">
            <button
              type="button"
              class="btn btn-secondary me-3"
              @click="fechar()"
            >
              Cancelar
            </button>
            <button type="button" class="btn btn-primary" @click="definir()">
              Definir
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "../components/ComponentDialog.vue";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreArvore } from "../stores/storeArvore.js";

export default {
  components: { Dialog },
  props: {},

  setup() {
    const storeAuth = useAuthStore();
    const storeArvore = useStoreArvore();

    return { storeAuth, storeArvore };
  },

  data() {
    return {
      itens: {},
      indice: null,
      funcao: "exato",
      tipo: "meses",
      valor: "12,11,5",
    };
  },

  mounted() {
    this.carregarValor();
  },

  methods: {
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogBackupTriggerEdit.abrir();
    },
    fechar() {
      this.$refs.dialogBackupTriggerEdit.fechar();
      this.$emit("dialog-off");
    },
    carregarItens() {
      //* Limpa os itens
      this.itens = {};

      if (this.tipo == "segundos" || this.tipo == "minutos") {
        //* Insere segundos, minutos
        for (var key = 0; key < 60; key++) {
          this.itens[key] = {
            key: key,
            texto: key,
            selecionado: false,
          };
        }
      } else if (this.tipo == "horas") {
        //* Insere horas
        for (var key = 0; key < 24; key++) {
          this.itens[key] = {
            key: key,
            texto: key,
            selecionado: false,
          };
        }
      } else if (this.tipo == "dias") {
        //* Insere dias
        for (var key = 1; key <= 31; key++) {
          this.itens[key] = {
            key: key,
            texto: key,
            selecionado: false,
          };
        }
      } else if (this.tipo == "meses") {
        var arrMeses = [
          "JAN",
          "FEV",
          "MAR",
          "ABR",
          "MAI",
          "JUN",
          "JUL",
          "AGO",
          "SET",
          "OUT",
          "NOV",
          "DEZ",
        ];

        //* Insere meses
        for (var key = 1; key <= 12; key++) {
          this.itens[key] = {
            key: key,
            texto: this.funcao == "exato" ? arrMeses[key - 1] : key,
            selecionado: false,
          };
        }
      } else if (this.tipo == "semana") {
        var arrSemana = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];

        //* Insere semana
        for (var key = 0; key <= 6; key++) {
          this.itens[key] = {
            key: key,
            texto: this.funcao == "exato" ? arrSemana[key] : key + 1,
            selecionado: false,
          };
        }
      }else if (this.tipo == "completo") {
        
        this.itens["P"] = {key: "P", texto: "Padrão", selecionado: false};
        this.itens["S"] = {key: "S", texto: "Sim", selecionado: false};
        this.itens["N"] = {key: "N", texto: "Não", selecionado: false};

      }
    },
    carregarValor() {
      //* Verifica se esta marcado todos
      if (this.valor == "*") {
        //* Carrega os itens
        this.carregarItens();
      } else {
        //* Verifica o tipo de funcao
        if (this.valor.indexOf("/") > -1) {
          //* Define a funcao
          this.funcao = "acada";

          //* Carrega os itens
          this.carregarItens();

          //* Define o valor
          var valor = this.valor.split("/")[1];

          //* Seleciona o item
          this.itens[valor].selecionado = true;
        } else {
          //* Define a funcao
          this.funcao = "exato";

          //* Carrega os itens
          this.carregarItens();

          //* Carrega os valores
          var arrValores = this.valor.split(",");

          //* Percorre os valores
          for (
            var indiceValor = 0;
            indiceValor < arrValores.length;
            indiceValor++
          ) {
            //* Marca como selecionado
            this.itens[arrValores[indiceValor]].selecionado = true;
          }
        }
      }
    },
    selecionar(itemKey) {
      //* Verifica se deve remover as selecoes
      if (this.funcao == "acada" || this.tipo == "completo") {
        this.selecionarLimpar();
      }

      //* Define como selecionado
      this.itens[itemKey].selecionado = !this.itens[itemKey].selecionado;
    },
    selecionarLimpar() {
      //* Carrega as keys
      var arrKeys = Object.keys(this.itens);

      //* Percorre os itens
      for (var key = 0; key < arrKeys.length; key++) {
        this.itens[arrKeys[key]].selecionado = false;
      }
    },
    definir() {
      var indiceItem;

      //* Limpa o valor
      this.valor = "*";

      //* Carrega as keys
      var arrItensKeys = Object.keys(this.itens);

      //* Verifica se o indice esta selecionado
      if (this.funcao == "acada") {
        //* Percorre os itens
        for (indiceItem = 0; indiceItem < arrItensKeys.length; indiceItem++) {
          //* Instancia o item
          var item = this.itens[arrItensKeys[indiceItem]];

          //* Verifica se esta selecionado
          if (item.selecionado == true) {
            this.valor = "*/" + item.key;
            break;
          }
        }
      } else if (this.funcao == "exato") {
        //* Percorre os itens
        for (indiceItem = 0; indiceItem < arrItensKeys.length; indiceItem++) {
          //* Instancia o item
          var item = this.itens[arrItensKeys[indiceItem]];

          //* Verifica se esta selecionado
          if (item.selecionado == true) {
            //* Verifica se deve limpar o valor
            if (this.valor == "*") {
              this.valor = "";
            }

            //* Insere o item selecionado
            this.valor = this.valor + (this.valor == "" ? "" : ",") + item.key;
          }
        }
      }

      //* Retorna o item selecionado
      this.$emit("definir", {
        indice: this.indice,
        funcao: this.funcao,
        tipo: this.tipo,
        valor: this.valor,
      });

      //* Fecha a janela
      this.fechar();
    },
  },
};
</script>

<style>
</style>
