<template>
  <div>
    <Dialog
      :id="'dialogView'"
      ref="dialogView"
      :hide-header="true"
      :hideFooter="true"
      :modal="true"
      :tamanho="'xl'"
      :classModalBody="'mx-5 mx-xl-1 my-1'"
      @dialog-off="
        $emit('dialog-off');
        fechar();
      "
    >
      
    {{ url }}

      <template #header>
        <div class="m-9 mb-0">
          <div class="d-flex justify-content-between">
            <div>
              <h1 class="d-flex text-primary fw-bolder fs-2 mt-2" v-if="true">
                Arquivo                
              </h1>
            </div>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="separator m-0 mt-3" v-if="true"></div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import { useAuthStore } from "@/stores/auth.js";
import { funcoes } from "@/plugins/funcoes.js";

import Dialog from "../components/ComponentDialog.vue";

export default {
  components: { Dialog },
  props: {},

  data() {
    return {
      url:"Carregando"
    };
  },

  mounted() {},

  methods: {
    abrir(interno = false) {
      if (!interno) {
        this.$emit("dialog-on");
      }

      this.$refs.dialogView.abrir();
    },
    async carregar(item) {

      this.url = item._id;

			const storeAuth = useAuthStore();

			var arrItensArquivos = [];
			
			var itemDownload = null;

			arrItensArquivos.push(item._id);

			//* Restaura o diretorio
			let responseDownload = await api.post(storeAuth.apiNuvem(item.instancia) + "/download/request", {itensArquivos:arrItensArquivos, itensVersoes: [], itensDiretorios:[]}, storeAuth.token[item.instancia] !== "" ? storeAuth.token[item.instancia] : null);

			//* Verifica se ocorreu erro
			if(responseDownload.data.erro == false)
			{

				funcoes.novaJanela(storeAuth.apiNuvem(item.instancia) + "/download/" + funcoes.customEncodeURIComponent(responseDownload.data.key) + "?attachment=false&nome=" + responseDownload.data.nome + responseDownload.data.tipo, 300, 400);
				
			}

		},
    fechar() {
      this.$refs.dialogView.fechar();
    },
  },
};
</script>

<style>
</style>
