<template>
  <div>
    <div class="row gy-5 g-xl-8">
      <div class="col-xl-12">
        <div class="mb-0" :class="{ 'card card-xl-stretch': !dialog }">
          <div
            class="border-0"
            data-select2-id="select2-data-133-64sq"
            :class="{
              'card-header pt-6': !dialog,
              'd-flex justify-content-between': dialog,
            }"
          >
            <div :class="{ 'card-title': !dialog }">
              <div class="d-flex align-items-center position-relative my-1">
                <span class="svg-icon svg-icon-1 position-absolute ms-6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect
                      opacity="0.5"
                      x="17.0365"
                      y="15.1223"
                      width="8.15546"
                      height="2"
                      rx="1"
                      transform="rotate(45 17.0365 15.1223)"
                      fill="currentColor"
                    ></rect>
                    <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>

                <input
                  type="text"
                  class="form-control form-control-solid w-250px ps-14"
                  placeholder="Pesquisar"
                  @keyup="keyupFiltroTexto"
                  v-model="filtroTexto"
                  autocomplete="off"
                />
              </div>
            </div>

            <div :class="{ 'card-toolbar': !dialog }">
              <div
                class="d-flex justify-content-end"
                data-kt-user-table-toolbar="base"
                data-select2-id="select2-data-132-7t2c"
              >
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="novoItem()"
                >
                  <span class="svg-icon svg-icon-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <rect
                        opacity="0.5"
                        x="11.364"
                        y="20.364"
                        width="16"
                        height="2"
                        rx="1"
                        transform="rotate(-90 11.364 20.364)"
                        fill="currentColor"
                      ></rect>
                      <rect
                        x="4.36396"
                        y="11.364"
                        width="16"
                        height="2"
                        rx="1"
                        fill="currentColor"
                      ></rect>
                    </svg>
                  </span>
                  Novo
                </button>
              </div>
            </div>
          </div>

          <div class="py-3" :class="{ 'card-body': !dialog }">
            <div class="table-responsive">
              <table
                class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
              >
                <thead>
                  <tr class="fw-bolder text-muted">
                    <th class="w-25px" style="display: none">
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value="1"
                          data-kt-check="true"
                          data-kt-check-target=".widget-9-check"
                        />
                      </div>
                    </th>
                    <th class="min-w-200px">Grupos ({{ itensTotal }})</th>
                    <th class="min-w-150px" v-if="false">Nuvem</th>
                    <th class="min-w-100px text-end">Opções</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="item in itens" :key="item._id" :item="item">
                    <td style="display: none">
                      <div
                        class="form-check form-check-sm form-check-custom form-check-solid"
                      >
                        <input
                          class="form-check-input widget-9-check"
                          type="checkbox"
                          value="1"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-45px me-5 mt-1 mb-1 mx-1">
                          <i class="bi bi-people text-primary fs-2hx gx-5"></i>
                        </div>
                        <div class="d-flex justify-content-start flex-column">
                          <span
                            class="text-dark fw-bolder text-hover-primary fs-6"
                            style="cursor: pointer"
                            @click="editarItem(item._id)"
                          >
                            {{ item.nome }}
                          </span>
                          <span
                            class="text-muted fw-bold text-muted d-block fs-7"
                            >{{ item.usuarios.length
                            }}{{
                              item.usuarios.length == 1
                                ? " usuário"
                                : " usuários"
                            }}</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="d-flex justify-content-end flex-shrink-0">
                        <button
                          class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                          @click="editarItem(item._id)"
                        >
                          <span class="svg-icon svg-icon-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                opacity="0.3"
                                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Paginacao
              ref="paginacao"
              :total="itensTotal"
              :limit="20"
              @change-pagina="carregarItens()"
            />
          </div>
        </div>
      </div>
    </div>

    <GrupoEdit
      ref="formGrupo"
      view="modal"
      @save-item="carregarItens()"
      @dialog-on="
        if (this.dialog) {
          $emit('dialog-on');
        }
      "
      @dialog-off="
        if (this.dialog) {
          $emit('dialog-off');
        }
      "
    />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

//import Combo from "../../components/ComponentCombo.vue";
import Paginacao from "../components/ComponentPaginacao.vue";
import GrupoEdit from "./GrupoEdit.vue";

export default {
  components: { GrupoEdit, Paginacao },

  props: {
    dialog: { type: Boolean, default: false },
  },

  data() {
    return {
      timerFiltroTexto: null,
      itensTotal: 0,
      filtroTexto: "",
      filtroCliente: "",
      filtroNuvem: "",
      ordem: "nome_ASC",
      itens: [],
    };
  },

  mounted() {},

  methods: {
    async carregarItens() {
      let filtros = "";

      filtros += "?_limit=" + 20;

      filtros += "&_page=" + this.$refs.paginacao.pagina;

      if (this.filtroTexto !== "") {
        filtros += "&_all=" + this.filtroTexto;
      }

      filtros += "&_ordem=" + this.ordem;

      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/grupo" + filtros);
      api.valid(response.data, this.$router);

      this.itens = response.data.itens;
      this.itensTotal = response.data.total;
    },
    editarItem(id) {
      this.$refs.formGrupo.carregarItem(id);
      this.$refs.formGrupo.abrir();
    },
    async novoItem() {
      await this.$refs.formGrupo.novo();
      this.$refs.formGrupo.item.cliente = this.filtroCliente;
      this.$refs.formGrupo.item.nuvem = this.filtroNuvem;
      this.$refs.formGrupo.abrir();
    },
    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const obj = this;

      this.timerFiltroTexto = setTimeout(function () {
        //obj.$refs.paginacao.pagina = 1;
        obj.carregarItens();
      }, 300);
    },
  },
};
</script>

<style>
</style>
