<template>
  <div class="row justify-content-center mt-10">
    <div class="col-12 col-md-11 col-sm-12 col-xs-12 mb-7">
      <div class="row justify-content-center mb-7">
        <div class="text-center col-10">
          <h5>Tarefas de Backup</h5>
          <h5 class="text-gray-700">
            <small>Rotinas agendadas</small>
          </h5>
        </div>
      </div>

      <BackupList />
    </div>
  </div>
</template>

<script>
import BackupList from "../../../../_shared/features/BackupList.vue";

export default {
  name: "BackupsApp",
  components: { BackupList },
  beforeCreate: function () {
    document.body.classList.remove("bodyOverflow");
  },
};
</script>
