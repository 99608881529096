<template>
  <div>
    <Dialog
      :id="'dialogUploadHtml'"
      ref="dialogUploadHtml"
      :hide-header="false"
      :hideFooter="true"
      :modal="true"
      :tamanho="'lg'"
      :classModalBody="'mx-1 mx-xl-1 my-1'"
      :titulo="'Upload'"
      @dialog-off="fechar()"
    >
      <div class="d-none">
        <input ref="inputArquivo" type="file" @change="onFileChange" multiple="true" />
        <input ref="inputPasta" type="file" @change="onFileChange" multiple="true" directory webkitdirectory />
      </div>

      <div
        class="drop-area"
        @dragenter="handleDragEnter"
        @dragover="handleDragOver"
        @dragleave="handleDragLeave"
        @drop="handleDrop"
      >
        <h5 class="text-muted">Arraste e solte arquivos aqui</h5>
      </div>

      <div class="mt-5 mb-5 text-center">
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary" v-if="false" :disabled="enviando == true || concluido == true || fila.length == 0 " @click="uploadFiles()">Iniciar</button>
          <button class="btn btn-primary" v-if="enviando" @click="cancelarUploadFiles()">Cancelar</button>
          <button class="btn btn-warning" v-if="enviando == false" :disabled="enviando == true" @click="$refs.inputArquivo.click();">Selecionar arquivos</button>
          <button class="btn btn-warning ms-1" v-if="enviando == false" :disabled="enviando == true" @click="$refs.inputPasta.click();">Selecionar pasta</button>
        </div>
      </div>
      
      <div>
        <h6 v-if="enviando == false && concluido == false && fila.length == 0" class="text-muted mt-1 text-center">Selecione os arquivos a serem enviados</h6>
        <h6 v-if="enviando == false && concluido == false && fila.length > 0" class="text-muted mt-1 text-center">{{fila.length}} arquivo<span v-if="fila.length > 1">s</span> selecionado<span v-if="fila.length > 1">s</span></h6>
        <h6 v-if="enviando == true" class="text-muted mt-1 text-center">{{concluidos}} / {{fila.length}} arquivo<span v-if="concluidos > 1">s</span> concluído<span v-if="concluidos > 1">s</span></h6>
        <h6 v-if="enviando == false && concluido == true && fila.length > 0 && concluidos > 0" class="text-dark mt-1 text-center">{{concluidos}} / {{fila.length}} arquivo<span v-if="concluidos > 1">s</span> enviado<span v-if="concluidos > 1">s</span> com sucesso</h6>
        <h6 v-if="enviando == false && concluido == true && fila.length > 0 && concluidos == 0" class="text-dark mt-1 text-center">Envio concluído, nenhum arquivo foi enviado</h6>
      </div>

      <ul class="list-group mt-5">
        <li v-for="(file, index) in fila" :key="index" class="list-group-item align-items-center">
          
          <div class="mt-1">
            <h6 class="text-dark">{{ file.path !== '' ? file.path : file.name }}</h6>
            <span style="display:none" class="badge badge-primary" @click="uploadFile(file, index)">Enviar</span>
          </div>
          <div class="progress" style="height: 5px;">
            <div class="progress-bar" :class="{'bg-success': file.progresso >= 100}" role="progressbar" :style="{ width: file.progresso + '%' }" :aria-valuenow="file.progresso" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
          <div class="d-flex justify-content-between mt-2">
            <h6 class="text-muted fw-normal">{{ $funcoes.tamanhoTexto(file.tamanho) }}</h6>
            <h6 class="text-muted" v-if="!file.erroTipo || file.erroTipo == ''">{{ file.progresso }} %</h6>
            <h6 class="text-danger" v-if="file.erroTipo && file.erroTipo !== ''">{{ file.erroMensagem }}</h6>
          </div>
          
        </li>
      </ul>

    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreArvore } from "../stores/storeArvore.js";
import Dialog from "./ComponentDialog.vue";

export default {
  components: { Dialog },
  props: {
    uploadAutomatico: { type: Boolean, default: true },
  },
  setup() {
    const storeAuth = useAuthStore();
    const storeArvore = useStoreArvore();

    return { storeAuth, storeArvore };
  },
  data() {
    return {
      fila:[],
      enviando: false,
      concluido: false,
      concluidos: 0
    };
  },
  computed: {},

  mounted() {
    this.customId = "ComponentUploadHtml_" + this.$funcoes.guidGenerator();
  },
  methods: {
    async uploadFiles() {

      var thisCall = this;

      //* Verifica se esta visualizando os compartilhamentos
      var compartilhamento = this.storeArvore.instancias["direita"].view == "compartilhamentos";

      //* Define o status
      this.enviando = true;
      this.concluido = false;
      this.concluidos = 0;

      //* Carrega os dados da sessao
      var resultUploadRequest;

      //* Percorre os arquivos
      for(let indiceArquivo = 0; indiceArquivo < this.fila.length; indiceArquivo++)
      {

        //* Instancia o arquivo
        let arquivo = this.fila[indiceArquivo];

        //* Define os dados a serem enviados
        let formData = { fileName: arquivo.name, diretorio: "", tamanho: arquivo.tamanho, dataAlteracao: arquivo.dataAlteracao, compartilhamento };

        //* Armazena o path
        let path = arquivo.path;

        //* Verifica se consta um path de subdiretorio
        if(path !== "")
        {
          path = path.substring(0, path.length - arquivo.name.length - 1);
        }

        //* Verifica se esta na raiz
        if(this.storeArvore.instancias["direita"].diretorioAtual !== "")
        {
          formData.diretorioPath = this.storeArvore.instancias["direita"].barraPath + (path !== '' ? '/' + path : '');
        }else{
          //* Verifica se deve criar em um subdiretorio
          if(path !== '')
          {
            formData.diretorioPath = '/' + path;
          }
        }

        try {
          //* Efetua o request do arquivo a ser enviado
          resultUploadRequest = await api.post(this.storeAuth.apiNuvem("direita") + "/upload/request", formData, this.storeAuth.token["direita"] !== "" ? this.storeAuth.token["direita"] : null);
        } catch (errArquivoRequest) {
          resultUploadRequest = { data: { erro: true, erroTipo: "CONEXAO" } };
        }

        //* Verifica se ocorreu algum erro
        if(resultUploadRequest.data.erro == false)
        {
          
          //* Define os dados para upload
          arquivo.request = resultUploadRequest.data;
          arquivo._id = resultUploadRequest.data._id;
          arquivo.versao = resultUploadRequest.data.fileId;

          //* Executa o upload
          await api.uploadFileHtml(this.storeAuth.apiNuvem("direita"), arquivo, this.storeAuth.token["direita"] !== "" ? this.storeAuth.token["direita"] : null)

          //* Incrementa o numero de itens concluidos
          this.concluidos = this.concluidos + 1;

        }else{
          	arquivo.erroTipo = resultUploadRequest.data.erroTipo;
            arquivo.erroMensagem = this.$funcoes.errosUpload()[arquivo.erroTipo] ? this.$funcoes.errosUpload()[arquivo.erroTipo] : arquivo.erroTipo;
        }
      
        //* Verifica se parou o envio
        if(this.enviando == false)
        {
          return;
        }

      }

      setTimeout(function(){

        //* Finaliza o envio
        thisCall.enviando = false;
        thisCall.concluido = true;

        //* Limpa os inputs
        this.$refs.inputArquivo.value = "";
        this.$refs.inputPasta.value = "";

      },1000);
      

    },
    cancelarUploadFiles() {

      //* Limpa os inputs
      this.$refs.inputArquivo.value = "";
      this.$refs.inputPasta.value = "";

      //* Cancela o envio
      this.fila = [];
      this.enviando = false;
      this.concluido = false;
      this.concluidos = 0;

    },
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogUploadHtml.abrir();
    },
    fechar() {
      this.cancelarUploadFiles();
      this.$refs.dialogUploadHtml.fechar();
      this.$emit("dialog-off");
    },
    onFileChange(e) {
      this.selecionarItens(e.target.files);
    },
    handleDragEnter(event) {
      event.preventDefault();
    },
    handleDragOver(event) {
      event.preventDefault();
    },
    handleDragLeave(event) {
      event.preventDefault();
    },
    handleDrop(event) {
      event.preventDefault();
      this.selecionarItens(Array.from(event.dataTransfer.files));
    },
    selecionarItens(itens)
    {

      var thisCall = this;

      //* Reinicia o status
      this.enviando = false;
      this.concluido = false;
      this.concluidos = 0;

      //* Limpa os itens da fila
      this.fila = [];

      //* Verifica se selecionou algum arquivos
      if (itens.length > 0) {
        for (
          var indiceFile = 0;
          indiceFile < itens.length;
          indiceFile++
        ) {

          let file = itens[indiceFile]; 

          //* Insere o item na fila
          this.fila.push({
            name: file.name,
            tamanho: file.size,
            dataAlteracao: file.lastModified,
            progresso: 0,
            status: '',
            path:file.webkitRelativePath ? file.webkitRelativePath : "",
            file: file
          });

        }

        //* Verifica se deve efetuar o upload
        if (this.uploadAutomatico) {
          setTimeout(function () {
            thisCall.uploadFiles();
          }, 300);
        }
      }

    }
  },
};
</script>

<style scoped>
.drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
}
</style>
