<template>
  <div class="row">
    <div class="col col-md-12">
      <div
        v-if="mensagem !== '' && view !== 'modal'"
        class="alert alert-success alert-dismissible fade show"
        role="alert"
      >
        <strong>{{ mensagem }}</strong>
      </div>
      <Dialog
        :id="'dialogAtividadeFiltro'"
        ref="dialogAtividadeFiltro"
        :hide-header="true"
        :modal="view == 'modal'"
        tamanho="lg"
      >
        <div>
          <div class="d-flex justify-content-end">
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="fv-row mb-7 text-center">
            <h2 class="fw-bolder">Filtrar</h2>
          </div>
        </div>

        <div>
          <div class="fv-row mb-5">
            <label class="fs-6 fw-bold mb-2">Tipo de atividade</label>
            <Combo
              :modelValue="item.tipo"
              @update:modelValue="(newValue) => (item.tipo = newValue)"
              :itens="tiposAtividade"
              autofocus="true"
              :classSelect2="false"
            />
          </div>
          <div class="fv-row mb-5">
            <label class="fs-6 fw-bold mb-2">Usuário</label>
            <Combo
              :modelValue="item.usuario"
              @update:modelValue="(newValue) => (item.usuario = newValue)"
              :itens="usuarios"
              autofocus="true"
              :classSelect2="false"
            />
          </div>

          <div class="row">
            <label class="fs-6 fw-bold mb-2">Data</label>
            <div class="col-6 fv-row">
              <label class="fs-6 fw-bold mb-2 text-muted">Inicial</label>
              <input
                v-model="item.dataInicial"
                class="form-control form-control-solid"
                type="datetime-local"
                autofocus="true"
                autocomplete="off"
              />
            </div>
            <div class="col-6 fv-row">
              <label class="fs-6 fw-bold mb-2 text-muted">Final</label>
              <input
                v-model="item.dataFinal"
                class="form-control form-control-solid"
                type="datetime-local"
                autofocus="true"
                autocomplete="off"
              />
            </div>
          </div>
        </div>

        <template #rodape>
          <div class="row">
            <div
              class="col-md-12 col-sm-12 d-flex justify-content-center"
              style="align-items: center; padding: 0.75rem"
            >
              <button type="button" class="btn btn-primary" @click="fechar()">
                Fechar
              </button>
            </div>
          </div>
        </template>
      </Dialog>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import { useAuthStore } from "@/stores/auth.js";

import Dialog from "../components/ComponentDialog.vue";
import Combo from "../components/ComponentCombo.vue";

export default {
  components: { Combo, Dialog },
  props: {
    view: { type: String, default: "modal" },
  },

  computed: {
    tiposAtividade: function () {
      //* Insere o item todos
      var arrTiposAtividades = [{ id: "", nome: "Todos" }];

      //* Insere os tipos
      arrTiposAtividades.push(...this.$funcoes.tiposAtividadeArray());

      return arrTiposAtividades;
    },
  },

  data() {
    return {
      item: {
        tipo: "",
        usuario: "",
        dataInicial: "",
        dataFinal: "",
      },
      usuarios: [],
      authStore: null,
    };
  },

  mounted() {
    this.authStore = useAuthStore();
  },

  methods: {
    abrir() {
      this.$emit("dialog-on");
      this.$refs.dialogAtividadeFiltro.abrir();
    },
    fechar() {
      this.$refs.dialogAtividadeFiltro.fechar();
      this.$emit("dialog-off");
    },

    async novo() {
      this.item.tipo = "";
      this.item.usuario = "";
      this.item.dataInicial = "";
      this.item.dataFinal = "";

      //* Carrega os usuarios
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/usuario");
      api.valid(response.data, this.$router);

      //* Define os usuarios
      this.usuarios = [{ id: "", nome: "Todos" }];

      //* Percorre os usuarios encontrados
      for (
        let indiceUsuario = 0;
        indiceUsuario < response.data.itens.length;
        indiceUsuario++
      ) {
        //* Insere na lista
        let itemUsuario = response.data.itens[indiceUsuario];
        this.usuarios.push({ id: itemUsuario._id, nome: itemUsuario.nome });
      }
    },
  },
};
</script>

<style>
</style>
