import { isDate } from "util";
import { api } from "@/plugins/api.js";

const humanReadable = require("@tsmx/human-readable");

export const funcoes = {
	formatarDataHora(datetime) {
		if (!datetime) return null;
		if (datetime && datetime != null && datetime !== "") {
			const options = {
				year: "numeric",
				month: "numeric",
				day: "numeric",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit"
			};
			datetime = new Date(datetime);
			const portugueseTime = new Intl.DateTimeFormat(["pt-BR", "pt-PT"], options);
			return portugueseTime.format(datetime);
		} else {
			return null;
		}
	},
	formatarData(datetime) {
		if (!datetime) return null;
		if (datetime && datetime != null && datetime !== "") {
			const options = {
				year: "numeric",
				month: "numeric",
				day: "numeric"
			};
			datetime = new Date(datetime);
			const portugueseTime = new Intl.DateTimeFormat(["pt-BR", "pt-PT"], options);
			return portugueseTime.format(datetime);
		} else {
			return null;
		}
	},
	formatarDinheiro(valor) {
		if (!valor) return "R$ 0,00";

		const formatter = new Intl.NumberFormat("pt-BR", {
			style: "decimal",
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
		return "R$ " + formatter.format(valor);
	},
	dataAdicionarDias(data, days) {
		const date = new Date(data.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	},
	dataDiferencaDias(date1, date2) {
		const dt1 = new Date(date1);
		const dt2 = new Date(date2);
		return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
	},
	dataConverterUS(date) {
		if (!date) return null;
		if (isDate(date)) {
			date = date.getFullYear() + "-" + (date.getMonth() + 1 < 10 ? "0" : "") + (date.getMonth() + 1) + "-" + (date.getDate() < 10 ? "0" : "") + date.getDate();
		}
		date = date.substr(0, 10); //* remove a parte do horário
		const [year, month, day] = date.split("-");
		return new Date(year, month - 1, day, 0, 0, 0, 0);
	},
	dataConverterUSString(date) {
		if (isDate(date)) {
			date = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
		}
		if (!date) return null;
		if (date.indexOf("T")) {
			date = date.split("T")[0];
		}
		if (date.indexOf(" ")) {
			date = date.split(" ")[0];
		}
		const [year, month, day] = date.split("-");
		return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
	},
	formatarDataHoraLocal(data) {
		return data.getFullYear() + "-" + (data.getMonth() + 1).toString().padStart(2, "0") + "-" + data.getDate().toString().padStart(2, "0") + "T" + data.getHours().toString().padStart(2, "0") + ":" + data.getMinutes().toString().padStart(2, "0");
	},
	scroll() {
		//$("html,body").animate({ scrollTop: 0 }, "slow");
		console.log("scroll");
	},
	porcentagem(valorAtual, valorTotal) {
		if (valorTotal == 0) {
			return 0;
		}

		return ((100 * valorAtual) / valorTotal).toFixed(2);
	},
	guidGenerator() {
		var S4 = function () {
			return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
		};
		return S4() + S4() + S4() + S4() + S4() + S4() + S4() + S4();
	},
	tiposUsuario() {
		return { admin: { id: "admin", nome: "Admin", sysadmin: true }, adminNuvem: { id: "adminNuvem", nome: "Admin Nuvem", sysadmin: false }, usuarioNuvem: { id: "usuarioNuvem", nome: "Convidado Nuvem", sysadmin: false } };
	},
	tiposUsuarioArray() {
		return Object.values(this.tiposUsuario());
	},
	tiposAtividade() {
		return { download: { id: "DOWNLOAD", nome: "Download" }, upload: { id: "UPLOAD", nome: "Upload" }, restaurar: { id: "RESTAURAR", nome: "Restaurar" }, novo: { id: "NOVO", nome: "Nova Pasta" }, excluir: { id: "EXCLUIR", nome: "Excluir" } };
	},
	tiposAtividadeArray() {
		return Object.values(this.tiposAtividade());
	},
	errosUpload()
	{
		return { ESPACO_INDISPONIVEL: "Espaço indisponível", USUARIO_INATIVO: "Usuário inativo", NUVEM_INATIVA: "Nuvem inativa", STORAGE_INATIVO: "Storage inativo", ARQUIVO_VAZIO: "Arquivo vazio", VERSAO_ATUAL: "Versão atual", DIREITO_ESCREVER: "Sem Permissão" };
	},
	keyPressNomeUsuario(event) {
		var charCode = event.keyCode;
		const testRegex = /^[A-Za-z0-9.-]+$/;

		if (!testRegex.test(String.fromCharCode(charCode))) {
			this.cancelEvent(event);
		}
	},
	keyPressInteiro(event) {
		var charCode = event.keyCode;
		const testRegex = /^[0-9]+$/;

		if (!testRegex.test(String.fromCharCode(charCode))) {
			this.cancelEvent(event);
		}
	},
	cancelEvent(event) {
		if (event.preventDefault) {
			event.preventDefault();
		} else {
			event.returnValue = false;
		}
	},
	usuarioFoto(id, foto) {
		return api.usuarioFoto(id, foto) + "?cache=" + this.guidGenerator();
	},
	tamanhoTexto(size) {
		return humanReadable.fromBytes(size, { fixedPrecision: 2, mode: "IEC" });
	},
	downloadUrl(url, filename) {
		// Create a new anchor element
		const a = document.createElement("a");

		// Set the href and download attributes for the anchor element
		// You can optionally set other attributes like `title`, etc
		// Especially, if the anchor element will be attached to the DOM
		a.href = url;
		a.download = filename || "download";

		// Click handler that releases the object URL after the element has been clicked
		// This is required for one-off downloads of the blob content
		const clickHandler = () => {
			setTimeout(() => {
				URL.revokeObjectURL(url);
				a.removeEventListener("click", clickHandler);
			}, 150);
		};

		// Add the click event listener on the anchor element
		// Comment out this line if you don't want a one-off download of the blob content
		a.addEventListener("click", clickHandler, false);

		// Programmatically trigger a click on the anchor element
		// Useful if you want the download to happen automatically
		// Without attaching the anchor element to the DOM
		// Comment out this line if you don't want an automatic download of the blob content
		a.click();

		// Return the anchor element
		// Useful if you want a reference to the element
		// in order to attach it to the DOM or use it in some other way
		return a;
	},
	novaJanela(url,altura,largura)
	{

		window.open(
			url,
			"newwindow_" + Date.now(),
			"scrollbars=1,width=" + largura.toString() + ",height=" + altura.toString(),
			true
		);
		
	},
	customEncodeURIComponent(str) {
		return encodeURIComponent(str).replace(/[!'()*/]/g, function(c) {
			return '%' + c.charCodeAt(0).toString(16);
		});
	},
	appTipo() {
		return process.env.VUE_APP_TIPO;
	},
	dateAddDays(data, days) {
		var date = new Date(data.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	}
};

export default {
	install: (app) => {
		app.config.globalProperties.$funcoes = funcoes;
	}
};
