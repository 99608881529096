<template>
  <div>
    <Dialog :id="'dialogLinkOpcoes'" ref="dialogLinkOpcoes" :hide-header="false" :hideFooter="true" :modal="true"
      :tamanho="'lg'" :classModalBody="'mx-1 mx-xl-1 my-1'" :titulo="'Link'" @dialog-off="fechar()">
      <div>
        <div>

          <h3 class="text-gray-800 text-center mb-3">
            <small>Arquivo</small>
          </h3>

          <h3 class="text-gray-500 text-center mb-8">
            <small>{{ link.arquivo }}</small>
          </h3>

          <h3 class="text-gray-800 text-center mb-8">
            <small>Link para compartilhamento</small>
          </h3>

          <div class="d-flex">
            <input type="text" class="form-control form-control-solid me-3 flex-grow-1"
              :class="{ 'bg-success text-inverse-success': copiando }" v-model="link.url">
            <button class="btn btn-light btn-active-light-primary fw-bold flex-shrink-0" @click="copiarEndereco()">{{
              copiando ? "Copiado!" : "Copiar" }}</button>
          </div>

          <h3 v-if="sucessoEmailMensagem !== ''" class="text-success mt-10 text-center">{{ sucessoEmailMensagem }}</h3>

          <div class="row mt-10" :class="{ 'd-none': view !== 'link' }">
            <div class="col-md-12 text-center">
              <button type="button" class="btn btn-light btn-active-light-primary fw-bold flex-shrink-0 me-2"
                @click="abrirOpcoes()">
                Opções
              </button>
              <button type="button" class="btn btn-warning" @click="abrirEnviarEmail()">
                Enviar por e-mail
              </button>
            </div>
          </div>

        </div>
        <div class="mt-7 text-center" :class="{ 'd-none': view != 'opcoes' }">

          <h5 class="text-info fw-bolder fs-5 mt-8">
            Expirar em
          </h5>

          <div class="mb-7">
            <h5 class="fs-2x fw-bold text-gray-800 me-2 lh-1 ls-n2 mt-5">
              {{ link.expirar }}
            </h5>
            <h5 class="fs-6 fw-semibold text-gray-400">Dias</h5>
            <input type="range" class="form-range" v-model="link.expirar" min="1" max="30" />
            <h5 class="fs-6 fw-semibold text-gray-400 mt-1">
              {{ $funcoes.formatarDataHora($funcoes.dateAddDays(link.data, parseInt(link.expirar))) }}
            </h5>
          </div>

          <div class="fv-row mb-5">
            <h5 class="text-info fw-bolder fs-5">
              Senha
            </h5>
            <input class="form-control form-control-solid" type="text" autocomplete="off"
              v-model="link.senha" @keyup="(event) => event.stopPropagation()" />            
          </div>

          <div class="row mt-10">
            <div class="col-md-12 text-center">

              <button type="button" class="btn btn-light btn-active-light-primary fw-bold flex-shrink-0"
                style="margin-right: 5px;" @click="view = 'link'">
                Cancelar </button>
              <button type="button" class="btn btn-success" @click="salvarLink()"> Salvar </button>

            </div>
          </div>

        </div>
        <div class="mt-7" :class="{ 'd-none': view != 'email' }">

          <h2 class="text-primary mb-3 text-center">Dados do e-mail</h2>

          <h3 v-if="erroEmailMensagem !== ''" class="text-primary mb-3 text-center">{{ erroEmailMensagem }}</h3>

          <div class="fv-row mt-4 mb-7">
            <div class="fv-row mb-5">
              <label class="fs-6 fw-semibold mb-2">Para</label>
              <input ref="emailPara" class="form-control form-control-solid" type="text" autocomplete="off"
                v-model="email.para" @keyup="(event) => event.stopPropagation()" />
              <div class="invalid-feedback" :class="{ 'd-block': itemErrosEmail.para.status }">
                {{ itemErrosEmail.para.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-5">
              <label class="fs-6 fw-semibold mb-2">Assunto</label>
              <input class="form-control form-control-solid" type="text" autocomplete="off"
                @keyup="(event) => event.stopPropagation()" v-model="email.assunto" />
              <div class="invalid-feedback" :class="{ 'd-block': itemErrosEmail.assunto.status }">
                {{ itemErrosEmail.assunto.mensagem }}
              </div>
            </div>
            <div class="fv-row mb-2">
              <label class="fs-6 fw-semibold mb-2">Anexo</label>
              <div class="mt-2 d-flex">

                <div class="form-check form-check-custom form-check-solid mb-2 text-center">
                  <input class="form-check-input" type="radio" :checked="email.anexo" @click="email.anexo = true" />
                  <label class="form-check-label fw-bold ms-3" for="status" @click="email.anexo = true">Com
                    anexo</label>
                </div>

                <div class="form-check form-check-custom form-check-solid ms-3 mb-2 text-center">
                  <input class="form-check-input" type="radio" :checked="email.anexo == false"
                    @click="email.anexo = false" />
                  <label class="form-check-label fw-bold ms-3" for="status" @click="email.anexo = false">Sem anexo</label>
                </div>


              </div>
            </div>
            <div class="fv-row">
              <label class="fs-6 fw-semibold mb-2">Corpo</label>
              <ckeditor @ready="onEditorReady" :editor="ckEditorFormat" v-model="email.corpo" :config="ckEditorConfig">
              </ckeditor>
            </div>
          </div>

          <div class="row mt-10">
            <div class="col-md-12 text-center">

              <button type="button" class="btn btn-light btn-active-light-primary fw-bold flex-shrink-0"
                style="margin-right: 5px;" @click="view = 'link'">
                Cancelar </button>
              <button type="button" class="btn btn-success" @click="enviarEmail()"> Enviar </button>

            </div>
          </div>

        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import Dialog from "../components/ComponentDialog.vue";
import DialogMensagem from "../components/ComponentDialogMensagem.vue";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreArvore } from "../stores/storeArvore.js";
import UsuarioSelectDialog from "./UsuarioSelectDialog.vue";
import GrupoSelectDialog from "./GrupoSelectDialog.vue";
import AtividadeList from "./AtividadeList.vue";
import Editor from "ckeditor5-custom-build/build/ckeditor";

export default {
  components: {
    Dialog,
    DialogMensagem,
    UsuarioSelectDialog,
    GrupoSelectDialog,
    AtividadeList
  },
  props: {
    instancia: { type: String, default: "" },
  },

  setup() {
    const storeAuth = useAuthStore();
    const storeArvore = useStoreArvore();

    return { storeAuth, storeArvore };
  },

  data() {
    return {
      email: {
        para: "",
        assunto: "",
        corpo: "",
        anexo: true,
      },
      link: {
        key: "",
        url: "",
        arquivo: "",
        expirar: 1,
        senha: "",
        data: new Date()
      },
      itemErrosEmail: {
        para: { status: false, mensagem: "" },
        assunto: { status: false, mensagem: "" },
      },
      erroEmailMensagem: "",
      sucessoEmailMensagem: "",
      ckEditorFormat: Editor,
      ckEditorConfig: {},
      copiando: false,
      view: "link"
    };
  },

  mounted() { },

  methods: {
    abrir(key = null) {

      this.$emit("dialog-on");

      //* Define que consta uma dialog aberta
      this.storeArvore.dialogAberta = true;

      //* Define a visualizacao padrao
      this.view = "link";
      this.copiando = false;

      //* Limpa os dados
      this.link.key = "";
      this.link.url = "";
      this.link.arquivo = "";
      this.link.expirar = 1;
      this.link.senha = "";
      this.link.data = new Date();

      //* Limpa os dados do email
      this.novoEmail();

      if(key == null)
      {
        //* Cria o download
        this.criarDownload(this.instancia);
      }else{
        this.link.key = key;
        this.carregarLink();
      }

      //* Abre a dialog
      this.$refs.dialogLinkOpcoes.abrir();

    },
    fechar() {
      //* Define que consta uma dialog fechada
      this.storeArvore.dialogAberta = false;

      this.$refs.dialogLinkOpcoes.fechar();
      this.$emit("dialog-off");
    },
    enterSalvar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        //this.salvarItem();
      }
    },
    copiarEndereco() {
      var thisCall = this;

      //* Define que esta copiando
      thisCall.copiando = true;

      //* Define o conteudo
      navigator.clipboard.writeText(this.link.url);

      //* Funcao para desmarcar o copiando
      setTimeout(() => {

        thisCall.copiando = false;

      }, 3000);

    },
    async onEditorReady(eventInfo) {
      console.log(await eventInfo.ui.componentFactory);
    },
    async criarDownload() {

      var arrItensDiretorios = [];
      var arrItensArquivos = [];
      var arrItensVersoes = [];

      var itemDownload = null;

      //* Carrega o array de selecionados
      var arrSelecionados = Object.keys(this.storeArvore.instancias[this.instancia].selecionados);

      //* Verifica se consta itens selecionados
      if (arrSelecionados.length == 0) {
        return;
      }

      //* Percorre todos os itens da area de transferencia
      for (var indexItemDownload = 0; indexItemDownload < arrSelecionados.length; indexItemDownload++) {
        itemDownload = this.storeArvore.instancias[this.instancia].selecionados[arrSelecionados[indexItemDownload]];

        if (itemDownload.isDiretorio == true) {
          arrItensDiretorios.push(itemDownload._id);
        } else if (itemDownload.isArquivo == true) {

          //* Desmembra a versao
          var arrIdArquivo = itemDownload._id.split("_");

          //* Verifica se consta uma versao especifica
          if (arrIdArquivo.length > 0) {

            //* Insere o arquivo com a versao
            arrItensArquivos.push(arrIdArquivo[0]);
            arrItensVersoes.push(arrIdArquivo[1]);

          } else {
            arrItensArquivos.push(itemDownload._id);
          }

        }
      }

      //* Restaura o diretorio
      let responseDownload = await api.post(this.storeAuth.apiNuvem(this.instancia) + "/download/request", { itensArquivos: arrItensArquivos, itensVersoes: arrItensVersoes, itensDiretorios: arrItensDiretorios, acesso:"publico" }, this.storeAuth.token[this.instancia] !== "" ? this.storeAuth.token[this.instancia] : null);

      //* Verifica se ocorreu erro
      if (responseDownload.data.erro == false) {

        //* Define os dados do link
        this.link.key = responseDownload.data.key;
        this.link.arquivo = responseDownload.data.nome + responseDownload.data.tipo;
        this.link.url = process.env.VUE_APP_URL + "/app/download" + this.storeAuth.apiNuvem(this.instancia, false) + "/" + this.$funcoes.customEncodeURIComponent(responseDownload.data.key);
        
      }

    },
    async abrirEnviarEmail() {

      var thisCall = this;

      this.novoEmail();
      this.view = 'email';

      setTimeout(() => {

        thisCall.$refs.emailPara.focus();

      }, 100)

    },
    async abrirOpcoes() {

      var thisCall = this;

      //* Carregar dados do link
      thisCall.carregarLink();
      
      //* Define a visualizacao
      this.view = 'opcoes';

    },
    async enviarEmail() {

      var thisCall = this;

      try {

        //* Limpa a mensagem de erro do email
        this.erroEmailMensagem = "";

        //* Limpa os erros do email
        this.itemErrosEmail = api.errosLimpar(this.itemErrosEmail);

        //* Restaura o diretorio
        let responseDownload = await api.post(this.storeAuth.apiNuvem(this.instancia) + "/download/email", { key: this.link.key, para: this.email.para, assunto: this.email.assunto, corpo: this.email.corpo, anexo: this.email.anexo }, this.storeAuth.token[this.instancia] !== "" ? this.storeAuth.token[this.instancia] : null);

        //* Verifica se ocorreu erro
        if (responseDownload.data.erro == false) {

          //* Apresenta a mensagem
          this.sucessoEmailMensagem = "E-mail enviado com sucesso!";

          //* Retorna para a visualizacao
          this.view = "link";

          //* Limpa os dados do email
          this.novoEmail();

          //* Limpa a mensagem
          setTimeout(() => {
            thisCall.sucessoEmailMensagem = "";
          }, 10000);

        } else {

          if (responseDownload.data.erros) {
            api.errosProcessar(this.itemErrosEmail, responseDownload.data.erros);
          } else if (responseDownload.data.mensagem) {
            this.erroEmailMensagem = responseDownload.data.mensagem;
          }else{
            this.erroEmailMensagem = "Erro ao enviar e-mail, verifique os dados";
          }

        }

      } catch (err) {
        this.erroEmailMensagem = "Erro ao enviar e-mail, verifique os dados";
      }



    },
    novoEmail() {

      //* Limpa os campos
      this.email.para = "";
      this.email.assunto = "";
      this.email.corpo = "";
      this.email.anexo = true;

      //* Limpa os erros do email
      this.itemErrosEmail = api.errosLimpar(this.itemErrosEmail);

      //* Limpa a mensagem de erro do email
      this.erroEmailMensagem = "";

    },
    async carregarLink()
    {

      //* Verifica se foi definida uma key
      if(this.link.key != "")
      {

        //* Restaura o diretorio
        let responseDownload = await api.get(this.storeAuth.apiNuvem(this.instancia) + "/download/request/" + this.$funcoes.customEncodeURIComponent(this.link.key), this.storeAuth.token[this.instancia] !== "" ? this.storeAuth.token[this.instancia] : null);

        //* Verifica se ocorreu erro
        if (responseDownload.data.erro == false) {

          //* Define os dados do link
          this.link.key = responseDownload.data.item.key;
          this.link.arquivo = responseDownload.data.item.nome + responseDownload.data.item.tipo;
          this.link.url = process.env.VUE_APP_URL + "/app/download" + this.storeAuth.apiNuvem(this.instancia, false) + "/" + this.$funcoes.customEncodeURIComponent(responseDownload.data.item.key);
          this.link.expirar = responseDownload.data.item.expirar;
          this.link.senha = responseDownload.data.item.senha;
          this.link.data = new Date(responseDownload.data.item.data);

        }

      }

    },
    async salvarLink()
    {

      //* Restaura o diretorio
      let responseDownload = await api.put(this.storeAuth.apiNuvem(this.instancia) + "/download/request", { key: this.link.key, expirar: this.link.expirar, senha: this.link.senha }, this.storeAuth.token[this.instancia] !== "" ? this.storeAuth.token[this.instancia] : null);

      //* Verifica se ocorreu erro
      if (responseDownload.data.erro == false) {

        //* Limpar
        this.link.expirar = 1;
        this.link.senha = "";

        //* Trocar visualizacao
        this.view = "link";

      }

    }

  },
};
</script>

<style></style>
