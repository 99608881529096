<template>
  <!--begin::Menu wrapper-->
  <div>
    <!--begin::Menu-->
    <div class="d-flex">
      <div
        class="me-3"
        v-for="(menu, menuKey) in menus"
        :key="menuKey"
        :item="menu"
      >
        <span
          class="btn btn-hover-rise btn-white btn-active-primary fw-bold py-3"
          :class="{
            active: $route.name == menu.rota,
          }"
          @click="$router.push(menu.path)"
          ><span
            class="badge badge-circle badge-secondary me-2"
            v-if="menu.badgeFila"
            >{{ Object.keys(storeUpload.arquivosAtivos).length }}</span
          ><span class="">{{ menu.titulo }}</span></span
        >
      </div>
    </div>

    <!--end::Menu-->
  </div>
  <!--end::Menu wrapper-->
</template>

<script>
//import HeaderMegaMenu from "./HeaderMegaMenu.vue";
import { useAuthStore } from "@/stores/auth.js";
import { useStoreUpload } from "../../../_shared/stores/storeUpload";
import { useRouter } from "vue-router";

export default {
  components: {},
  props: {},
  setup() {
    const storeUpload = useStoreUpload();
    const authStore = useAuthStore();

    return { storeUpload, authStore };
  },
  data() {
    return {
      menus: {},
    };
  },

  async mounted() {
    const router = useRouter();

    await router.isReady();

    this.menus = {
      0: { titulo: "Home", path: "/app", rota: "homeApp" },
    };

    this.menus[1] = {
      titulo: "Backup",
      path: "/app/backup",
      rota: "backupsApp",
    };
    this.menus[2] = {
      titulo: "Monitor",
      path: "/app/monitor",
      rota: "monitorApp",
    };
  },

  methods: {},
};
</script>

<style>
</style>
