<template>
  <Teleport to="#modalContainer">
    <div
      :id="customId"
      :class="{ modal: modal, fade: modal }"
      role="dialog"
      aria-labelledby="formLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      data-bs-focus="false"
    >
      <div
        class="modal-dialog"
        :class="{
          'modal-lg mw-650px': tamanho == 'lg',
          'modal-xl': tamanho == 'xl',
          'modal-sm': tamanho == 'sm',
        }"
        role="dialog"
      >
        <div class="modal-content">
          <slot name="header"></slot>
          <div v-if="!hideHeader" class="modal-header">
            <span>
              <h2 v-if="!tituloCenter" class="fw-bolder">{{ titulo }}</h2>
              <h5
                v-if="!tituloCenter && subtitulo !== ''"
                class="fw-bolder text-muted mt-2"
              >
                {{ subtitulo }}
              </h5>
              <h2
                v-if="tituloCenter"
                class="fw-bolder text-center"
                style="width: 100%"
              >
                {{ titulo }}
              </h2>
            </span>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              :class="{ 'd-none': !modal || hideCloseButton }"
            >
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr061.svg-->
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
              <!--end::Svg Icon-->
            </div>
          </div>
          <div :class="'modal-body ' + classBody">
            <p class="text-gray-600 mt-1 fw-semibold fs-5">
              {{ mensagemCustomizada == "" ? mensagem : mensagemCustomizada }}
            </p>
          </div>
          <div v-if="!hideFooter" :class="{ 'modal-footer': !noClassFooter }">
            <div v-if="tipo == 'simnao'">
              <button
                type="button"
                class="btn btn-light me-2"
                data-bs-dismiss="modal"
                @click="cancelar()"
              >
                Cancelar
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="confirmar()"
              >
                Confirmar
              </button>
            </div>
            <div v-if="tipo == 'erro'">
              <button
                type="button"
                class="btn btn-light"
                data-bs-dismiss="modal"
                @click="cancelar()"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  props: {
    id: { type: String, default: "" },
    titulo: { type: String, default: "" },
    subtitulo: { type: String, default: "" },
    mensagem: { type: String, default: "" },
    modal: { type: Boolean, default: true },
    tamanho: { type: String, default: "lg" },
    hideCloseButton: { type: Boolean, default: false },
    "hide-header": { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    noClassFooter: { type: Boolean, default: false },
    tituloCenter: { type: Boolean, default: false },
    classBody: { type: String, default: "mx-5 mx-xl-15 my-1" },
    tipo: { type: String, default: "simnao" },
  },

  data() {
    return { customId: "", mensagemCustomizada: "" };
  },

  mounted() {
    this.customId = this.id + "_" + this.$funcoes.guidGenerator();
  },

  methods: {
    confirmar() {
      this.$emit("confirmar");
      this.fechar();
    },
    cancelar() {
      this.$emit("cancelar");
      this.fechar();
    },
    fechar() {
      var thisCall = this;
      global.$("#" + thisCall.customId).modal("hide");
    },
    abrir() {
      var thisCall = this;
      global.$("#" + thisCall.customId).modal("show");
    },
  },
};
</script>

<style>
:focus {
  outline: 0;
}

.modal {
  overflow: auto !important;
}

.modal-dialog {
  margin-top: 20px !important;
}
</style>
