import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import LoadScript from "vue-plugin-load-script";
import { createPinia } from "pinia";
import CKEditor from "@ckeditor/ckeditor5-vue";
import money from "v-money";

import funcoes from "./plugins/funcoes";

global.jQuery = require("jquery");
var $ = global.jQuery;
window.$ = $;

const app = createApp(App);

app.config.productionTip = false;
app.config.devtools = false;
app.config.debug = false;
app.config.silent = true;

app.use(createPinia());
app.use(router);
app.use(LoadScript);
app.use(funcoes);
app.use(money);
app.use(CKEditor);
app.mount("#app");
