<template>
  <div>
    <Dialog
      :id="'dialogNuvemLogin'"
      ref="dialogNuvemLogin"
      :hide-header="false"
      :hideFooter="true"
      :modal="true"
      :tamanho="'md'"
      :classModalBody="'mx-5 mx-xl-1 my-1'"
      :titulo="'Nova conexão'"
      @dialog-off="fechar()"
    >
      <div>
        <div>
          <form name="login" onsubmit="return false">
            <!--begin::Form-->
            <div class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
              <!--begin::Heading-->
              <div class="text-center mb-10">
                <!--begin::Title-->
                <h1 class="text-dark mb-3">Preencha seus dados</h1>
                <!--end::Title-->
              </div>
              <!--begin::Heading-->
              <!--begin::Input group-->
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-dark"
                  >Usuário</label
                >
                <!--end::Label-->
                <!--begin::Input-->
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="text"
                  v-model="item.nomeUsuario"
                  autofocus
                  @keyup="enterEntrar"
                  autocomplete="username"
                  ref="nomeUsuario"
                />
                <!--end::Input-->
                <div
                  v-if="itemErros.nomeUsuario.status"
                  class="fv-plugins-message-container invalid-feedback"
                >
                  {{ itemErros.nomeUsuario.mensagem }}
                </div>
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row mb-10 fv-plugins-icon-container">
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack mb-2">
                  <!--begin::Label-->
                  <label class="form-label fw-bolder text-dark fs-6 mb-0"
                    >Senha</label
                  >
                  <!--end::Label-->
                  <!--begin::Link-->
                  <a
                    v-if="false"
                    href="/"
                    class="link-primary fs-6 fw-bolder"
                    tabindex="-1"
                    >Esqueceu sua senha ?</a
                  >
                  <!--end::Link-->
                </div>
                <!--end::Wrapper-->
                <!--begin::Input-->
                <input
                  class="form-control form-control-lg form-control-solid"
                  type="password"
                  name="password"
                  v-model="item.senha"
                  @keyup="enterEntrar"
                  autocomplete="current-password"
                />
                <!--end::Input-->
                <div
                  v-if="itemErros.senha.status"
                  class="fv-plugins-message-container invalid-feedback"
                >
                  {{ itemErros.senha.mensagem }}
                </div>
              </div>
              <!--end::Input group-->
              <!--begin::Actions-->
              <div class="text-center">
                <!--begin::Submit button-->
                <button
                  class="btn btn-lg btn-primary w-100 mb-5"
                  type="button"
                  @click="entrar()"
                >
                  <span class="indicator-label">Acessar</span>
                  <span class="indicator-progress"
                    >Please wait...
                    <span
                      class="spinner-border spinner-border-sm align-middle ms-2"
                    ></span
                  ></span>
                </button>
                <!--end::Submit button-->
                <!--begin::Separator-->
                <div
                  class="text-center text-muted text-uppercase fw-bolder mb-5"
                  v-if="false"
                >
                  or
                </div>
                <!--end::Separator-->
                <!--begin::Google link-->
                <a
                  href="#"
                  class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                  v-if="false"
                >
                  <img
                    alt="Logo"
                    src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/brand-logos/google-icon.svg"
                    class="h-20px me-3"
                  />Continue com Google</a
                >
                <!--end::Google link-->
                <!--begin::Google link-->
                <a
                  href="#"
                  class="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                  v-if="false"
                >
                  <img
                    alt="Logo"
                    src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/brand-logos/facebook-4.svg"
                    class="h-20px me-3"
                  />Continue com Facebook</a
                >
                <!--end::Google link-->
                <!--begin::Google link-->
                <a
                  href="#"
                  class="btn btn-flex flex-center btn-light btn-lg w-100"
                  v-if="false"
                >
                  <img
                    alt="Logo"
                    src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/brand-logos/apple-black.svg"
                    class="h-20px me-3"
                  />Continue com Apple</a
                >
                <!--end::Google link-->
              </div>
              <!--end::Actions-->
              <div></div>
            </div>
            <!--end::Form-->
          </form>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
import Dialog from "../components/ComponentDialog.vue";
import { useAuthStore } from "@/stores/auth.js";

export default {
  components: { Dialog },
  props: {},

  setup() {
    const storeAuth = useAuthStore();

    return { storeAuth };
  },

  data() {
    return {
      item: {
        nomeUsuario: "",
        senha: "",
        lembrar: true,
      },
      itemErros: {
        nomeUsuario: { status: false, mensagem: "" },
        senha: { status: false, mensagem: "" },
      },
    };
  },

  mounted() {},

  methods: {
    novo() {
      this.item.nomeUsuario = "";
      this.item.senha = "";
      this.item.lembrar = true;

      var thisCall = this;
      setTimeout(function () {
        thisCall.$refs["nomeUsuario"].focus();
      }, 500);
    },
    abrir() {
      this.novo();
      this.$emit("dialog-on");
      this.$refs.dialogNuvemLogin.abrir();
    },
    fechar() {
      this.$refs.dialogNuvemLogin.fechar();
      this.$emit("dialog-off");
    },
    async entrar() {
      var authStore = useAuthStore();

      //* Limpa os erros
      this.itemErros = api.errosLimpar(this.itemErros);

      var result = await authStore.adicionarNuvem(
        this.item.nomeUsuario.toLowerCase(),
        this.item.senha,
        this.$router
      );

      if (result.erro == true) {
        api.errosProcessar(this.itemErros, result.erros);
      } else {
        //* Fecha a janela
        this.fechar();
      }
    },
    enterEntrar(event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        this.entrar();
        return false;
      }
    },
  },
};
</script>

<style>
</style>
