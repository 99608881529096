<template>
  <!--begin::Timeline-->
  <div class="timeline" :class="{ 'ms-10': diretorio.lazy == true }">
    <!--begin::Timeline item-->
    <div class="timeline-item align-items-center mb-4">
      <!--begin::Timeline icon-->
      <div class="timeline-icon" style="margin-left: 11px">
        <!--begin::Svg Icon | path: icons/duotune/general/gen018.svg-->
        <span
          class="svg-icon svg-icon-2"
          :class="{
            'svg-icon-primary':
              diretorio.status == 'concluido' ||
              diretorio.status == 'processando' ||
              (diretorio.status == 'fila' && diretorio.isSubdiretorio == true),
            'svg-icon-secondary':
              diretorio.status == 'fila' && diretorio.isSubdiretorio == false,
          }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-folder-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M9.828 3h3.982a2 2 0 0 1 1.992 2.181l-.637 7A2 2 0 0 1 13.174 14H2.825a2 2 0 0 1-1.991-1.819l-.637-7a1.99 1.99 0 0 1 .342-1.31L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3zm-8.322.12C1.72 3.042 1.95 3 2.19 3h5.396l-.707-.707A1 1 0 0 0 6.172 2H2.5a1 1 0 0 0-1 .981l.006.139z"
            />
          </svg>
        </span>

        <!--end::Svg Icon-->
      </div>
      <!--end::Timeline icon-->
      <!--begin::Timeline content-->
      <div class="timeline-content m-0 text-start">
        <!--begin::Title-->
        <span class="fs-8 text-gray-400 fw-semibold d-block"
          >{{ diretorio.diretorioCaminho }}
          <span
            class="text-gray-800"
            v-if="
              (storeUpload.fila[fila].local ||
                storeUpload.fila[fila].lazy == false) &&
              diretorio.arquivos.length > 0
            "
          >
            - {{ diretorio.concluidos.length }} de
            {{ diretorio.arquivos.length }}
            transferidos</span
          >

          <button
            class="btn btn-light ms-3 me-3 p-2 py-1"
            :disabled="diretorio.arquivos.length == 0"
            @click="
              iniciarArquivo(
                diretorio.arquivos.length > 0 ? diretorio.arquivos[0] : ''
              )
            "
            v-if="false"
          >
            <span class="svg-icon svg-icon-1x me-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-play"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
                />
              </svg>
            </span>
          </button>
          <button
            type="button"
            class="btn btn-light me-3 p-2 py-1"
            @click="
              pausarArquivo(
                diretorio.arquivos.length > 0 ? diretorio.arquivos[0] : ''
              )
            "
            v-if="false"
          >
            <span class="svg-icon svg-icon-1x me-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-pause-circle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                />
                <path
                  d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"
                />
              </svg>
            </span>
          </button>
          <button
            type="button"
            class="btn btn-light ms-2 me-0 p-2 py-1"
            @click="storeUpload.removerDiretorio(fila, diretorio.id)"
            v-if="storeUpload.fila[fila].local && storeUpload.fila[fila].status == 'fila'"
          >
            <span class="svg-icon svg-icon-1x me-0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-trash"
                viewBox="0 0 16 16"
              >
                <path
                  d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                />
                <path
                  fill-rule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </span>
          </button>
        </span>
        <!--end::Title-->
        <!--begin::Title-->
        <span
          class="svg-icon svg-icon-2 svg-icon-success me-2"
          v-if="
            diretorio.status == 'concluido' &&
            (index > 0 || storeUpload.fila[fila].status == 'concluido')
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-check2-all"
            viewBox="0 0 16 16"
          >
            <path
              d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0l7-7zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0z"
            />
            <path
              d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708z"
            />
          </svg>
        </span>
        <span class="fs-5 fw-bold text-gray-800">{{
          diretorio.diretorioRaiz == null
            ? diretorio.diretorioNome
            : storeUpload.fila[fila].diretorios[diretorio.diretorioRaiz]
                .diretorioNome +
              diretorio.diretorioCaminho.substring(
                storeUpload.fila[fila].diretorios[diretorio.diretorioRaiz]
                  .diretorioCaminho.length
              )
        }}</span>
        <!--end::Title-->
      </div>
      <!--end::Timeline content-->
    </div>
    <!--end::Timeline item-->

    <div v-for="arquivo in diretorio.arquivos" :key="arquivo">
      <ComponentFilaDiretorioArquivo
        :diretorio="diretorio"
        :arquivo="arquivo"
        :fila="fila"
      />
    </div>
  </div>
  <!--end::Timeline-->
</template>

<script>
import { useStoreUpload } from "../stores/storeUpload";
import ComponentFilaDiretorioArquivo from "../components/ComponentFilaDiretorioArquivo.vue";

export default {
  components: { ComponentFilaDiretorioArquivo },
  props: {
    diretorio: { type: Object, default: () => {} },
    fila: { type: String, default: "" },
    index: { type: Number, default: null },
  },
  setup() {
    const storeUpload = useStoreUpload();

    return { storeUpload };
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {
    async pausarArquivo(fileId) {
      this.storeUpload.pausarUploadArquivo(fileId);
    },
    async iniciarArquivo(fileId) {
      this.storeUpload.iniciarTransferenciaArquivo(fileId);
    },
    async removerArquivo(fileId) {
      this.storeUpload.removerArquivo(fileId);
    },
  },
};
</script>
