<template>
  <div>
    <div class="d-flex justify-content-center mx-6">
      <div>
        <button
          class="btn ms-1 me-1 pe-4"
          :class="{
            'btn-info': aba == 'local',
            'p-3': aba == 'local',
            'p-2': aba !== 'local',
            'btn-outline': aba !== 'local',
            'btn-outline-dashed': aba !== 'local',
            'btn-outline-info': aba !== 'local',
            'btn-active-light-info': aba !== 'local',
          }"
          @click="trocarAba('local')"
        >
          <span class="svg-icon svg-icon-1 m-0"
            ><i class="bi bi-pc-display-horizontal fs-1x gx-5 p-2 pe-3"></i
          ></span>
          Online
        </button>
        <button
          class="btn ms-1 me-1 pe-4"
          :class="{
            'btn-info': aba == 'remoto',
            'p-3': aba == 'remoto',
            'p-2': aba !== 'remoto',
            'btn-outline': aba !== 'remoto',
            'btn-outline-dashed': aba !== 'remoto',
            'btn-outline-info': aba !== 'remoto',
            'btn-active-light-info': aba !== 'remoto',
          }"
          @click="trocarAba('remoto')"
        >
          <span class="svg-icon svg-icon-1 m-0"
            ><i class="bi bi-cloud-fill fs-1x gx-5 p-2 pe-3"></i
          ></span>
          Remoto
        </button>
        <button
          class="btn ms-1 me-1 pe-4"
          :class="{
            'btn-info': aba == 'erros',
            'p-3': aba == 'erros',
            'p-2': aba !== 'erros',
            'btn-outline': aba !== 'erros',
            'btn-outline-dashed': aba !== 'erros',
            'btn-outline-info': aba !== 'erros',
            'btn-active-light-info': aba !== 'erros',
          }"
          @click="trocarAba('erros')"
        >
          <span class="svg-icon svg-icon-1 m-0"
            ><i class="bi bi-x-octagon-fill fs-1x gx-5 p-2 pe-3"></i
          ></span>
          Erros
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-center mx-6 mt-5">
      <button
        class="btn btn-success ms-3 me-3 p-2 py-2"
        :disabled="storeUpload.status == 'processando'"
        @click="
          storeUpload.status = 'processando';
          storeUpload.filasProximoArquivoTimer();
        "
        v-if="storeUpload.status !== 'processando' && aba == 'local'"
      >
        <span class="svg-icon svg-icon-2x me-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-play"
            viewBox="0 0 16 16"
          >
            <path
              d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
            />
          </svg>
        </span>
        Iniciar fila
      </button>
      <button
        type="button"
        class="btn btn-white me-3 p-2 py-2"
        :disabled="storeUpload.status == 'pausado'"
        @click="storeUpload.status = 'pausado'"
        v-if="storeUpload.status !== 'pausado' && aba == 'local'"
      >
        <span class="svg-icon svg-icon-2x me-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-pause-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              d="M5 6.25a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5zm3.5 0a1.25 1.25 0 1 1 2.5 0v3.5a1.25 1.25 0 1 1-2.5 0v-3.5z"
            />
          </svg>
        </span>
        Pausar fila
      </button>
    </div>

    <p :class="{ 'd-none': !listaVazia }" style="margin-top: 100px">
      Nenhum arquivo na lista
    </p>

    <div v-for="filaKey in filaReverse" :key="filaKey">
      <div class="card card-flush mt-3 mx-6" :class="{ 'd-none': listaVazia }">
        <!--begin::Body-->
        <div class="card-body pt-3 mt-5">
          <div class="tab-pane fade show active" role="tabpanel">
            <!--begin::Item-->
            <div class="m-0">
              <!--begin::Wrapper-->
              <div>
                <!--begin::Section-->
                <div class="d-flex justify-content-between">
                  <div class="text-start">
                    <span class="text-gray-800 fs-9 fw-semibold mt-1"
                      >{{
                        $funcoes.formatarDataHora(
                          storeUpload.fila[filaKey].data
                        )
                      }}
                      <button
                        type="button"
                        class="btn btn-light me-0 p-2 py-1"
                        v-if="false"
                      >
                        <span class="svg-icon svg-icon-1x me-0">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </span></button
                    ></span>
                    <span
                      class="text-gray-800 fw-bold d-block fs-6"
                      style="text-transform: capitalize"
                      >{{ storeUpload.fila[filaKey].tipo }}</span
                    >
                  </div>
                  <div class="text-end">
                    <span
                      class="svg-icon svg-icon-3x"
                      :class="{
                        'svg-icon-primary':
                          Object.keys(storeUpload.fila[filaKey].erros).length ==
                          0,
                        'svg-icon-danger':
                          Object.keys(storeUpload.fila[filaKey].erros).length >
                          0,
                      }"
                      v-if="
                        storeUpload.fila[filaKey].tipo == 'upload' &&
                        storeUpload.fila[filaKey].status == 'fila'
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-cloud-arrow-up-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2z"
                        />
                      </svg>
                    </span>
                    <span
                      class="svg-icon svg-icon-3x"
                      :class="{
                        'svg-icon-primary':
                          Object.keys(storeUpload.fila[filaKey].erros).length ==
                          0,
                        'svg-icon-danger':
                          Object.keys(storeUpload.fila[filaKey].erros).length >
                          0,
                      }"
                      v-if="
                        storeUpload.fila[filaKey].tipo == 'download' &&
                        storeUpload.fila[filaKey].status == 'fila'
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-cloud-arrow-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2zm2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708z"
                        />
                      </svg>
                    </span>
                    <span
                      class="svg-icon svg-icon-3x"
                      :class="{
                        'svg-icon-primary':
                          Object.keys(storeUpload.fila[filaKey].erros).length ==
                          0,
                        'svg-icon-danger':
                          Object.keys(storeUpload.fila[filaKey].erros).length >
                          0,
                      }"
                      v-if="
                        storeUpload.fila[filaKey].tipo == 'copia' &&
                        storeUpload.fila[filaKey].status == 'fila'
                      "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-files"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"
                        />
                      </svg>
                    </span>
                    <span
                      class="svg-icon svg-icon-3x"
                      :class="{
                        'svg-icon-success':
                          Object.keys(storeUpload.fila[filaKey].erros).length ==
                          0,
                        'svg-icon-danger':
                          Object.keys(storeUpload.fila[filaKey].erros).length >
                          0,
                      }"
                      v-if="storeUpload.fila[filaKey].status == 'concluido'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-cloud-check"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10.354 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                        />
                        <path
                          d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <!--end::Section-->
              </div>
              <!--end::Wrapper-->

              <div class="separator separator-dashed my-4"></div>

              <div v-if="storeUpload.fila[filaKey] !== undefined">
                <div
                  v-for="(
                    diretorio, diretorioKey, diretorioIndex
                  ) in storeUpload.fila[filaKey].subdiretorios"
                  :key="diretorioKey"
                >
                  <ComponentFilaDiretorio
                    :diretorio="
                      storeUpload.fila[filaKey].diretorios[diretorioKey]
                    "
                    :fila="filaKey"
                    :index="diretorioIndex"
                    v-if="
                      storeUpload.fila[filaKey].diretorios[diretorioKey] &&
                      storeUpload.fila[filaKey].diretorios[diretorioKey]
                        .subdiretorio == '' &&
                      (aba == 'local' || aba == 'remoto')
                    "
                  /><ComponentFilaDiretorio
                    v-if="
                      storeUpload.fila[filaKey].diretorios[diretorioKey] &&
                      storeUpload.fila[filaKey].diretorios[diretorioKey]
                        .subdiretorio !== '' &&
                      (aba == 'local' || aba == 'remoto')
                    "
                    :fila="filaKey"
                    :index="diretorioIndex"
                    :diretorio="
                      storeUpload.fila[filaKey].diretorios[
                        storeUpload.fila[filaKey].diretorios[diretorioKey]
                          .subdiretorio
                      ]
                    "
                  />

                  <div v-if="aba == 'erros'">
                    <div
                      v-for="(arquivo, arquivoKey) in storeUpload.fila[filaKey]
                        .erros"
                      :key="arquivoKey"
                    >
                      <div class="timeline">
                        <ComponentFilaDiretorioArquivo
                          :diretorio="
                            storeUpload.fila[filaKey].diretorios[
                              storeUpload.arquivos[arquivoKey].diretorioId
                            ]
                          "
                          :arquivo="arquivoKey"
                          :fila="storeUpload.arquivos[arquivoKey].filaId"
                          :view="'simples'"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Item-->
          </div>
        </div>
        <!--end: Card Body-->
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";
//import { useAuthStore } from "@/stores/auth.js";
import { useStoreUpload } from "../stores/storeUpload";
import ComponentFilaDiretorio from "./ComponentFilaDiretorio.vue";
import ComponentFilaDiretorioArquivo from "./ComponentFilaDiretorioArquivo.vue";

//const FileAPI = require("file-api");

export default {
  components: { ComponentFilaDiretorio, ComponentFilaDiretorioArquivo },
  props: {},
  setup() {
    const storeUpload = useStoreUpload();

    return { storeUpload };
  },
  data() {
    return {
      arquivos: {},
      aba: "local",
    };
  },
  computed: {
    listaVazia() {
      return this.filaReverse.length == 0;
    },
    filaReverse() {
      var thisCall = this;
      return Object.keys(this.storeUpload.fila)
        .filter(
          (item) =>
            (thisCall.aba == "local"
              ? thisCall.storeUpload.fila[item].local == true
              : true) &&
            (thisCall.aba == "remoto"
              ? thisCall.storeUpload.fila[item].local == false
              : true) &&
            (thisCall.aba == "erros"
              ? Object.keys(thisCall.storeUpload.fila[item].erros).length > 0
              : true)
        )
        .reverse();
    },
  },

  mounted() {},
  methods: {
    teste() {
      this.storeUpload.novoArquivo();
    },
    async novoArquivo() {
      var formData = { fileName: "nginx.conf" };
      var thisCall = this;

      api
        .post("/upload/request", formData)
        .then(async function (result) {
          var arquivo = {
            fileId: result.data.fileId,
            fileName: formData.fileName,
            startingByte: 0,
            progresso: 0,
            file: null,
            request: null,
            onProgress: null,
            onComplete: null,
            onError: null,
            onTimeout: null,
            onAbort: null,
            onError: null,
          };

          //* Atualiza o progresso
          arquivo.onProgress = (e, arquivo) => {
            const loaded = arquivo.startingByte + e.loaded;
            // thisCall.arquivos[arquivo.fileId].progresso = (loaded * 100) / arquivo.file.size;
            thisCall.arquivos[arquivo.fileId].progresso =
              (loaded * 100) / e.total;
          };

          //* Carrega o conteudo do arquivo
          arquivo.file = await thisCall.loadFile(
            "/Users/danielnogueira/hd/temp/nginx.conf"
          );

          //"/Users/danielnogueira/hd/temp/newwebsuita_dsm2.sql"

          //* Insere o arquivo na lista
          thisCall.arquivos[arquivo.fileId] = arquivo;

          //* Processa o upload do arquivo
          await api.uploadFile(arquivo);
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    async loadFile(path) {
      /*
      const filePost = new FileAPI.File(path);
      const fileStream = fs.createReadStream(path);
      const blob = await streamToBlob(fileStream);

      filePost.blob = blob;
      filePost.size = blob.size;
      filePost.slice = function (start) {
        return this.blob.slice(start);
      };

      return filePost;

      */
    },
    dataURIToBlob(dataURI) {
      const splitDataURI = dataURI.split(",");
      const byteString =
        splitDataURI[0].indexOf("base64") >= 0
          ? atob(splitDataURI[1])
          : decodeURI(splitDataURI[1]);
      const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

      return new Blob([ia], { type: mimeString });
    },
    async trocarAba(novaAba) {
      this.aba = novaAba;

      if (novaAba == "remoto") {
        this.storeUpload.carregarTransferencias();
      }
    },
  },
};
</script>

<style scoped>
</style>
