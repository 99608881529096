<template>
  <div
    class="row justify-content-center prevent-select"
    style="margin-top: 0px"
  >
    <div class="text-center col-12">
      <TransferenciasDialog ref="dialogTransferencias"  />
      <ViewDialog ref="viewDialog"/>  

      <div class="row">
        <div class="col-12">
          <DiretorioList ref="direita" instancia="direita" tipo="remoto" @abrir-arquivo="abrirArquivo" />
        </div>
      </div>

      <div class="row" style="margin-top: 10px" v-if="false">
        <div class="col-12">
          <P2P />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DiretorioList from "../../../../_shared/features/DiretorioList.vue";
import TransferenciasDialog from "../../../../_shared/features/TransferenciasDialog.vue";
import P2P from "../../../../_shared/components/ComponentP2P.vue";
import ViewDialog from "../../../../_shared/features/ViewDialog.vue";

import { useStoreArvore } from "../../../../_shared/stores/storeArvore.js";

//* XX const electron = require("electron");

export default {
  name: "HomeApp",
  components: { DiretorioList, P2P, TransferenciasDialog, ViewDialog },
  setup() {
    const storeArvore = useStoreArvore();

    return { storeArvore };
  },
  methods: {
    abrirArquivo(item)
    {
      
      this.$refs.viewDialog.carregar(item);
      //this.$refs.viewDialog.abrir();

    }, 
    uploadArquivo() {},
    keyDownEvent(event) {

      var thisCall = this;

      //* Verifica se consta alguma dialog aberta
      if(thisCall.storeArvore.dialogAberta)
      {
        return;
      }

      var key = event.which || event.keyCode;

      //* Colar
      if (key == 38) {
        thisCall.storeArvore.selecionarProximoItem(
          thisCall.storeArvore.instanciaSelecionada,
          -1
        );
        event.preventDefault();
        return false;
      } else if (key == 40) {
        thisCall.storeArvore.selecionarProximoItem(
          thisCall.storeArvore.instanciaSelecionada,
          1
        );
        event.preventDefault();
        return false;
      }

      // 38 40
    },
    keyUpEvent(event) {

      var thisCall = this;

      //* Verifica se consta alguma dialog aberta
      if(thisCall.storeArvore.dialogAberta)
      {
        return;
      }

      var key = event.which || event.keyCode;

      //* Verifica se o control esta pressionado
      var ctrl = event.ctrlKey ? event.ctrlKey : key === 17 ? true : false;

      //* Colar
      if (key == 86 && ctrl) {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.colar(thisCall.storeArvore.instanciaSelecionada);
        }
        //* Copiar
      } else if (key == 67 && ctrl) {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.copiar(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (key == 88 && ctrl) {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.recortar(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (key == 46) {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.$refs[
            thisCall.storeArvore.instanciaSelecionada
          ].excluirItem();
        }
      } else if (key == 116) {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.atualizar(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (key == 113) {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.renomear(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (key == 84) {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.transferir(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (key == 13) {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          var keysSelecionadas = Object.keys(
            thisCall.storeArvore.instancias[
              thisCall.storeArvore.instanciaSelecionada
            ].selecionados
          );

          if (keysSelecionadas.length == 1) {
            thisCall.storeArvore.navegarArvore(
              thisCall.storeArvore.instanciaSelecionada,
              thisCall.storeArvore.instancias[
                thisCall.storeArvore.instanciaSelecionada
              ].selecionados[keysSelecionadas[0]]
            );
          }
        }
      } else if (key == 8) {
        //* Verifica se pode retornar ao diretorio anterior
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          if (
            thisCall.storeArvore.instancias[
              thisCall.storeArvore.instanciaSelecionada
            ].itens.length > 0
          ) {
            if (
              thisCall.storeArvore.instancias[
                thisCall.storeArvore.instanciaSelecionada
              ].itens[0].nome == "... "
            ) {
              thisCall.storeArvore.navegarArvore(
                thisCall.storeArvore.instanciaSelecionada,
                thisCall.storeArvore.instancias[
                  thisCall.storeArvore.instanciaSelecionada
                ].itens[0]
              );
            }
          }
        }
      }
    },
  },
  beforeCreate: function () {
    document.body.className = document.body.className + " bodyOverflow";

    //setTimeout(function () {
    //window.KTApp.init();
    //window.KTMenu.init();
    //window.KTApp.initBootstrapTooltips();
    //}, 200);
  },
  beforeUnmount: function () {
    window.removeEventListener("keydown", this.keyDownEvent);
    window.removeEventListener("keyup", this.keyUpEvent);
  },
  mounted() {
    //* XX var thisCall = this;

    window.addEventListener("keydown", this.keyDownEvent, false);
    window.addEventListener("keyup", this.keyUpEvent, false);

    //* Limpa eventos
    //* XX electron.ipcRenderer._events = {};

    //* Trata os menus enviados
    /* XX electron.ipcRenderer.on("/arvore/comando", (sender, parametros) => {
      //* Trata a respectiva funcao do menu
      if (parametros.funcao == "atualizar") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.atualizar(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (parametros.funcao == "transferir") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.transferir(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (parametros.funcao == "copiar") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.copiar(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (parametros.funcao == "recortar") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.recortar(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (parametros.funcao == "colar") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.colar(thisCall.storeArvore.instanciaSelecionada);
        }
      } else if (parametros.funcao == "abrir") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.abrirArquivoOS(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (parametros.funcao == "renomear") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.renomear(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      } else if (parametros.funcao == "excluir") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.$refs[
            thisCall.storeArvore.instanciaSelecionada
          ].excluirItem();
        }
      } else if (parametros.funcao == "novo-diretorio") {
        if (thisCall.storeArvore.instanciaSelecionada !== "") {
          thisCall.storeArvore.diretorioCriar(
            thisCall.storeArvore.instanciaSelecionada
          );
        }
      }
    });
  
    */
  },
};
</script>

<style>
.bodyOverflow {
  height: 100%;
  position: fixed;
  overflow-y: hidden;
  width: 100%;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>
