<template>
  <div class="row justify-content-center" style="margin-top: 10px">
    <div class="text-center col-8">
      <div class="row justify-content-center" style="margin-bottom: 20px">
        <div class="text-center col-10">
          <h5>Backup</h5>
          <h5 class="text-secondary">
            <small
              >Gerencie rotinas de backup dos seus dados de forma
              automatica</small
            >
          </h5>
        </div>
      </div>

      <div class="row justify-content-center" style="">
        <div class="text-center col-10">
          <div class="row" style="margin-bottom: 10px">
            <div class="col-12">
              <div
                class="btn-toolbar justify-content-between align-items-end"
                role="toolbar"
                style="margin-left: 2px; margin-right: 2px"
              >
                <div>
                  <h3 contenteditable="true" style="margin-bottom: 0">
                    Entre com o nome...
                  </h3>
                </div>

                <div style="zoom: 80%">
                  <button type="button" class="btn btn-outline-secondary">
                    Salvar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr style="background-color: black" />
        </div>
      </div>

      <div class="row justify-content-center" style="margin-bottom: 20px">
        <div class="text-center col-10">
          <div class="form-group">
            <h6 class="text-start">Diretorio local</h6>
            <input
              type="text"
              class="form-control"
              placeholder="Entre com o diretorio local"
            />
          </div>
          <div class="form-group" style="margin-top: 20px">
            <h6 class="text-start">Diretorio remoto</h6>
            <input
              type="text"
              class="form-control"
              placeholder="Entre com o diretorio remoto"
            />
          </div>
          <div class="form-group" style="margin-top: 20px">
            <h6 class="text-start">Direção</h6>
            <div class="form-check d-flex justify-content-start">
              <input
                class="form-check-input"
                type="radio"
                value="direcao1"
                style="margin-right: 10px"
                checked
              />
              <label class="form-check-label">
                Copia do diretorio local para o diretorio remoto
              </label>
            </div>
            <div class="form-check d-flex justify-content-start">
              <input
                class="form-check-input"
                type="radio"
                value="direcao1"
                style="margin-right: 10px"
              />
              <label class="form-check-label">
                Copia do diretorio remoto para o diretorio local
              </label>
            </div>
            <div class="form-check d-flex justify-content-start">
              <input
                class="form-check-input"
                type="radio"
                value="direcao1"
                style="margin-right: 10px"
              />
              <label class="form-check-label">Copia de ambos os lados </label>
            </div>
          </div>
          <div class="form-group" style="margin-top: 20px">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Dia</th>
                  <th scope="col">Mes</th>
                  <th scope="col">Ano</th>
                  <th scope="col">Semana</th>
                  <th scope="col">Hora</th>
                  <th scope="col">Minuto</th>
                  <th scope="col">Segundo</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
              </tbody>
            </table>
            <button type="button" class="btn btn-outline-secondary">
              Agendar execucao
            </button>
          </div>

          <div class="form-group" style="margin-top: 20px">
            <h6 class="text-start">Opções</h6>
            <div class="form-check form-switch d-flex justify-content-start">
              <input
                class="form-check-input"
                type="checkbox"
                style="margin-right: 10px"
              />
              <label class="form-check-label">Incluir sub-pastas</label>
            </div>
            <div class="form-check form-switch d-flex justify-content-start">
              <input
                class="form-check-input"
                type="checkbox"
                style="margin-right: 10px"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Manter data</label
              >
            </div>
            <div class="form-check form-switch d-flex justify-content-start">
              <input
                class="form-check-input"
                type="checkbox"
                style="margin-right: 10px"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Comprimir</label
              >
            </div>
            <div class="form-check form-switch d-flex justify-content-start">
              <input
                class="form-check-input"
                type="checkbox"
                style="margin-right: 10px"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Manter data atual</label
              >
            </div>
            <div class="form-check form-switch d-flex justify-content-start">
              <input
                class="form-check-input"
                type="checkbox"
                style="margin-right: 10px"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Substituir arquivos</label
              >
            </div>
            <div class="form-check form-switch d-flex justify-content-start">
              <input
                class="form-check-input"
                type="checkbox"
                style="margin-right: 10px"
              />
              <label class="form-check-label" for="flexSwitchCheckDefault"
                >Substituir mais novo</label
              >
            </div>
          </div>

          <div class="form-group" style="margin-top: 20px">
            <h6 class="text-start">Criptografia</h6>
            <div class="form-check d-flex justify-content-start">
              <input
                class="form-check-input"
                type="radio"
                value="direcao1"
                style="margin-right: 10px"
                checked
              />
              <label class="form-check-label"> Sem criptografia </label>
            </div>
            <div class="form-check d-flex justify-content-start">
              <input
                class="form-check-input"
                type="radio"
                value="direcao1"
                style="margin-right: 10px"
              />
              <label class="form-check-label"> Média </label>
            </div>
            <div class="form-check d-flex justify-content-start">
              <input
                class="form-check-input"
                type="radio"
                value="direcao1"
                style="margin-right: 10px"
              />
              <label class="form-check-label">Alta</label>
            </div>
          </div>

          <div class="row" style="margin-top: 20px">
            <h6 class="text-start">Filtrar arquivos</h6>
            <div class="col col-md-6">
              <div class="form-group">
                <h6 class="text-start text-secondary">Incluir</h6>
                <input type="text" class="form-control" placeholder="*.*" />
              </div>
            </div>
            <div class="col col-md-6">
              <div class="form-group">
                <h6 class="text-start text-secondary">Ignorar</h6>
                <input type="text" class="form-control" placeholder="*.*" />
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 20px">
            <h6 class="text-start">Filtrar pastas</h6>
            <div class="col col-md-6">
              <div class="form-group">
                <h6 class="text-start text-secondary">Incluir</h6>
                <input type="text" class="form-control" placeholder="*.*" />
              </div>
            </div>
            <div class="col col-md-6">
              <div class="form-group">
                <h6 class="text-start text-secondary">Ignorar</h6>
                <input type="text" class="form-control" placeholder="*.*" />
              </div>
            </div>
          </div>

          <div class="form-group" style="margin-top: 20px">
            <h6 class="text-start">Enviar informacoes por e-mail</h6>
            <input
              type="text"
              class="form-control"
              placeholder="email@exemplo.com"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "CrontEdit",
  components: {},
  beforeCreate: function () {
    document.body.classList.remove("bodyOverflow");
  },
};
</script>
