<template>
  <div>
    <Dialog
      :id="'dialogSelectGrupo'"
      ref="dialogSelectGrupo"
      :hide-header="false"
      :hideFooter="true"
      :modal="true"
      :tamanho="'md'"
      :classModalBody="'mx-5 mx-xl-1 my-1'"
      :titulo="'Grupos'"
      @dialog-off="fechar()"
    >
      <GrupoSelect
        ref="grupoSelect"
        :dialog="true"
        @selecionar="selecionar()"
      />
    </Dialog>
  </div>
</template>

<script>
import GrupoSelect from "./GrupoSelect.vue";
import Dialog from "../components/ComponentDialog.vue";

export default {
  components: { GrupoSelect, Dialog },
  props: {},

  data() {
    return {
      filtroNuvem: "",
      filtroCliente: "",
    };
  },

  mounted() {},

  methods: {
    abrir(itensSelecionadosPadrao = {}) {
      this.filtroNuvem = "";
      this.filtroCliente = "";
      this.$refs.grupoSelect.filtroTexto = "";
      this.$refs.grupoSelect.itensSelecionados = {};
      this.$refs.grupoSelect.itensSelecionadosPadrao = itensSelecionadosPadrao;
      this.$refs.grupoSelect.filtroCliente = this.filtroCliente;
      this.$refs.grupoSelect.filtroNuvem = this.filtroNuvem;
      this.$refs.grupoSelect.carregarItens();
      this.$refs.dialogSelectGrupo.abrir();
    },
    abrirCliente(cliente, itensSelecionadosPadrao = {}) {
      this.filtroNuvem = "";
      this.filtroCliente = cliente;
      this.$refs.grupoSelect.filtroTexto = "";
      this.$refs.grupoSelect.itensSelecionados = {};
      this.$refs.grupoSelect.itensSelecionadosPadrao = itensSelecionadosPadrao;
      this.$refs.grupoSelect.filtroCliente = this.filtroCliente;
      this.$refs.grupoSelect.filtroNuvem = this.filtroNuvem;
      this.$refs.grupoSelect.carregarItens();
      this.$refs.dialogSelectGrupo.abrir();
    },
    abrirNuvem(nuvem, itensSelecionadosPadrao = {}) {
      this.filtroNuvem = nuvem;
      this.filtroCliente = "";
      this.$refs.grupoSelect.filtroTexto = "";
      this.$refs.grupoSelect.itensSelecionados = {};
      this.$refs.grupoSelect.itensSelecionadosPadrao = itensSelecionadosPadrao;
      this.$refs.grupoSelect.filtroCliente = this.filtroCliente;
      this.$refs.grupoSelect.filtroNuvem = this.filtroNuvem;
      this.$refs.grupoSelect.carregarItens();
      this.$refs.dialogSelectGrupo.abrir();
    },
    selecionar() {
      this.$refs.dialogSelectGrupo.fechar();
      this.$emit("selecionar", this.$refs.grupoSelect.itensSelecionados);
    },
    fechar() {
      this.$emit("cancelar");
    },
  },
};
</script>

<style>
</style>
