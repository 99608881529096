<template>
  <div>
    <div class="row gy-5 g-xl-8">
      <div class="mb-0" :class="{ 'mt-0': !dialog }">
        <div class="border-0 pt-0" v-if="dialog">
          <div class="col">
            <div class="row">
              <div class="col-lg-12 fv-row">
                <div class="fv-row mb-0 text-center">
                  <button
                    type="button"
                    class="btn btn-light-primary me-3"
                    @click="abrirFiltro"
                  >
                    <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
                    <span class="svg-icon svg-icon-2"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    Filtrar
                  </button>
                  <button type="button" class="btn btn-light-primary me-3">
                    <!--begin::Svg Icon | path: icons/duotune/arrows/arr078.svg-->
                    <span class="svg-icon svg-icon-2"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          opacity="0.3"
                          x="12.75"
                          y="4.25"
                          width="12"
                          height="2"
                          rx="1"
                          transform="rotate(90 12.75 4.25)"
                          fill="currentColor"
                        ></rect>
                        <path
                          d="M12.0573 6.11875L13.5203 7.87435C13.9121 8.34457 14.6232 8.37683 15.056 7.94401C15.4457 7.5543 15.4641 6.92836 15.0979 6.51643L12.4974 3.59084C12.0996 3.14332 11.4004 3.14332 11.0026 3.59084L8.40206 6.51643C8.0359 6.92836 8.0543 7.5543 8.44401 7.94401C8.87683 8.37683 9.58785 8.34458 9.9797 7.87435L11.4427 6.11875C11.6026 5.92684 11.8974 5.92684 12.0573 6.11875Z"
                          fill="currentColor"
                        ></path>
                        <path
                          opacity="0.3"
                          d="M18.75 8.25H17.75C17.1977 8.25 16.75 8.69772 16.75 9.25C16.75 9.80228 17.1977 10.25 17.75 10.25C18.3023 10.25 18.75 10.6977 18.75 11.25V18.25C18.75 18.8023 18.3023 19.25 17.75 19.25H5.75C5.19772 19.25 4.75 18.8023 4.75 18.25V11.25C4.75 10.6977 5.19771 10.25 5.75 10.25C6.30229 10.25 6.75 9.80228 6.75 9.25C6.75 8.69772 6.30229 8.25 5.75 8.25H4.75C3.64543 8.25 2.75 9.14543 2.75 10.25V19.25C2.75 20.3546 3.64543 21.25 4.75 21.25H18.75C19.8546 21.25 20.75 20.3546 20.75 19.25V10.25C20.75 9.14543 19.8546 8.25 18.75 8.25Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    <!--end::Svg Icon-->
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="py-3">
          <div class="table-responsive">
            <table
              class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
            >
              <thead>
                <tr class="fw-bolder text-dark">
                  <th style="width: 120px" class="text-center">
                    Atividades ({{ itensTotal }})
                  </th>
                  <th style="width: 120px" class="text-center">Tipo</th>
                  <th style="width: 200px" class="text-start">Usuário</th>
                  <th class="text-start">Descrição</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in itens" :key="item._id" :item="item">
                  <td class="text-start">
                    <span
                      class="text-center text-dark fw-bold d-block fs-7 mt-1"
                    >
                      {{ $funcoes.formatarDataHora(item.data) }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="text-center text-muted fw-bold text-muted d-block fs-7 mt-2"
                      >{{ item.tipo }}</span
                    >
                  </td>
                  <td class="text-start">
                    <div
                      class="d-flex align-items-center"
                      v-if="usuarios[item.usuario]"
                    >
                      <div class="symbol symbol-45px me-5">
                        <img
                          :src="
                            $funcoes.usuarioFoto(
                              item.usuario,
                              usuarios[item.usuario].foto == true ? true : false
                            )
                          "
                          alt=""
                        />
                      </div>
                      <div class="d-flex justify-content-start flex-column">
                        <span class="text-dark fw-bolder fs-6">
                          {{ usuarios[item.usuario].nome }}
                        </span>
                        <span class="text-muted fw-bold d-block fs-7">{{
                          usuarios[item.usuario].nomeUsuario
                        }}</span>
                      </div>
                    </div>
                  </td>
                  <td class="">
                    <div>
                      <div v-if="item.tipo == 'COPIA'">
                        <span class="text-muted d-block fs-8"><span class="fw-bold text-dark">De: </span>{{ item.dados.de }}</span>
                        <span class="text-muted d-block fs-8"><span class="fw-bold text-dark">Para: </span>{{ item.dados.para }}</span>                        
                      </div>
                      <div v-if="item.tipo == 'DOWNLOAD'">
                        <span class="text-muted d-block fs-8" v-if="item.dados.fullPath">{{
                          item.dados.fullPath
                        }}</span>
                        <span class="text-muted d-block fs-8" v-if="!item.dados.fullPath">{{
                          (item.dados.diretorio
                            ? item.dados.diretorio.fullPath + "/"
                            : "/") +
                          item.dados.nome +
                          item.dados.tipo
                        }}</span>
                      </div>
                      <div v-if="item.tipo == 'UPLOAD'">
                        <span class="fw-bold text-dark d-block fs-8">{{
                          item.dados.novo ? "Novo arquivo" : "Arquivo alterado"
                        }}</span>
                        <span class="text-muted d-block fs-8" v-if="!item.dados.diretorio && item.dados.fullPath">{{
                          item.dados.fullPath
                        }}</span>
                        <span class="text-muted d-block fs-8" v-if="!item.dados.diretorio && !item.dados.fullPath">{{
                          "/" + item.dados.nome + item.dados.tipo
                        }}</span>
                         <span class="text-muted d-block fs-8" v-if="item.dados.diretorio">{{
                          item.dados.diretorio.fullPath + "/" + item.dados.nome + item.dados.tipo
                        }}</span>
                      </div>
                      <div v-if="item.tipo == 'RENOMEAR' && item.arquivo">
                        <span class="text-muted d-block fs-8" v-if="item.dados.fullPath">{{item.dados.fullPath}}</span>
                        <span class="text-muted d-block fs-8" v-if="!item.dados.fullPath && item.dados.diretorio">{{item.dados.diretorio.fullPath}}/{{item.dados.nome + item.dados.tipo}}</span>
                        <span class="text-muted d-block fs-8" v-if="!item.dados.fullPath && !item.dados.diretorio">/{{item.dados.nome + item.dados.tipo}}</span>
                        <span class="text-muted d-block fs-8">
                          <span class="text-gray800 d-block"><span class="fw-bold text-dark">De: </span>{{ item.dados.nome + item.dados.tipo }}</span>                          
                          <span class="text-gray800 d-block"><span class="fw-bold text-dark">Para: </span>{{ item.dados.novoNome + item.dados.novoTipo }}</span>
                        </span>
                      </div>
                      <div v-if="item.tipo == 'RENOMEAR' && item.diretorio">
                        <span class="text-muted d-block fs-8">{{
                          item.dados.fullPath
                        }}</span>
                        <span class="text-muted d-block fs-8">
                          <span class="text-gray800  d-block"><span class="fw-bold text-dark">De: </span>{{ item.dados.nome }}</span>
                          <span class="text-gray800  d-block"><span class="fw-bold text-dark">Para: </span>{{ item.dados.novoNome }}</span>
                        </span>
                      </div>
                      <div v-if="item.tipo == 'MOVER'">
                        <span class="fw-bold text-dark d-block fs-8"
                          >{{item.diretorio ? "Pasta movida" : "Arquivo movido"}}</span
                        >
                        <span class="text-muted d-block fs-8">
                          <span class="text-gray800 d-block"><span class="fw-bold text-dark">De: </span>{{ item.dados.de }}</span>
                          <span class="text-gray800 d-block"><span class="fw-bold text-dark">Para: </span>{{ item.dados.para }}</span>
                        </span>
                      </div>
                      <div v-if="item.tipo == 'NOVO' && item.diretorio">
                        <span class="fw-bold text-dark d-block fs-8"
                          >Nova pasta</span
                        >
                        <span class="text-muted d-block fs-8">{{
                          item.dados.fullPath
                        }}</span>
                      </div>
                      <div v-if="item.tipo == 'EXCLUIR' && item.arquivo">
                        <span class="fw-bold text-dark d-block fs-8"
                          >Arquivo excluido</span
                        >
                        <span class="text-muted d-block fs-8" v-if="!item.dados.diretorio && item.dados.fullPath">{{
                          item.dados.fullPath
                        }}</span>
                        <span class="text-muted d-block fs-8" v-if="!item.dados.diretorio && !item.dados.fullPath">{{
                          "/" + item.dados.nome + item.dados.tipo
                        }}</span>
                         <span class="text-muted d-block fs-8" v-if="item.dados.diretorio">{{
                          item.dados.diretorio.fullPath + "/" + item.dados.nome + item.dados.tipo
                        }}</span>
                      </div>
                      <div v-if="item.tipo == 'EXCLUIR' && item.diretorio">
                        <span class="fw-bold text-dark d-block fs-8"
                          >Pasta excluida</span
                        >
                        <span class="text-muted d-block fs-8">{{
                          item.dados.fullPath
                        }}</span>
                      </div>
                      <div v-if="item.tipo == 'RESTAURAR' && item.diretorio">
                        <span class="fw-bold text-dark d-block fs-8"
                          >Pasta restaurada</span
                        >
                        <span class="text-muted d-block fs-8">{{
                          item.dados.fullPath
                        }}</span>
                      </div>
                      <div v-if="item.tipo == 'RESTAURAR' && item.arquivo">
                        <span class="fw-bold text-dark d-block fs-8"
                          >Arquivo restaurado</span
                        >
                        <span class="text-muted d-block fs-8" v-if="!item.dados.diretorio && item.dados.fullPath">{{
                          item.dados.fullPath
                        }}</span>
                        <span class="text-muted d-block fs-8" v-if="!item.dados.diretorio && !item.dados.fullPath">{{
                          "/" + item.dados.nome + item.dados.tipo
                        }}</span>
                         <span class="text-muted d-block fs-8" v-if="item.dados.diretorio">{{
                          item.dados.diretorio.fullPath + "/" + item.dados.nome + item.dados.tipo
                        }}</span>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Paginacao
            ref="paginacao"
            :center="true"
            :total="itensTotal"
            :limit="5"
            @change-pagina="carregarItens()"
          />
        </div>
      </div>
    </div>
    <AtividadeFiltroDialog
      ref="dialogAtividadeFiltro"
      @dialog-on="$emit('dialog-on')"
      @dialog-off="
        aplicarFiltro();
        $emit('dialog-off');
      "
    />
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import { useAuthStore } from "@/stores/auth.js";
import Paginacao from "../components/ComponentPaginacao.vue";
import AtividadeFiltroDialog from "./AtividadeFiltroDialog.vue";

export default {
  components: { Paginacao, AtividadeFiltroDialog },
  props: {
    dialog: { type: Boolean, default: false },
  },
  data() {
    return {
      timerFiltroTexto: null,
      itensTotal: 0,
      filtroTexto: "",
      ordem: "data_DESC",
      itens: [],
      usuarios: {},
      item: null
    };
  },

  setup() {
    const storeAuth = useAuthStore();
    return { storeAuth };
  },

  mounted() {},

  watch: {},

  methods: {
    async carregarItens(item = null) {
      let filtros = "";

      if(item !== null)
      {
        this.item = item;
      }else
      {
        item = this.item;
      }

      filtros += "?_limit=" + 5;
      filtros += "&_page=" + this.$refs.paginacao.pagina;
      filtros += "&_ordem=" + this.ordem;

      if (this.$refs.dialogAtividadeFiltro.item.tipo !== "") {
        filtros += "&tipo=" + this.$refs.dialogAtividadeFiltro.item.tipo;
      }

      if (this.$refs.dialogAtividadeFiltro.item.usuario !== "") {
        filtros += "&usuario=" + this.$refs.dialogAtividadeFiltro.item.usuario;
      }

      if (this.$refs.dialogAtividadeFiltro.item.dataInicial !== "") {
        filtros +=
          "&dataInicial=" + this.$refs.dialogAtividadeFiltro.item.dataInicial;
      }

      if (this.$refs.dialogAtividadeFiltro.item.dataFinal !== "") {
        filtros +=
          "&dataFinal=" + this.$refs.dialogAtividadeFiltro.item.dataFinal;
      }

      if (item !== null && item.tipo && item._id) {
        filtros += "&" + item.tipo + "=" + item._id;
      }

      //* Carrega os dados dos usuarios possiveis
      this.carregarUsuarios();

      //* Carrega as atividades
      const response = await api.get(
        this.storeAuth.apiNuvem() + "/atividade" + filtros
      );

      api.valid(response.data, this.$router);

      this.itens = response.data.itens;
      this.itensTotal = response.data.total;
    },
    async carregarUsuarios() {
      this.usuarios = await api.getItensObjectId(process.env.VUE_APP_URL_API + "/admin/usuario");
    },
    keyupFiltroTexto() {
      clearTimeout(this.timerFiltroTexto);

      const obj = this;

      this.timerFiltroTexto = setTimeout(function () {
        //obj.$refs.paginacao.pagina = 1;
        obj.carregarItens();
      }, 300);
    },
    async abrirFiltro() {
      this.$refs.dialogAtividadeFiltro.abrir();
    },
    async aplicarFiltro() {
      //* Reposiciona a paginacao
      this.$refs.paginacao.pagina = 1;

      //* Recarrega os itens
      this.carregarItens();
    },
    async limparFiltros() {
      this.$refs.dialogAtividadeFiltro.novo();
    },
  },
};
</script>

<style></style>
