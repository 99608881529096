<template>
  <div>
    <Dialog
      :id="'dialogListUsuario'"
      ref="dialogListUsuario"
      :hide-header="true"
      :hideFooter="true"
      :modal="true"
      :tamanho="'xl'"
      :classModalBody="'mx-5 mx-xl-1 my-1'"
      @dialog-off="
        $emit('dialog-off');
        fechar();
      "
    >
      <UsuarioList
        ref="listUsuario"
        :dialog="true"
        @dialog-on="fechar()"
        @dialog-off="
          filtroNuvem == ''
            ? abrir(filtroCliente, true)
            : abrirNuvem(filtroCliente, filtroNuvem, true)
        "
        :buttonNovo="buttonNovo"
        :buttonSelecionar="buttonSelecionar"
      />

      <template #header>
        <div class="m-9 mb-0">
          <div class="d-flex justify-content-between">
            <div>
              <h1 class="d-flex text-primary fw-bolder fs-2 mt-2" v-if="true">
                {{ nomeCliente }}
                <span
                  class="h-22px border-1 border-gray-200 border-start ms-3 mx-2 me-1"
                  v-if="true"
                ></span>
                <span class="text-muted fs-7 fw-bold ms-2 mt-2" v-if="true">{{
                  nomeNuvem
                }}</span
                ><span
                  class="h-22px border-1 border-gray-200 border-start ms-3 mx-2 me-1"
                  v-if="true"
                ></span>
                <span class="text-dark fs-7 fw-bold ms-2 mt-2" v-if="true"
                  >Usuários</span
                >
              </h1>
            </div>

            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1" @click="$emit('dialog-off')">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <rect
                    opacity="0.5"
                    x="6"
                    y="17.3137"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(-45 6 17.3137)"
                    fill="currentColor"
                  ></rect>
                  <rect
                    x="7.41422"
                    y="6"
                    width="16"
                    height="2"
                    rx="1"
                    transform="rotate(45 7.41422 6)"
                    fill="currentColor"
                  ></rect>
                </svg>
              </span>
            </div>
          </div>
          <div class="separator m-0 mt-3" v-if="true"></div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { api } from "@/plugins/api.js";

import UsuarioList from "./UsuarioList.vue";
import Dialog from "../components/ComponentDialog.vue";

export default {
  components: { UsuarioList, Dialog },
  props: {
    buttonNovo: { type: Boolean, default: true },
    buttonSelecionar: { type: Boolean, default: false },
  },

  data() {
    return {
      nomeCliente: "",
      nomeNuvem: "",
      filtroCliente: "",
      filtroNuvem: "",
    };
  },

  mounted() {},

  methods: {
    abrir(cliente, interno = false) {
      if (!interno) {
        this.$emit("dialog-on");
      }

      this.filtroCliente = cliente;
      this.filtroNuvem = "";
      this.$refs.listUsuario.filtroCliente = this.filtroCliente;
      this.$refs.listUsuario.filtroNuvem = this.filtroNuvem;
      this.$refs.listUsuario.carregarItens();
      this.$refs.dialogListUsuario.abrir();

      if (!interno) {
        this.$emit("dialog-on");
      }
    },
    async abrirNuvem(cliente, nuvem, interno) {
      //* Limpa os campos
      this.nomeCliente = "-";
      this.nomeNuvem = "-";

      //* Carrega os dados da api
      const response = await api.get(process.env.VUE_APP_URL_API + "/admin/nuvem/" + nuvem);

      //* Verifica se econtrou
      if (response.data.itens.length > 0) {
        const itemNuvem = response.data.itens[0];
        this.nomeCliente = itemNuvem.cliente ? itemNuvem.cliente.nome : "-";
        this.nomeNuvem = itemNuvem.nome;

        if (!interno) {
          this.$emit("dialog-on");
        }

        this.filtroCliente = cliente;
        this.filtroNuvem = nuvem;

        //* Passa para a lista os totais
        this.$refs.listUsuario.estatisticas.espacoTotal = itemNuvem.espacoTotal;
        this.$refs.listUsuario.estatisticas.espacoUtilizado =
          itemNuvem.espacoOcupado;
        this.$refs.listUsuario.estatisticas.usuariosTotal =
          itemNuvem.usuariosTotal;

        //* Totais de usuarios ativos
        let usuariosAtivos = 0;
        
        //* Percorre os usuarios
        for(let indiceUsuario = 0; indiceUsuario < itemNuvem.usuarios.length; indiceUsuario++)
        {
          
          //* Instancia o usuario
          let itemUsuario = itemNuvem.usuarios[indiceUsuario];
          
          //* Verifica se o usuario esta ativo
          if(itemUsuario.status == true )
          {
            //* Incrementa o numero de usuarios ativos
            usuariosAtivos = usuariosAtivos + 1;
          }

        }

        this.$refs.listUsuario.estatisticas.usuariosUtilizado = usuariosAtivos;

        this.$refs.listUsuario.filtroCliente = this.filtroCliente;
        this.$refs.listUsuario.filtroNuvem = this.filtroNuvem;

        this.$refs.listUsuario.carregarItens();
        this.$refs.dialogListUsuario.abrir();
      }
    },
    fechar() {
      this.$refs.dialogListUsuario.fechar();
    },
  },
};
</script>

<style>
</style>
